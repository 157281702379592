import { Box, Pagination } from "@mui/material"

import postCreateApplicationConfig from "../../../../Api/DesktopConfiguration/v2/post-create-application-config"
import postCreateUpdateGroupConfigV2       from "../../../../Api/DesktopConfiguration/v2/post-create-group-config"
import postCreateUpdateUserConfigV2        from "../../../../Api/DesktopConfiguration/v2/post-create-user-config"
import { 
    TApiPostCreateUpdateApplicationConfig, 
    TApiPostCreateUpdateGroupConfig, 
    TApiPostCreateUpdateUserConfig 
} from "../../../../Types/Api/ApiDesktopConfigRequest"

import { useAction }        from "../../../../Store/Hook/use-action"
import { useTypedSelector } from "../../../../Store/Hook/use-typed-selector"
import CreateConfig, { enumConfigType }         from "../Dialogs/CreateConfig"

type TListToolProps = {
    configType: enumConfigType
}

const ListTools = (props: TListToolProps) =>{

    const {
        SetNeedUpdateUsersConfigsFromAPI,
        SetNeedUpdateGroupConfigsFromAPI,
        SetNeedUpdateApplicationConfigsFromAPI,
        SetUsersPagination,
        SetError,
        SetErrorNeedToShow,
    } = useAction()

    const {
        selectedApplicationVersion,
        number,
        size,
        totalElements,
    } = useTypedSelector(store => store.desktopConfigurations.users)

    const lastElementsCount = totalElements % size

    const onUserConfigCreateUpdate = (
        params: TApiPostCreateUpdateUserConfig
    ) =>{
        postCreateUpdateUserConfigV2({
            ...params
        }).then((response)=>{
            SetNeedUpdateUsersConfigsFromAPI(true)
        }).catch((error)=>{
            SetError({
                status  : error.response.data.status ? String(error.response.data.status) : '',
                path    : error.response.data.path   ? String(error.response.data.path)   : '',
                message : error.response.data.message ? String(error.response.data.message) : 'CANT CREATE/UPDATE USER CONFIG'
            })
            SetErrorNeedToShow(true)
            console.log("CANT CREATE/UPDATE APPLICATION CONFIG", JSON.stringify(error))
        })
    }

    const onGroupConfigCreateUpdate = (params: TApiPostCreateUpdateGroupConfig) =>{
        
        postCreateUpdateGroupConfigV2({
            ...params
        }).then((response)=>{
            SetNeedUpdateGroupConfigsFromAPI(true)
        }).catch((error)=>{
            SetError({
                status  : error.response.data.status ? String(error.response.data.status) : '',
                path    : error.response.data.path   ? String(error.response.data.path)   : '',
                message : error.response.data.message ? String(error.response.data.message) : 'ANT CREATE/UPDATE GROUP CONFIG'
            })
            SetErrorNeedToShow(true)
            console.log("CANT CREATE/UPDATE APPLICATION CONFIG", JSON.stringify(error))
        })
    }

    const onApplicationConfigCreate = (params: TApiPostCreateUpdateApplicationConfig) =>{
 
        postCreateApplicationConfig({
            ...params
        }).then((response)=>{
            SetNeedUpdateApplicationConfigsFromAPI(true)
        }).catch((error)=>{
            SetError({
                status  : error.response.data.status ? String(error.response.data.status) : '',
                path    : error.response.data.path   ? String(error.response.data.path)   : '',
                message : error.response.data.message ? String(error.response.data.message) : "CANT CREATE/UPDATE APPLICATION CONFIG"
            })
            SetErrorNeedToShow(true)
            console.log("CANT CREATE/UPDATE APPLICATION CONFIG", JSON.stringify(error))
        })
    }

    return(
        <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '18px 0 10px 0' }}>
        
            <Pagination
                variant="outlined" 
                shape="rounded"
                color="secondary"
                sx={{margin: '0 0 8px 0'}}
                page={number+1} 
                count={(totalElements - lastElementsCount)/size + ( lastElementsCount === 0 ? 0 : 1  )} 
                onChange={
                    (e:any, clickedPageNumber:number)=>{
                        SetUsersPagination({
                            number       : clickedPageNumber-1,
                            size         : size,
                            totalElements: totalElements
                        })

                        SetNeedUpdateUsersConfigsFromAPI(true)
                    }
                }
            />

            <div>
                <CreateConfig
                    configType     = { props.configType }
                    application    = { selectedApplicationVersion ? selectedApplicationVersion.name    : '' }
                    appVersion     = { selectedApplicationVersion ? selectedApplicationVersion.version : '' }
                    onCreateConfig = { props.configType === enumConfigType.USER ? onUserConfigCreateUpdate :
                                (   props.configType === enumConfigType.GROUP ? onGroupConfigCreateUpdate :
                                                                                onApplicationConfigCreate
                    )}
                />
            </div>
        </Box>
    )
}

export default ListTools
