import { enumSORT } from "../../Types/General"
import { enumStoreMtpAction, IStoreMobileTreatmentPlan, TMtpStoreAction } from "../../Types/Store/StoreMtp"


const initialState:IStoreMobileTreatmentPlan = {
    
    caseDescription: {
        needUpdateFromAPI: true,
        mtpCase: undefined
    },

    mtpCasesList:{
        needUpdateFromApi: false,
        list: [],
        filterData:{
            direction: enumSORT.DESC,
            "page.number": 0,
            "page.size"  : 100,
            "period.from": '',
            "period.to"  : '',
            info         : '',
            status       : '',
        },
    },    

    error:{
        needToShow: false,
        status: '',
        message: '',
        path: '',
    }
}


export const mtpReducer = ( 

    state = initialState,
    action : TMtpStoreAction

) : IStoreMobileTreatmentPlan => {

    switch(action.type){

        case  enumStoreMtpAction.SET_MTP_NEED_UPDATE_CASE_FROM_API :            
            return ({
                ...state,
                caseDescription:{
                    ...state.caseDescription,
                    needUpdateFromAPI: action.payload
                }
            })

        case  enumStoreMtpAction.SET_MTP_CASE :            
            return ({
                ...state,
                caseDescription:{
                    ...state.caseDescription,
                    mtpCase: action.payload
                }
            })
        case enumStoreMtpAction.SHOW_MTP_ERROR :
            return({
                ...state,
                error: {
                    needToShow: true,
                    ...action.payload
                }
            })

        case enumStoreMtpAction.HIDE_MTP_ERROR :
            return({
                ...state,
                error: {
                    needToShow: false,
                    message: '',
                    path: '',
                    status: ''
                }
            })

        case enumStoreMtpAction.SET_MTP_NEED_UPDATE_CASES_LIST_FROM_API:
            return({
                ...state,
                mtpCasesList:{
                    ...state.mtpCasesList,
                    needUpdateFromApi: action.payload
                }
            })

        case enumStoreMtpAction.SET_MTP_LIST_PAGE_SIZE:
            return({
                ...state,
                mtpCasesList:{
                    ...state.mtpCasesList,
                    filterData: {
                        ...state.mtpCasesList.filterData,
                        "page.number": 0,
                        "page.size": action.payload
                    }
                }
            })
        case enumStoreMtpAction.SET_MTP_LIST_PAGE_NUMBER:
            return({
                ...state,
                mtpCasesList:{
                    ...state.mtpCasesList,
                    filterData: {
                        ...state.mtpCasesList.filterData,
                        "page.number": action.payload
                    }
                }
            })

        case enumStoreMtpAction.SET_MTP_LIST:
            return({
                ...state,
                mtpCasesList:{
                    ...state.mtpCasesList,
                    list: action.payload
                }
            })

        case enumStoreMtpAction.SET_MTP_LIST_DIRECTION:
            return({
                ...state,
                mtpCasesList:{
                    ...state.mtpCasesList,
                    filterData: {
                        ...state.mtpCasesList.filterData,
                        direction: action.payload
                    }
                }
            })
            
        case enumStoreMtpAction.SET_MTP_LIST_FILTER_DATA:
            return({
                ...state,
                mtpCasesList:{
                    ...state.mtpCasesList,
                    filterData: {
                        ...action.payload
                    }
                }
            })
            
        default:
            return state
    }

}

