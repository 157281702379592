import API from '../api-base'
import { TApiEditCaseComment } from "../../../Types/Api/ApiCaseManagementRequest"
import { TComment } from '../../../Pages/CaseManagement/Case/Comments/useComments'

// https://case-management-api.tp-dev-stable.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Comment%20API/updateComment

const putEditCaseComment = async ( params: TApiEditCaseComment ): Promise<TComment>  =>{
    return await API.put(
        `/v2/cases/${params.caseId}/comments/${params.commentId}`,
        { 
            content: params.content
        }
    )
}

export default putEditCaseComment
