import { FunctionComponent } from 'react'

export interface IErrorPageProps {
    errorCode?: string
    errorSummary?: string
}

const Error: FunctionComponent<IErrorPageProps> = (props) =>{
    const { errorCode, errorSummary } = props
    return(
        <>
            <div>
                <h2>
                    Error { errorCode ? errorCode : null }
                </h2>
                <p>
                    { errorSummary }
                </p>
            </div>
        </>
        
    )
}

export default Error
