import { AxiosResponse } from "axios"
import API from './api-base'
import { TApiDeleteGroup  } from "../../Types/Api/ApiGroupsRequest"
import { enumApiParameter } from "../../Types/General"


// https://adalisk.stoplight.io/docs/tp-api/b3A6MzgzNDUyMjA-delete-group

const deleteGroup = async ( params: TApiDeleteGroup ): Promise<AxiosResponse>  =>{
    return await API.delete(
        
        `/groups/${params[enumApiParameter.GROUP_ID]}`
    
    )
}

export default deleteGroup
