import { AxiosResponse } from "axios"
import { TApiPostWorkflowRuleAdd } from "../../Types/Api/ApiWorkflowRequest"
import { TApiPostWorkflowRuleAddResponse } from "../../Types/Api/ApiWorkflowResponse"
import API from './api-base'


// https://tp-workflow-api.tp-qa.smiledirect.services/swagger-ui/index.html#/Rules%20API/create

const postRuleAdd = async ( params: TApiPostWorkflowRuleAdd ): Promise<AxiosResponse<TApiPostWorkflowRuleAddResponse>>  =>{

    return await API.post(
    
        `/rules`,
    
        { 
            ...params
        }
    )
}

export default postRuleAdd
