import { 
    TableContainer, 
    Table, 
    TableBody, 
    TableRow, 
    TableCell, 
} from "@mui/material"
import { ReactElement, useMemo } from "react"

import { TCase } from "../../../../Types/Store/StoreCaseManagement"
import formatDateToLocal from "../../../../Utils/format-date-to-local"
import CaseTagField from "./CaseTagField"
import { TCasePropertyForUpdate } from "../Case"
import CasePrevoiusId from "./CasePreviousId"
import CaseExternalTreatmentPlanId from "./CaseExternalTreatmentPlanId"

type TCaseLabels = {
    [key:string]: string
}

const caseLabels:TCaseLabels = {

    id                      : 'CMS ID',
    externalId              : 'External ID',
    createdBy               : 'Created By',
    source                  : 'Case Source',
    version                 : 'Version',
    createdAt               : 'Created Date',
    updatedAt               : 'Updated Date',
    status                  : 'Status',
    lockedBy                : 'Locked By',
    lockedAt                : 'Locked At',
    locked                  : 'Locked',
    medicalRecordNumber     : 'Medical Record Number',
    smoVersion              : 'SMO Version',
    smoObjExportPath        : 'SMO Obj Export Path',
    treatmentOption         : 'Treatment Option',
    tag                     : 'Tag',
    previousCaseId          : 'Previous Case ID',
    externalTreatmentPlanId : 'External TreatmentPlan ID',
}

type TCaseViewProps = {
    caseToView: TCase
    setNeedUpdateProperty: React.Dispatch<React.SetStateAction<TCasePropertyForUpdate>>
}

const CaseView = (props:TCaseViewProps)=>{

    const { caseToView, setNeedUpdateProperty } = props
    
    const tableContent = useMemo(()=>{
        
        const result:ReactElement[] = []
        
        const fieldsKeys   = Object.keys(  props.caseToView) as string[]
        const fieldsValues = Object.values(props.caseToView) as string[]
        
        const specialFields:string[] = [ // special fields that will have own components
            'tags', 
            'previousCaseId',
            'externalTreatmentPlanId',
        ] 

        for(let i = 0; i<fieldsKeys.length; i++){

            if( !specialFields.includes(fieldsKeys[i]) ){
                
                result.push(
                    <TableRow
                        hover
                        key={i}
                        sx={{
                            maxWidth : '100%',
                        }}
                    >
                        <TableCell 
                            align="right"
                            sx={{ border: 'unset'}}
                        >
                            <strong>{ caseLabels[fieldsKeys[i]] ? caseLabels[fieldsKeys[i]] : fieldsKeys[i] }</strong>
                        </TableCell>
                        
                        <TableCell 
                            align="left"
                            sx={{ border: 'unset'}}
                        >
                            { (fieldsKeys[i] === 'createdAt' || fieldsKeys[i] === 'updatedAt' || fieldsKeys[i] === 'lockedAt') ?   formatDateToLocal(fieldsValues[i]) : fieldsValues[i]   }
                            { typeof(fieldsValues[i]) === 'boolean' ? String(fieldsValues[i]): null  }
                            { fieldsKeys[i] === 'previousCaseId' ? fieldsValues[i] : null }
                        </TableCell>
                    </TableRow>
                )
            }
        }

        specialFields.forEach((spacialField, specialFieldIndex)=>{
            switch(spacialField){
                case 'tags':
                    result.push(
                        <CaseTagField
                            key={`specialField${specialFieldIndex}`}
                            setNeedUpdateProperty= { setNeedUpdateProperty }
                            caseToView           = { caseToView            }
                        />
                    )
                    break
                case 'previousCaseId':
                    result.push(
                        
                        <CasePrevoiusId
                            key={`specialField${specialFieldIndex}`}
                            setNeedUpdateProperty= { setNeedUpdateProperty }
                            caseToView           = { caseToView            }
                        />
                        
                    )
                    break
                case 'externalTreatmentPlanId':
                    result.push(
                        
                        <CaseExternalTreatmentPlanId
                            key={`specialField${specialFieldIndex}`}
                            caseToView           = { caseToView            }
                        />
                        
                    )
                    break

                default:
                    break
            }
        })


        return(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[props])

    return(
        <TableContainer
            sx={{
                maxWidth: '700px'
            }}
        >
            <Table
                size="small"
            >
                <TableBody>

                    {
                        tableContent
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default CaseView
