import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Box, Button, Checkbox, FormControl, FormGroup, InputLabel, ListItemButton, ListItemIcon, ListItemText, MenuItem, Modal, OutlinedInput, Select, Typography } from "@mui/material"
import useValidation, { enumFormValidationRule } from "../../formValidator"
import TransitionList from "./TransitionList"
import postCreateStatusTransition from "../../../Api/CaseManagement/v2/post-create-status-transition"
import { TWorkflowSetErrorPayload } from "../../../Types/Store/StoreWorkflow"
import putUpdateCaseStatusTransition from "../../../Api/CaseManagement/v2/put-update-case-status-transitions"
import colorLibrary, { buttonContainedStyle, buttonOutlinedStyle } from "../../../Utils/colors"

export type TTransitionAddFormProps = {
    editedStatus         ?: string
    editedTransitions    ?: string[]
    editedInitial        ?:boolean
    editedVersion        ?:number
    avalableStatus        : string[]
    setNeedUpdateFromAPI  : Dispatch<SetStateAction<boolean>>
    setApiError           : Dispatch<SetStateAction<TWorkflowSetErrorPayload>>
} 

const TransitionAddForm = (props: TTransitionAddFormProps) => {
    const { 
        avalableStatus,
        setApiError,
        setNeedUpdateFromAPI,
        editedStatus,
        editedTransitions,
        editedInitial,
        editedVersion,
    } = props

    const [
        status,
        isStatusValid,
        ,
        onStatusChange
    ] = useValidation([enumFormValidationRule.REQUIRED ], editedStatus)
    
    

    const [ isInitial, setInitial] = useState(editedInitial && editedInitial === true ? true : false)
    const [ selectedTransitions, setSelectedTransitions ] = useState<string[]>( editedTransitions ? editedTransitions : [])
    const [ isRequestNeeded    , setRequestNeeded       ] = useState(false)
    const [open, setOpen] = useState(false)
    let isEditMode =  Boolean(editedStatus || editedTransitions)
    
    const handleOpen = () => {
        if(editedStatus){
            onStatusChange(editedStatus, true)
        }
        if(Array.isArray(editedTransitions) && setSelectedTransitions.length > 0){
            setSelectedTransitions(editedTransitions)
        }
        if(editedInitial && editedInitial === true){
            setInitial(editedInitial)
        }
        setOpen(true)
    }

    const handleClose = () => {
        onStatusChange('', true)
        setSelectedTransitions([])
        setOpen(false)
    }



    useEffect(()=>{
        if(isRequestNeeded === true && isStatusValid === true){

            if(isEditMode === false){

                postCreateStatusTransition({
                    initial: isInitial,
                    status,
                    transitions: selectedTransitions
                })
                .then(()=>{
                    setNeedUpdateFromAPI(true)
                })
                .catch((error)=>{
                    setApiError({
                        needToShow: true,
                        message: error.response.data.message,
                        path: error.response.data.path,
                        status: error.response.data.status
                    })
                })
                .finally( handleClose )
            }

            if(isEditMode === true && editedVersion!== undefined){
                putUpdateCaseStatusTransition({
                    status,
                    initial: isInitial,
                    transitions: selectedTransitions,
                    version: editedVersion
                })
                .then(()=>{
                    setNeedUpdateFromAPI(true)
                })
                .catch((error)=>{
                    setApiError({
                        needToShow: true,
                        message: error.response.data.message,
                        path: error.response.data.path,
                        status: error.response.data.status
                    })
                })
                .finally( handleClose )
            }

        }
        setRequestNeeded(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isRequestNeeded])


    return(
        <>
            <Button 
                variant="contained" 
                size="small" 
                sx={{
                    margin: '0 40px 0 0',
                    ...buttonContainedStyle
                }}

                
                onClick = {()=>{
                    handleOpen()
                }}
            >
                { isEditMode ?
                    'EDIT'
                    :
                    'ADD STATUS'
                }
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                onContextMenu = {(e)=>{
                    e.stopPropagation()
                    e.preventDefault()
                }}
            >
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 'max-content',
                        bgcolor: '#fff',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}

                >
                    <Typography  variant="h6" component="h2">
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start'
                            }}
                        >
                            <Box sx={{
                                fontSize: '24px',
                            }}>
                                { isEditMode ?
                                    'Edit'
                                    :
                                    'Add'
                                } Status Transition
                            </Box>
                        </Box>
                    </Typography>
                    
                    <Box sx={{ display: 'flex' , flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}  >

                        
                        {
                            isEditMode ?
                                <FormControl error={!isStatusValid}
                                        fullWidth
                                        sx={{margin: '30px 0 30px 0', width: '240px'}}
                                >
                                    <InputLabel id="select-status-label">Status</InputLabel>
                                    <Select
                                        labelId="select-status-label"
                                        id="select-status"
                                        value={ status }
                                        label="Status"
                                        sx={{ width: '100%' }}
                                        onChange={ (e)=>{
                                            onStatusChange(e.target.value)
                                        }}
                                    >
                                        {
                                            avalableStatus.map((statusItem, statusIndex) =>{ 
                                                return(
                                                    <MenuItem 
                                                        value={statusItem}
                                                        key={`statusItem${statusIndex}`}
                                                    >
                                                        { statusItem }
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            :
                                
                                <FormControl fullWidth
                                    sx={{ margin: '30px 0 20px 0' }}
                                >
                                    <InputLabel 
                                        htmlFor="component-outlined"
                                    >
                                        Status
                                    </InputLabel>
                                    
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={status}
                                        onChange={(e)=>{
                                            onStatusChange(e.target.value)
                                        }}
                                        label="Status"
                                        autoFocus
                                    />
                                </FormControl>
                        }

                        <FormGroup sx={{border: `2px solid ${colorLibrary.backgroundColor}`, width: '100%', borderRadius: '6px'}}>
                        
                            <ListItemButton 
                                role={undefined} 
                                onClick={
                                    ()=>{ setInitial(prev=> !prev) }
                                } 
                                dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={ isInitial }
                                        tabIndex={-1}
                                        disableRipple
                                        // inputProps={{ 'aria-labelledby': labelId }}
                                        onChange={(e, checked)=>{ setInitial(checked) }}
                                    />
                                </ListItemIcon>
                                <ListItemText 
                                    primary={ 'Initial' } 
                                />
                            </ListItemButton>
                                
                        </FormGroup>

                        
                        {
                            avalableStatus.length > 0 ?
                                <>
                                    <Box sx={{margin: '30px 0 5px 0'}}>
                                        Transitions
                                    </Box>
                                    <Box sx={{ margin: '0 0 30px 0' , border: `2px solid ${colorLibrary.backgroundColor}`, borderRadius: '6px', width: '100%'}}>
                                        <TransitionList 
                                            transitionList={ avalableStatus.filter((statusItem)=>{ return( statusItem !== status ) }) }
                                            setSelectedTransitions = { setSelectedTransitions }
                                            editedTransitions = { editedTransitions }
                                        />
                                    </Box>
                                </>
                            :
                                null
                        }
                    </Box>


                {/* statusValidationError  */}

                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <Button 
                            variant='outlined' 
                            onClick={()=>{
                                onStatusChange(status)
                                setRequestNeeded(true)
                            }} 
                            sx={{
                                margin: '0 30px 0 0',
                                ...buttonOutlinedStyle
                            }}
                        >
                            {
                                isEditMode ?
                                'Apply'
                                :
                                'Add'
                            }
                        </Button>

                        <Button 
                            variant='outlined' onClick={handleClose}
                            sx={ buttonOutlinedStyle }
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default TransitionAddForm
