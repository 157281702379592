import { Box, Pagination } from "@mui/material"
import { useAction } from "../../Store/Hook/use-action"
import { useTypedSelector } from "../../Store/Hook/use-typed-selector"
import RulesTable from './RulesTable/table'
import CreateRule from './CreateRule/CreateRule'
import { useEffect } from "react"
import { enumApiParameter, enumSORT } from "../../Types/General"
import getRulesListSortedByOrder from "../../Api/Workflow/get-rules-list"

const WorkflowRules = () =>{


    const { 
        pagination, 
        needUpdateDataFromAPI,
    } = useTypedSelector(state=>state.workflow)

    const { 
        setWorkflowRulesNeedUpdateFromAPI,
        setWorkflowRulesList,
        setWorkflowRulesPagination,

    } = useAction()

    useEffect(()=>{

        if(needUpdateDataFromAPI === true){
            
            setWorkflowRulesNeedUpdateFromAPI(false)
            
            getRulesListSortedByOrder({
                [ enumApiParameter.DIRECTION   ] : enumSORT.ASC,
                [ enumApiParameter.PAGE_NUMBER ] : pagination.number,
                [ enumApiParameter.PAGE_SIZE   ] : pagination.size
            })
            .then((response)=>{
                
                setWorkflowRulesList(response.data.content)
                setWorkflowRulesPagination({
                    number: response.data.number,
                    size: response.data.size,
                    totalElements: response.data.totalElements
                })
            })
            .catch((error)=>{
                console.log('CANT GET WORKFLOW RULES LIST FROM API', JSON.stringify(error))
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[needUpdateDataFromAPI])

    const lastElementsCount = pagination.totalElements % pagination.size

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '6px 0 0 0' }}>
                <Pagination
                    variant="outlined" 
                    shape="rounded"
                    color="secondary"
                    sx={{margin: '0 0 8px 0'}}
                    page={pagination.number+1} 
                    count={(pagination.totalElements - lastElementsCount)/pagination.size + ( lastElementsCount === 0 ? 0 : 1  )}
                    onChange={
                        (e:any, clickedPageNumber:number)=>{
                            setWorkflowRulesPagination({
                                number       : clickedPageNumber-1,
                                size         : pagination.size,
                                totalElements: pagination.totalElements
                            })
                            setWorkflowRulesNeedUpdateFromAPI(true)
                        }
                    } 
                />                
            </Box>
            
            <CreateRule/>
            
            <RulesTable />

        </div>
    )
}

export default WorkflowRules
