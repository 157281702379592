import { AxiosResponse } from "axios"
import API from '../api-base'

// https://adalisk.stoplight.io/docs/tp-api/branches/main/d811dbdf3cbba-lock-case

const postLockCase = async ( caseId: string ): Promise<AxiosResponse>  =>{
    return await API.post(
        `/v2/cases/${caseId}/locks`
    )
}

export default postLockCase
