import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiDeleteCaseComment } from "../../../Types/Api/ApiCaseManagementRequest"

// https://case-management-api.tp-dev-stable.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Comment%20API/delete_1


const deleteCaseComment = async ( params:TApiDeleteCaseComment  ): Promise<AxiosResponse>  =>{
    return await API.delete(
        `/v2/cases/${params.caseId}/comments/${params.commentId}`
    )
}

export default deleteCaseComment
