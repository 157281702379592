import API from '../api-base'
import { TApiPostUpdateStatusTransition } from "../../../Types/Api/ApiCaseManagementRequest"
import { TCaseStatusTransition } from "../../../Types/Api/ApiCaseManagementResponse"


// https://adalisk.stoplight.io/docs/tp-api/branches/main/dd9b7ad51821e-put-case-status-transitions

const putUpdateCaseStatusTransition = async ( params: TApiPostUpdateStatusTransition ): Promise<TCaseStatusTransition>  =>{
    return await API.put(
        `/v2/case-status-transitions`,
        { 
            ...params 
        }
    )
}

export default putUpdateCaseStatusTransition
