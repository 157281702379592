import { Box, Button } from "@mui/material"
import { Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from "react"
import { TWorkflowRuleAction } from "../../../Types/Store/StoreWorkflow"

import checkFormState from "./Utils/check-form-state"
import generateFormEntries from "./Utils/generate-form"

export type TActionEditorProps = {
    isVisible: boolean
    actionItemIndex?: number
    actionList?: TWorkflowRuleAction[],
    setActionList?: Dispatch<SetStateAction<TWorkflowRuleAction[]>>
    avalableActionsTemplate: TWorkflowRuleAction[]
    setInvisible?: () => void
}

export type TAttributeCheckResult = {
    attribute: string
    isValid: boolean
    error: string
}

export type TErrorDescription = [ 
    string, 
    {
        value: string | string[]
        requirement: {
            type: string
            required: boolean
            items?: {
                type: string
            }
        }
    }
]



const ActionEditor = (props:TActionEditorProps) =>{
    const { 
        isVisible , 
        actionItemIndex, 
        actionList, 
        setActionList, 
        setInvisible,
        avalableActionsTemplate,

    } = props
    

    const [ formState, setFormState ] = useState<Object>({})
    const [ formErrorState, setFormErrorsState ] = useState<TAttributeCheckResult[]>([])
    const [ isNeedSetAction, setNeedSetAction ] = useState(false)
    const [ localFormState, setLocalFormState ] = useState<TWorkflowRuleAction | undefined>()

    useEffect(()=>{
        if(actionList && actionItemIndex!==undefined && actionItemIndex >-1){
            setLocalFormState({
                type: actionList[actionItemIndex].type,
                attributes: {
                    ...actionList[actionItemIndex].attributes,
                    ...formState
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[actionItemIndex, actionList ])

    

    useEffect(()=>{
        if(isNeedSetAction === true){
            if(setActionList && localFormState){



                const formCheckResults = checkFormState(
                    {
                        type: localFormState.type,
                        attributes: {
                            ...localFormState.attributes,
                            ...formState
                        }
                    },
                    avalableActionsTemplate
                )
                
                console.log(formCheckResults)

                if(formCheckResults.length>0){
                    setLocalFormState({
                        type: localFormState.type,
                        attributes: {
                            ...localFormState.attributes,
                            ...formState
                        }
                    })

                    setFormErrorsState(formCheckResults)
                }else{
                    if(actionItemIndex!==undefined){

                        setActionList((prevList)=>{
                            return([
                                ...prevList.slice(0,actionItemIndex),
                                {
                                    type: localFormState.type,
                                    attributes: {
                                        ...localFormState.attributes,
                                        ...formState
                                    }
                                },
                                ...prevList.slice(actionItemIndex+1,prevList.length),
    
                            ])
                        })
                        
                    }
                    setFormErrorsState([])
                    if(setInvisible){
                        setInvisible()
                    }
                    
                }
                
                
                setNeedSetAction(false)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedSetAction, actionItemIndex,formState,actionList])


    const actionEditedItem = useMemo(()=>{
        if( localFormState && Array.isArray(actionList) && actionItemIndex !==undefined && avalableActionsTemplate.length > 0){

            const startValues = localFormState.attributes as { [key: string]: string; }

            let localTemplateIndex:number = -1
            for(let i = 0; i<avalableActionsTemplate.length; i++){
                if(localFormState.type === avalableActionsTemplate[i].type){
                    localTemplateIndex = i
                    break
                }
            }
            

            const formElements: ReactElement[] = generateFormEntries(avalableActionsTemplate[localTemplateIndex].attributes, setFormState, startValues, formErrorState)

            return(
                <Box>
                    
                    <Box>
                        {
                            formElements.length === 0 ?
                                <Box sx={{
                                    margin: '20px'
                                }}>
                                    ACTION DOESN'T HAS ATRIBUTES
                                </Box>
                            :
                                <Box>
                                    {
                                        formElements
                                    }
                                </Box>
                        }
                    </Box>

                    <Box
                        sx={{
                            margin: '16px 0 10px 0'
                        }}
                    >
                        <Button
                            variant="outlined" 
                            color  ='primary' 
                            size   ='small'

                            onClick={()=>{
                                setNeedSetAction(true)
                            }}
                        >
                            APPLY
                        </Button>

                        <Button
                            variant="outlined" 
                            color  ='primary' 
                            size   ='small'
                            sx={{margin: '0 0 0 16px'}}

                            onClick={()=>{
                                if(setInvisible){
                                    setInvisible()
                                }
                            }}
                        >
                            CANCEL
                        </Button>

                    </Box>
                </Box>
            )
        } 
        return null
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[localFormState, avalableActionsTemplate, formErrorState])

    return(
        isVisible ?
            <Box
                sx={{
                    width: '100%',
                    border: '1px solid #ccc',
                    padding: '10px 20px 10px 24px',
                }}
            >
                { actionEditedItem }
            </Box>
        : null
    )
}

export default ActionEditor
