
export type TActionAttributeViewer = {
    attributes?: Object 
}

const ActionAttributeViewer = (props:TActionAttributeViewer) => {

    const { attributes } = props


    return(
        <div style={{
            textOverflow: 'ellipsis',
            width: '100%',
            fontSize: '14px',
            margin: '0 16px',
            height: '100%',
            display: 'flex',
            alignSelf: 'center',
        }}>
            {
                attributes ? 
                    JSON.stringify(attributes)
                :
                    `Actions are missing`
            }
        </div>
    )
}

export default ActionAttributeViewer

