import { useEffect, useMemo, useState } from 'react'
import { 
    FormControl, 
    FormHelperText, 
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import useValidation, { enumFormValidationRule } from '../formValidator'
import postAddUserToGroup from '../../Api/UserGroups/post-add-user-to-group'

import { useAction } from '../../Store/Hook/use-action'
import { buttonContainedStyle } from '../../Utils/colors'

export type TAssignMultiplyUsersForm = {
    groupId?: string
}

export type TErrorListElement = {
    errorCode: string
    errorMessage: string
}

export type TProgressDescription = {
    success: number
    total: number
    errors: TErrorListElement[]
}

export default function AssignMultiplyUsersForm(props:TAssignMultiplyUsersForm) {
    const [open, setOpen] = useState(false)
    const [ isNeedToSendRequest , setIsNeedToSendRequest ] = useState(false)

    const [ progressDescription, setProgressDescription ] = useState<TProgressDescription>({ 
        success: 0,
        total: 0,
        errors: [],
    })

    const [
        userIdList,
        isUserIdListValid,
        userIdListValidationError,
        onUserIdListChange
    ] = useValidation([enumFormValidationRule.EMAIL_LIST]) 

    const { setNeedUpdateUsersFromAPI } = useAction()


    useEffect(()=>{
        if(isNeedToSendRequest === true){
            if(isUserIdListValid === true){
                const userIdArray = userIdList.split('\n')
                
                setProgressDescription({
                    success: 0,
                    total: userIdArray.length,
                    errors: [],
                })

                for(let i=0; i< userIdArray.length; i++){
                    
                    postAddUserToGroup({
                        groupId: props.groupId ? props.groupId : '',
                        login :  userIdArray[i]
                    }).then((response)=>{
                        //setNeedUpdateUsersFromAPI(true)
                        setProgressDescription((prev) =>{ 
                            return({
                                ...prev,
                                success: prev.success+1,
                            })
                        })
                    }).catch((error)=>{
                        console.log("CAN'T ADD User to group", error.response, error)
                        
                        setProgressDescription((prev) =>{ 
                            return({
                                ...prev,
                                errors:[
                                    ...prev.errors,
                                    {
                                        errorCode: error.response.data.status ? String(error.response.data.status) : '',
                                        errorMessage: `${ userIdArray[i] } - ${String(error.response.data.message)}` 
                                    }
                                ]
                            })
                        })
                        

                        // SetUserGroupsError({
                        //     status  : error.response.data.status ? String(error.response.data.status) : '',
                        //     path    : error.response.data.path   ? String(error.response.data.path)   : '',
                        //     message : error.response.data.message ? String(error.response.data.message) : "CANT GET LIST OF APPLICATION'S CONFIG SORTED BY NAME"
                        // })
    
                        // SetUserGroupsErrorNeedToShow(true)
                    }).finally(()=>{
                        setNeedUpdateUsersFromAPI(true)
                    })
                }

                

            }
            setIsNeedToSendRequest(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToSendRequest])

    const handleClickOpen = () => {
        setOpen(true)
        onUserIdListChange('',true)
        setProgressDescription({
            success: 0,
            total: 0,
            errors: [],
        })
    }

    const handleClose = () => {
        setOpen(false)
        onUserIdListChange('',true)
        setProgressDescription({
            success: 0,
            total: 0,
            errors: [],
        })
    }

    const handleChange = (e:any) =>{
        onUserIdListChange(e.target.value)
    }

    const handleAddMultiply = () =>{
        onUserIdListChange(userIdList)
        setIsNeedToSendRequest(true)
    }

    const title = 'Add Multiple Users'
    
    const progressLog = useMemo(()=>{
        
        return(
            progressDescription.total !== 0 ?

                <>
                    <DialogContent sx={{width: '600px'}}>

                        <TextField
                            id="outlined-multiline-static"
                            // error = {!isUserIdListValid}
                            label="Progress"
                            multiline
                            rows={5}
                            value = { `SUCCESS - ${progressDescription.success}/${progressDescription.total}\nERRORS:\n ${progressDescription.errors.map(item=>`ErrorCode:  ${item.errorCode} \n Message: ${item.errorMessage}`).join('\n')}` }
                            fullWidth
                            onChange={ handleChange }
                        />
                    </DialogContent>

                    <DialogActions>
                        
                        <Button onClick={handleClose} >
                            Ok
                        </Button>

                    </DialogActions>
                </>
            :
                null
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[progressDescription])
    
    return (
        <div>
            <Button 
                variant="contained" 
                onClick={handleClickOpen} 
                sx={{ 
                    margin: '0 10px 0 0',
                    ...buttonContainedStyle, 
                }}
            >
                { title }
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle> { title } </DialogTitle>
                <DialogContent>
                    Paste list of user's emails separated by line break into text field.
                </DialogContent>
                <FormControl
                    error = {!isUserIdListValid}
                >

                    <DialogContent sx={{width: '600px'}}>

                        <TextField
                            id="outlined-multiline-static"
                            error = {!isUserIdListValid}
                            label="List of e-mails"
                            multiline
                            rows={10}
                            value = { userIdList }
                            fullWidth
                            onChange={ handleChange }
                        />
                        <FormHelperText>
                            { userIdListValidationError }
                        </FormHelperText>

                    </DialogContent>
                </FormControl>
                {
                    progressDescription.total === 0 ?

                        <DialogActions>
                            
                            <Button onClick={handleClose} >
                                Cancel
                            </Button>

                            <Button onClick={handleAddMultiply} >
                                Add
                            </Button>

                        </DialogActions>
                    :
                        null
                }
                

                { progressLog }
            </Dialog>
        </div>
    )
}
