import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { TCaseStatusTransition } from "../../../Types/Api/ApiCaseManagementResponse"
import useValidation, { enumFormValidationRule } from "../../formValidator"
import { TCaseManagementStatusTransitionTableHeaderId, TCaseManagementStatusTransitionTableHederDescriptor } from "./tableHeaderDescription"
import {
    Cancel as CancelIcon,
} from '@mui/icons-material'
import { Dispatch, SetStateAction, useEffect } from "react"
import TransitionAddForm from "./TransitionAddForm"
import { TWorkflowSetErrorPayload } from "../../../Types/Store/StoreWorkflow"
import TransitionDeleteForm from "./TransitionDeleteForm"
import colorLibrary from "../../../Utils/colors"

export type TTransitionsTableProps = {
    tableData: TCaseStatusTransition[]
    headerDescription: TCaseManagementStatusTransitionTableHederDescriptor[]
    setStatusFilter: Dispatch<SetStateAction<string>>
    setNeedUpdateFromAPI: Dispatch<SetStateAction<boolean>>
    avalableStatus: string[]
    setApiError: Dispatch<SetStateAction<TWorkflowSetErrorPayload>>
}

const TransitionsTable = (props: TTransitionsTableProps) => {
    
    const { 
        headerDescription,
        tableData,
        setStatusFilter,
        setNeedUpdateFromAPI,
        setApiError,
        avalableStatus,
    } = props
    
    const [
        filterStatus,
        isFilterStatusValid,
        ,
        onFilterStatusChange
    ] = useValidation([enumFormValidationRule.REQUIRED ], '')


    const onChangeStatusFilter = (statusValue:string) =>{
        onFilterStatusChange(statusValue)
    }

    useEffect(()=>{
        if(isFilterStatusValid){
            setStatusFilter(filterStatus)
            setNeedUpdateFromAPI(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isFilterStatusValid, filterStatus])

    return(
        
        <Box 
            sx={{ width: 'max-content' }}
        >
            <Paper>
                <Toolbar
                    sx={{
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 },
                        padding: '12px 0'
                    }}
                >
                    <TransitionAddForm 
                        avalableStatus       = { avalableStatus       }
                        setNeedUpdateFromAPI = { setNeedUpdateFromAPI }  
                        setApiError          = { setApiError          }
                    />
                    
                    <Box
                        sx={{display: 'flex'}}
                    >
                        <FormControl 
                            sx={{ width: '200px' }}
                            error={!isFilterStatusValid}
                            size='small'
                        >
                            <InputLabel id="search-status-label">Search By Status</InputLabel>
                            <Select
                                labelId="search-status-label"
                                value={filterStatus}
                                label="Search Status"
                                onChange={(e)=>{
                                    onChangeStatusFilter(String(e.target.value))
                                    
                                }}
                            >
                                {
                                    tableData.map((tableDataItem, tableDataItemIndex)=>{
                                        return(
                                            <MenuItem 
                                                value={ tableDataItem.status }
                                                key={`select-status-for-search-${tableDataItemIndex}`}
                                            > 
                                                { tableDataItem.status }
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        { 
                            filterStatus!==''  ?
                            <IconButton
                                onClick={()=>{
                                    setStatusFilter('')
                                    onFilterStatusChange('',true)
                                }}
                            >
                                <CancelIcon/>
                            </IconButton> 
                            
                            : null
                        } 
                    </Box>
                </Toolbar>

                {
                    tableData.length === 0 ?
                        <Box sx={{ 
                            width: '600px',
                            height: '300px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            Nothing to show
                        </Box>
                    :

                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size="small"
                            >
                                
                                <TableHead
                                    sx={{
                                        backgroundColor: colorLibrary.backgroundColor
                                    }}
                                >
                                    <TableRow>
                                        {
                                            headerDescription.map((headerItem, headerIndex)=>{
                                                return(
                                                    <TableCell
                                                        component={'td'}
                                                        key={headerItem.id}
                                                        align={ headerItem.align }
                                                        padding='normal'
                                                        sx={{
                                                            color:'#fff'
                                                        }}
                                                    >
                                                        { headerItem.label }
                                                    </TableCell>
                                                )
                                            })
                                        }

                                        <TableCell key={`empty-cell-for-buttons-column`}/>
                                    
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        tableData.map((tableDataItem, tableDataIndex)=>{
                                            

                                            return(
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={`case-status-transition-table-row${tableDataIndex}`}
                                                >
                                                    {
                                                        headerDescription.map((headerItem, headerIndex)=>{
                                                            let  result = null
                                                            const avalableHeaderItems:TCaseManagementStatusTransitionTableHeaderId[] = ['status','initial','transitions']
                                                            
                                                            if(avalableHeaderItems.indexOf(headerItem.id)>-1){

                                                                if(headerItem.id === 'transitions'){
                                                                    result = 
                                                                    <Table
                                                                        size="small"
                                                                    >
                                                                        <TableBody>

                                                                            {    
                                                                                tableDataItem[headerItem.id].map((transitionItem, transitionIndex)=>{
                                                                                    return(
                                                                                        <TableRow
                                                                                            key={`case-status-transition-table-row-subtable-row${transitionIndex}`}
                                                                                        >
                                                                                            <TableCell
                                                                                                sx={{
                                                                                                    border: '1px solid grey'
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    transitionItem
                                                                                                }
                                                                                            </TableCell>
                                                                                            
                                                                                        </TableRow>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </TableBody>
                                                                    </Table>
                                                                }else{
                                                                    result = String(tableDataItem[headerItem.id])
                                                                    if(result === 'null') result  = '0'
                                                                }
                                                            }
                                                            
                                                            
                                                            return(
                                                                <TableCell 
                                                                    align={ 
                                                                        headerItem.align 
                                                                    }
                                                                    sx={{
                                                                        verticalAlign: 'top'
                                                                    }}
                                                                    key={`case-status-transition-table-row${tableDataIndex}-${headerIndex}`}
                                                                > 
                                                                    { result }

                                                                </TableCell>
                                                            )
                                                        })
                                                    }

                                                    <TableCell 
                                                        key={`table-cell-for-action-buttons-${tableDataIndex}`}
                                                        align='center'
                                                        sx={{
                                                            verticalAlign: 'top'
                                                        }}
                                                    >
                                                        <TransitionAddForm
                                                            editedStatus         = { tableDataItem.status      }
                                                            editedVersion        = { tableDataItem.version !== null ? tableDataItem.version : 0 }
                                                            editedTransitions    = { tableDataItem.transitions }
                                                            editedInitial        = { tableDataItem.initial     }
                                                            avalableStatus       = { avalableStatus            }
                                                            setNeedUpdateFromAPI = { setNeedUpdateFromAPI      }  
                                                            setApiError          = { setApiError               }
                                                        />
                                                        <TransitionDeleteForm
                                                            status               = { tableDataItem.status      }
                                                            setNeedUpdateFromAPI = { setNeedUpdateFromAPI      }  
                                                            setApiError          = { setApiError               }
                                                            clearFilter          = { ()=>{ onFilterStatusChange('',true) } }
                                                        />
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                }
            </Paper>
        </Box>
    )
}

export default TransitionsTable
