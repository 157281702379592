import { AxiosResponse } from "axios"
import API from '../api-base'
import { enumApiParameter } from "../../../Types/General"
import { TApiGetListOfGroupConfigByApplicationSortByUpdatedAtV2         } from "../../../Types/Api/ApiDesktopConfigRequest"
import { TApiGetListOfGroupConfigByApplicationSortByUpdatedAtResponse } from "../../../Types/Api/ApiDesktopConfigResponse" 


// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=DesktopConfiguration%20service%20v2#/Group%20configuration%20API/list

const getListOfGroupConfigSortByUpdatedAt = async ( params: TApiGetListOfGroupConfigByApplicationSortByUpdatedAtV2 ): Promise<AxiosResponse<TApiGetListOfGroupConfigByApplicationSortByUpdatedAtResponse>>  =>{
    const requestParams: TApiGetListOfGroupConfigByApplicationSortByUpdatedAtV2= {...params}

    delete requestParams[enumApiParameter.APPLICATION]
    delete requestParams[enumApiParameter.VERSION]
    
    for (const [key, value] of Object.entries(requestParams)){
        if(key === enumApiParameter.DIRECTION && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.DIRECTION]
        }
        if(key === enumApiParameter.PAGE_NUMBER && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.PAGE_NUMBER]
        }
        if(key === enumApiParameter.PAGE_SIZE && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.PAGE_SIZE]
        }
        if(key === enumApiParameter.PERIOD_FROM && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.PERIOD_FROM]
        }
        if(key === enumApiParameter.PERIOD_TO && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.PERIOD_TO]
        }
    }
    

    return await API.get(
        `/v2/configurations/groups/${String(params[enumApiParameter.APPLICATION]).toLowerCase()}/${params[enumApiParameter.VERSION]}/by-updated`,
        { 
            params:{ ...requestParams} 
        }
    )
}

export default getListOfGroupConfigSortByUpdatedAt
