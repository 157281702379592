import { AxiosResponse } from "axios"
import API from './api-base'
import { TApiGetUsersInGroup         } from "../../Types/Api/ApiGroupsRequest"
import { TApiGetUsersInGroupResponse } from "../../Types/Api/ApiGroupsResponse"
import { enumApiParameter } from "../../Types/General"

// https://adalisk.stoplight.io/docs/tp-api/b3A6MzgxMTk2NjU-list-of-added-users-to-the-group

const getUsersInGroup = async ( params: TApiGetUsersInGroup ): Promise<AxiosResponse<TApiGetUsersInGroupResponse>>  =>{
    return await API.get(
        
        `/groups/${params[enumApiParameter.GROUP_ID]}/users`,

        { 
            params:{
                [enumApiParameter.DIRECTION  ]: params[enumApiParameter.DIRECTION],
                [enumApiParameter.PAGE_NUMBER]: params[enumApiParameter.PAGE_NUMBER],
                [enumApiParameter.PAGE_SIZE  ]: params[enumApiParameter.PAGE_SIZE]
            } 
        }
    )
}

export default getUsersInGroup
