import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react"
// import {
//     Comment as CommentIcon 
// } from '@mui/icons-material'

export type TTransitionListProps = {
    transitionList: string[]
    setSelectedTransitions: Dispatch<SetStateAction<string[]>>
    editedTransitions?:string[]
}

const TransitionList = ( props: TTransitionListProps ) => {
    const { transitionList, setSelectedTransitions, editedTransitions } = props    

    let initialIndexes:number[] = []

    if(editedTransitions){

        transitionList.forEach((item, index)=>{
            if(editedTransitions.indexOf( item ) >-1){
                initialIndexes.push( index )
            }
        })
    }

    const [ checkedIndexes, setCheckedIndexes] = useState<number[]>(initialIndexes)

    const handleToggle = (value: number) => () => {     
        const currentIndex = checkedIndexes.indexOf(value)
        const newChecked = [...checkedIndexes]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setCheckedIndexes(newChecked)
    }

    useEffect(()=>{
        setSelectedTransitions([
            ...transitionList.filter((transitionItem, transitionIndex)=>{
                return( checkedIndexes.indexOf(transitionIndex) > -1 )
            })
        ])
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[checkedIndexes])
    
    return (
        <List sx={{ width: '100%', maxWidth: 360 }}>
            {
                transitionList.map( (transitionItem , transitionIndex ) =>{
                    const labelId = `checkbox-transition-list-label-${transitionIndex}`
                    return (
                        <ListItem
                            key={labelId}
                            disablePadding
                        >
                            <ListItemButton role={undefined} onClick={handleToggle(transitionIndex)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={ checkedIndexes.indexOf(transitionIndex) !== -1 }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={ transitionItem } />
                            </ListItemButton>
                        </ListItem>
                    )
                })
                
            }
        </List>
    )
}

export default TransitionList
