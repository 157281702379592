import React from 'react'
import ReactDOM from 'react-dom'
import './index.sass'
import { BrowserRouter } from 'react-router-dom'
import Application from './App/application'
import { Provider } from 'react-redux'
import { store } from './App/Store'

const env = window['REACT_APP_ENV'] ? window['REACT_APP_ENV'] : process.env.REACT_APP_ENV
document.title = `${ env ? `[${env}] ` : '' }TP-Console`

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store = { store } >
                <Application />
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
)
