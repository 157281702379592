import { TApiGetMtpCaseByCaseIdResponse } from "../Api/ApiMtpResponse"
import { enumSORT } from "../General"





export type TMtpCase = TApiGetMtpCaseByCaseIdResponse

export type TMtpCaseListItem = {
    id: string
    status: string
    info: string
    details: string
    smoVersion: string
    createdAt: string
    completedAt: string
    selfUrl: string
}

export type IStoreMobileTreatmentPlan = {
    caseDescription:{
        needUpdateFromAPI: boolean
        mtpCase: TMtpCase | undefined
    }

    mtpCasesList:{
        needUpdateFromApi: boolean
        filterData: TStoreMTPFilterData
        list: TMtpCaseListItem[]
    }

    error: IStoreErrorDescritpion
}

export type TStoreError = {
    status    : string
    message   : string
    path      : string
}

export interface IStoreErrorDescritpion extends TStoreError  {
    needToShow: boolean
}

export type TStoreMTPFilterData = {
    direction     : enumSORT
    'page.number' : number
    'page.size'   : number
    'period.from' : string
    'period.to'   : string
    status        : string
    info          : string
}


export enum enumStoreMtpAction {
    SET_MTP_NEED_UPDATE_CASE_FROM_API = 'SET_MTP_NEED_UPDATE_CASE_FROM_API',
    SET_MTP_CASE                      = 'SET_MTP_CASE',
    SHOW_MTP_ERROR                    = 'SHOW_MTP_ERROR',
    HIDE_MTP_ERROR                    = 'HIDE_MTP_ERROR',
    
    SET_MTP_NEED_UPDATE_CASES_LIST_FROM_API = 'SET_MTP_NEED_UPDATE_CASES_LIST_FROM_API',
    SET_MTP_LIST_FILTER_DATA = 'SET_MTP_LIST_FILTER_DATA',
    SET_MTP_LIST             = 'SET_MTP_LIST',
    SET_MTP_LIST_PAGE_NUMBER = 'SET_MTP_LIST_PAGE_NUMBER',
    SET_MTP_LIST_PAGE_SIZE   = 'SET_MTP_LIST_PAGE_SIZE',
    SET_MTP_LIST_PERIOD      = 'SET_MTP_LIST_PERIOD',
    SET_MTP_LIST_DIRECTION   = 'SET_MTP_LIST_DIRECTION',

}


export type TMtpStoreAction =   TMtpActionSetNeedUpdateCaseFromAPI |
                                TMtpActionSetCase |
                                TMtpActionShowError |
                                TMtpActionHideError |
                                
                                TMtpActionSetNeedUpdateCasesListFromAPI |
                                TMtpActionSetList |
                                TMtpActionSetListPageNumber |
                                TMtpActionSetListPageSize |
                                TMtpActionSetListPeriod |
                                TMtpActionSetListDirection |
                                TMtpActionSetFilterData



export type TMtpActionSetNeedUpdateCaseFromAPI = {
    type: enumStoreMtpAction.SET_MTP_NEED_UPDATE_CASE_FROM_API
    payload: boolean
}

export type TMtpActionSetCase = {
    type: enumStoreMtpAction.SET_MTP_CASE
    payload: TApiGetMtpCaseByCaseIdResponse
}

export type TMtpActionShowError = {
    type: enumStoreMtpAction.SHOW_MTP_ERROR
    payload: TStoreError
}

export type TMtpActionHideError = {
    type: enumStoreMtpAction.HIDE_MTP_ERROR
}

export type TMtpActionSetNeedUpdateCasesListFromAPI = {
    type: enumStoreMtpAction.SET_MTP_NEED_UPDATE_CASES_LIST_FROM_API
    payload: boolean
}


export type TMtpActionSetList = {
    type: enumStoreMtpAction.SET_MTP_LIST
    payload: TMtpCaseListItem[]
}

export type TMtpActionSetListPageNumber = {
    type: enumStoreMtpAction.SET_MTP_LIST_PAGE_NUMBER
    payload: number
}

export type TMtpActionSetListPageSize = {
    type: enumStoreMtpAction.SET_MTP_LIST_PAGE_SIZE
    payload: number
}

export type TMtpActionSetListPeriod = {
    type: enumStoreMtpAction.SET_MTP_LIST_PERIOD
    payload: {
        'period.from': string
        'period.to'  : string
    }
}

export type TMtpActionSetListDirection = {
    type: enumStoreMtpAction.SET_MTP_LIST_DIRECTION
    payload: enumSORT
}

export type TMtpActionSetFilterData = {
    type: enumStoreMtpAction.SET_MTP_LIST_FILTER_DATA
    payload: TStoreMTPFilterData
}
