import { CircularProgress } from "@mui/material"
import { Box } from "@mui/system"
import { useEffect, useState } from "react"
import getFileListByCaseId from "../../../../Api/CaseManagement/v2/get-filelist-by-case-id"
import { useAction } from "../../../../Store/Hook/use-action"
import { TCaseFileDescription } from "../../../../Types/Api/ApiCaseManagementResponse"

import FilesToolbar from "./FilesToolbar"
import FilesTree from "./FilesTree"


export type TCaseFilesProps = {
    caseId:string | undefined
    setFilesToOpenInSMI: (filesToOpen:string[], isReset?: boolean)=>void
}

export type TCaseFileItem = {
    filePath: string[],
    fileDescription: TCaseFileDescription
}

export type TCaseFilesState = {
    filesList         : TCaseFileDescription[]
    newFolderPath     : string[][]
    expandedNodes     : string[]
    selectedNodes     : string[]
    isFilesListLoaded : boolean
    isFileUploaded    : boolean
    uploadProgress    : number
}

export const caseFilesInfoBarStyle = {
    width: '100vw',
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}



export type TNode = {
    id               : string
    type             : 'FOLDER' | 'FILE'
    name             : string
    path             : string[]
    child            : TNode[]
    fileDescription? : TCaseFileDescription
}

export type TNodeRecord = {
    path: string[]
    fileDescription?: TCaseFileDescription
}



const CaseFiles = (props: TCaseFilesProps) =>{

    const { 
        caseId,
        setFilesToOpenInSMI,
    } = props

    const [ state, setState] = useState<TCaseFilesState>({
        filesList:[],
        
        newFolderPath: [],
        
        expandedNodes:['ROOTFOLDER|'],
        selectedNodes:['ROOTFOLDER|'],
        isFilesListLoaded: false,
        isFileUploaded: false,
        uploadProgress: -1
    })

    const { setCaseManagementError, setCaseManagementErrorNeedToShow } = useAction()

    useEffect(()=>{
        if(caseId && state.isFilesListLoaded === false){
            setFilesToOpenInSMI([], true)
            getFileListByCaseId({
                id: caseId //: '5446bd91-c5c6-45d1-9bd4-d2bcbdf2e9a3'
            })
            .then((response)=>{
                setState(prev=>({
                    ...prev,
                    filesList: response.data,
                    newFolderPath: prev.newFolderPath.filter((folderPath)=>{ //
                        let result = true
                        for(let i = 0; i<response.data.length; i++){
                            if(response.data[i].s3key.indexOf('/'+folderPath.join('/')+'/') > -1){
                                result = false
                                break
                            }
                        }
                        return(result)
                    }),
                }))
            })
            .catch((error)=>{
                setCaseManagementError({
                    status  : error.response.data.status  ? String(error.response.data.status)  : '',
                    path    : error.response.data.path    ? String(error.response.data.path)    : '',
                    message : error.response.data.message ? String(error.response.data.message) : "CANT GET CASE FILE LIST"
                })
                setCaseManagementErrorNeedToShow(true)
                console.log('CANT GET CASE FILE LIST', JSON.stringify(error))
            })
            .finally(()=>{
                setState(prev=>({
                    ...prev,
                    isFilesListLoaded: true,
                }))
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[caseId, state.isFilesListLoaded])


    const setNewFolder = (newFolder:string)=>{
        if(state.selectedNodes.length === 1 && state.selectedNodes[0].indexOf('FOLDER|')>-1){
            const splittedFolder = state.selectedNodes[0].split('/')
            const currentFolder = splittedFolder.slice(1,splittedFolder.length)
            setState((prev)=>{
                const  prevCopyNewFolderPath = [...prev.newFolderPath]
                prevCopyNewFolderPath.push([...currentFolder,newFolder])
                const prevCopyExpandedNodes = [...prev.expandedNodes]
                prevCopyExpandedNodes.push(state.selectedNodes[0])
                return({
                    ...prev,
                    newFolderPath:  prevCopyNewFolderPath,
                    expandedNodes: prevCopyExpandedNodes,
                })
            })
        }
    }

    const removeSelectedNewFolder = ()=>{

        if(state.selectedNodes.length === 1 && state.selectedNodes[0].indexOf('FOLDER|')>-1){
            const splittedFolder = state.selectedNodes[0].split('/')
            const currentFolder = splittedFolder.slice(1,splittedFolder.length)
            setState((prev)=>{
                let  prevCopyNewFolderPath = [...prev.newFolderPath]
                prevCopyNewFolderPath = prevCopyNewFolderPath.filter((folderPathArray)=>{
                    return(
                        folderPathArray.length === currentFolder.length && 
                        folderPathArray.join('/').indexOf(currentFolder.join('/')) ===-1
                    )
                })
                return({
                    ...prev,
                    newFolderPath: prevCopyNewFolderPath,
                })
            })
        }
    }

    return(
        
        <div
            style={{
                minHeight: 100
            }}
        >

            {
                state.filesList.length === 0 &&
                <Box 
                    sx={caseFilesInfoBarStyle}
                >
                    {
                        state.isFilesListLoaded === false ?
                            <CircularProgress />
                        :
                            <>There are no files in case yet</>
                    }
                </Box>
            }

            {
                state.filesList.length > 0 &&
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    
                }}>
                    <FilesToolbar
                        caseId             = { caseId ? caseId : ''}
                        selectedNodes      = { state.selectedNodes }
                        filesList          = { state.filesList     }
                        setNewFolder       = { setNewFolder        }
                        removeSelectedNewFolder    = { removeSelectedNewFolder     }
                        isFileUploaded     = { state.isFileUploaded }
                        pathInCase         = { state.selectedNodes  }
                        uploadProgress     = { state.uploadProgress }
                        setFileUploaded  = { (isFileUploaded:boolean)=>{
                            setState(prev=>({...prev, isFileUploaded}))
                        }}
                        setFileListNotLoaded = {()=>{
                            setState(prev=>({...prev, isFilesListLoaded: false}))
                        }}
                        setUploadProgress = {(progressInPercent: number)=>{
                            setState(prev=>({...prev, uploadProgress: progressInPercent}))
                        }}
                        setFilesToOpenInSMI = { setFilesToOpenInSMI }
                    />
                    
                    <FilesTree 
                        filesList          = { state.filesList     }
                        newFolderPath      = { state.newFolderPath }
                        needUpdateFileTree = { ()=>{ setState(prev =>({ ...prev, isFilesListLoaded: false })) }}
                        expandedNodes      = { state.expandedNodes }
                        setExpandedNodes   = { (newExpandedNodes:string[])=>{ setState(prev=>({ ...prev, expandedNodes: [...newExpandedNodes] })) }}

                        setSelectedNodes   = { (newSelectedNodes:string[])=>{ setState(prev=>({ ...prev, selectedNodes: [...newSelectedNodes] })) }}
                    />
                </Box>
            }
            
        </div>

    )
}

export default CaseFiles
