import { Box, Button, Checkbox, FormControl, FormHelperText, Modal, SxProps, TextField, Theme, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import postRuleAdd from "../../../Api/Workflow/post-rule-add"
import { useAction } from "../../../Store/Hook/use-action"
import { TWorkflowRule, TWorkflowRuleAction, TWorkflowRuleNew } from "../../../Types/Store/StoreWorkflow"
import useValidation, { enumFormValidationRule } from "../../formValidator"
import AceEditor from "react-ace"
import "ace-builds/src-noconflict/ace"
import "ace-builds/src-noconflict/mode-groovy"
import "ace-builds/src-noconflict/theme-eclipse"
import putRuleUpdate from "../../../Api/Workflow/put-rule-update"
import { useTypedSelector } from "../../../Store/Hook/use-typed-selector"
import ErrorModal from "./ErrorModal"
import ActionViewer from "./ActionViewer"
import { buttonContainedStyle, buttonOutlinedStyle } from "../../../Utils/colors"

export type TCreateRuleProps = {
    rule?: TWorkflowRule
}

export const modalEditorStyle:SxProps<Theme> = {
    backgroundColor: '#ffffff',
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '30px',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    overflowY: 'auto',
}

const CreateRule = ( props: TCreateRuleProps ) =>{

    const { rule } = props

    const [ open               , setOpen             ] = useState(false)
    const [ isEnabled          , setEnabled          ] = useState( rule ? rule.enabled : false)
    const [ isNeedToCreateRule , setNeedToCreateRule ] = useState(false)
    const [ actionList         , setActionList       ] = useState<TWorkflowRuleAction[]>( rule ? rule.actions : [])
    const { error } = useTypedSelector(state=> state.workflow)
    
    const { 
        pagination, 
    } = useTypedSelector(state=>state.workflow)

    const { 
        setWorkflowRulesNeedUpdateFromAPI,
        setWorkflowError,
    } = useAction()

    const clearFields = () =>{
        onRuleNameChange(rule ? rule.name : '', true)
        onConditionChange(rule ? rule.condition :'', true)
        onOrderValueChange(rule ? String(rule.order) : '1',true)
        setActionList(rule ? rule.actions : [])
        setWorkflowError({
            needToShow: false,
            message: '',
            path: '',
            status: ''
        })
    }

    const handleClose = () =>{
        clearFields()
        setOpen(false)
    }

    const handleOpen = () =>{
        clearFields()
        setOpen(true)
        if(!rule){
            onOrderValueChange(String(pagination.totalElements + 1), true )
        }
    }

    const [
        ruleName,
        isRuleNameValid,
        ruleNameValidationError,
        onRuleNameChange

    ] = useValidation([enumFormValidationRule.REQUIRED], rule ? rule.name : undefined)

    const [
        condition,
        isConditionValid,
        conditionValidationError,
        onConditionChange

    ] = useValidation([enumFormValidationRule.REQUIRED], rule ? rule.condition : undefined)

    const [
        orderValue,
        isOrderValueValid,
        orderValueValidationError,
        onOrderValueChange

    ] = useValidation([enumFormValidationRule.REQUIRED], rule ? String(rule.order) : String(pagination.totalElements + 1))


    const onCreateRule = () =>{
        onRuleNameChange(ruleName)
        onConditionChange(condition)
        onOrderValueChange(orderValue)
        setNeedToCreateRule(true)
    }



    useEffect(()=>{
        if(isNeedToCreateRule === true){
            setNeedToCreateRule(false)
        
            if(
                isRuleNameValid &&
                isConditionValid &&
                isOrderValueValid
            ){
                
                if(typeof(rule) === 'undefined'){

                    const newRule:TWorkflowRuleNew = {
                        name: ruleName,
                        order: parseInt(orderValue),
                        condition: condition,
                        enabled: isEnabled,
                        actions: actionList
                    }
                    
                    postRuleAdd(newRule)
                    .then((response)=>{
                        setWorkflowRulesNeedUpdateFromAPI(true)
                        clearFields()
                        handleClose()
                    })
                    .catch((error)=>{
                        setWorkflowError({
                            needToShow: true,
                            message: error.response.data.message,
                            path: error.response.data.path,
                            status: error.response.data.status
                        })
                        console.log('CANT CREATE NEW RULE', JSON.stringify(error))
                    })
                }else{
                    const ruleForUpdate = {
                        ...rule,
                        name: ruleName,
                        condition: condition,
                        enabled: isEnabled,
                        order: parseInt(orderValue) ,
                        actions: actionList
                    }

                    putRuleUpdate(ruleForUpdate)
                    .then((response)=>{
                        setWorkflowRulesNeedUpdateFromAPI(true)
                        handleClose()
                    })
                    .catch((error)=>{
                        setWorkflowError({
                            needToShow: true,
                            message: error.response.data.message,
                            path: error.response.data.path,
                            status: error.response.data.status
                        })
                        console.log("CANT UPDATE CASE-WORKFLOW RULE", JSON.stringify(error))
                    })
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedToCreateRule])


    return(
        <Box 
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                margin:  rule ? undefined : '0 0 10px 0'
            }}
        >

            {
                rule ?
                    <Button
                        onClick={handleOpen}
                        variant="outlined" color='primary'
                        sx={ buttonOutlinedStyle }
                    >
                        Edit
                    </Button>
                :
                    <Button
                        onClick={handleOpen}
                        variant="contained" color='primary'
                        sx={ buttonContainedStyle }
                    >
                        Create Rule
                    </Button>


            }
            <Modal
                open={open}
                onClose={handleClose}
            >
                    <Box 
                        sx={ modalEditorStyle }
                    >
                        

                        <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    oveerFlowY: 'scroll'

                                }}
                            >
                                <Typography 
                                    id="modal-modal-title" 
                                    variant="h5" 
                                    component="h1"
                                    sx={{
                                        margin: '0 0 20px 0',
                                    }}
                                >
                                    {
                                        rule ?
                                            'Edit rule'
                                        :
                                            'Create rule'
                                    }
                                </Typography>

                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>

                                    <div style={{margin: '0 20px 0 0'}}>
                                        <FormControl fullWidth error={!isOrderValueValid} >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    padding: '6px 20px 6px 10px',
                                                    margin: '0 16px 18px 0',
                                                    width: '100%',
                                                    border: '1px solid #bbb',
                                                    borderRadius: '4px'
                                                }}
                                            >
                                        

                                                        <Checkbox 
                                                            checked = {isEnabled}
                                                            onChange = {(e)=>{
                                                                setEnabled( prev => !prev  )
                                                            }} 
                                                        />
                                                        <Typography>
                                                            Enabled 
                                                        </Typography>
                                                
                                            </Box>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <FormControl fullWidth error={!isOrderValueValid} >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    margin: '0',
                                                    width: '100%'
                                                }}
                                            >
                                                <TextField
                                                    margin="none"
                                                    id="configJson"
                                                    sx={{margin: '0 0 20px 0'}}
                                                    label= { 'Priority' }
                                                    type="number"
                                                    variant="outlined"
                                                    value={ orderValue ? orderValue : 1 }
                                                    onChange={(e)=>{
                                                        const value = parseInt(e.target.value)
                                                        if(value>0){
                                                            onOrderValueChange(e.target.value)
                                                        }
                                                    }}
                                                    fullWidth
                                                />
                                                <FormHelperText>
                                                    { orderValueValidationError }
                                                </FormHelperText>
                                            </Box>
                                        </FormControl>
                                    </div>
                                </Box>
                                
                            </Box>

                            
                            

                            <FormControl fullWidth error={!isRuleNameValid} >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        margin: '20px 0 0 0',
                                        width: '100%'
                                    }}
                                >
                                    
                                    <TextField
                                        margin="none"
                                        id="configJson"
                                        sx={{margin: '0 0 20px 0'}}
                                        label= { 'Rule Name' }
                                        type="text"
                                        variant="outlined"
                                        value={ ruleName }
                                        onChange={(e)=>{ onRuleNameChange(e.target.value) }}
                                        fullWidth
                                    />
                                    <FormHelperText>
                                        { ruleNameValidationError }
                                    </FormHelperText>
                                </Box>
                            </FormControl>

                            <div style={{
                                margin: '0 0 8px 12px',
                                fontSize: '1.25rem',
                            }}>
                                Condition
                            </div>

                            <Box sx={{
                                margin: '0 24px 16px 0',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flexDirection: 'column',

                            }}>
                                
                                <AceEditor
                                    mode="groovy"
                                    theme="eclipse"
                                    onChange={(value, stat) => {
                                        onConditionChange(value)
                                    }}
                                    value={ 
                                        condition
                                    }
                                    name="UNIQUE_ID_OF_DIV"
                                    editorProps={{ $blockScrolling: true }}
                                    style={{
                                        height: '100px',
                                        width: '100%',
                                        border: '1px solid grey',
                                    }}
                                />

                                {
                                    conditionValidationError &&
                                    <FormHelperText error = {true}>
                                        { conditionValidationError }
                                    </FormHelperText>
                                }

                                <div style={{
                                    margin: '20px 0 8px 12px',
                                    fontSize: '1.25rem',
                                }}>
                                    Actions
                                </div>

                                <ActionViewer 
                                    actionList = { actionList }
                                    setActions = { setActionList }
                                />

                            </Box>
                            


                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    margin: '20px 24px 0 0'
                                }}
                            >
                                <Button
                                    variant="contained" color='primary'
                                    size="large"
                                    onClick = { handleClose }
                                    sx={{ margin: '0 40px 0 0' }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant="contained" color='primary'
                                    size="large"
                                    onClick = { onCreateRule }
                                >
                                    {
                                        rule ?
                                            'Update'
                                        :
                                            'Create Rule'
                                    }
                                </Button>
                            </Box>


                            <ErrorModal 
                                error        = { error }
                                errorControl = { (isVisible:boolean)=>{
                                    setWorkflowError({
                                        needToShow: isVisible,
                                        message: '',
                                        path: '',
                                        status: ''
                                    })
                                } } 
                            />


                    </Box>
                </Modal>

        </Box>
    )
}

export default CreateRule
