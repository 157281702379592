import * as React from 'react'
import { styled } from '@mui/material/styles'
import { 
    Box,
    Button,
    Toolbar,
    CssBaseline,
    Typography,
    IconButton,
    Tooltip,
} from '@mui/material'


import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Preloader from './Preloader/Preloader'
import { 
    Menu          as MenuIcon,
    ExitToApp     as ExitToAppIcon, 
    AccountCircle,
    Close         as CloseIcon,
} from '@mui/icons-material'

import { Outlet, useRoutes } from 'react-router-dom'
import { authClient } from '../application'

import { useTypedSelector } from '../Store/Hook/use-typed-selector'
import LeftSideMenu from './SideMenu/SideMenu'
import colorLibrary from '../Utils/colors'
import { NavLink } from 'react-router-dom'
import headerNameRoutes from './headerName'

const drawerWidth = 240


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(
    MuiAppBar, 
    {
        shouldForwardProp: (prop) => prop !== 'open',
    }
)

    <AppBarProps>
        (({ theme, open }) => ({
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            ...(open && {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
                }),
            }),
            backgroundColor: colorLibrary.backgroundColor,
        }))



const Layout = () => {
    const [open, setOpen] = React.useState(false)

    const handleDrawer = () => {
        setOpen(!open)
    }

    let headerName = useRoutes(headerNameRoutes)

    const { email } = useTypedSelector(store=>store.base.userInfo)
    
    return (
    
        !email ?

            <Preloader/>

        :
            
            <Box sx={{ display: 'flex' }}>
                
                <CssBaseline />
                
                <AppBar position="fixed" >
                    
                    <Toolbar>

                        <Tooltip 
                            title={ !open ? 'Open Menu' : 'Close Menu' }
                            placement="right-end"
                        >

                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawer}
                                edge="start"
                                sx={{
                                    marginRight: '24px',
                                }}
                            >
                                {
                                    open ?
                                        <CloseIcon/>
                                    :
                                        <MenuIcon />
                                }
                            </IconButton>
                        </Tooltip>
                        
                        

                        <Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1, display : 'flex', alignItems: 'center'}}>
                            
                                <NavLink
                                    to= { '/' }  
                                    style={{
                                        display: 'flex',
                                        textDecoration: 'none',
                                        alignItems: 'center',
                                        color: '#fff',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: '#FFFFFF',
                                            padding: 5,
                                            borderRadius: 5,
                                            marginRight: 20
                                        }}
                                    >
                                        <img
                                            src="/images/logo-sdc.svg"
                                            alt=""
                                            width={50}
                                        />
                                    </div>
                                    <div style={{opacity: 0.65, marginRight: '20px'}}>
                                        TP-Console
                                    </div>  
                                </NavLink>
                                
                                { headerName }

                        </Typography>
                        
                        <AccountCircle />

                        <Typography component="span" sx={{ fontSize: 12, margin: '0 16px 0 8px'}}>
                            {
                                email
                            }
                        </Typography>
                        
    
                        <Tooltip title={ 'Logout' }>
                            <Button 
                                color="inherit"
                                onClick={()=>{
                                    authClient.signOut()
                                }}
                            >
                                <ExitToAppIcon/>
                            </Button>
                        </Tooltip>

                    </Toolbar>
                </AppBar>

                <LeftSideMenu 
                    isOpened={ open } 
                    setOpened={setOpen}
                />

                
                <Box 
                    component="main" 
                    sx={{ 
                        flexGrow: 1,
                    }}
                >
                    <DrawerHeader />
                    <Box>
                        <Outlet></Outlet>
                    </Box>
                </Box>
            </Box>
    )
}

export default Layout
