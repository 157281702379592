import { AxiosResponse } from "axios"
import API from './api-base'
import { TApiGetGroup          } from "../../Types/Api/ApiGroupsRequest"
import { TApiGetGroupResponce  } from "../../Types/Api/ApiGroupsResponse"
import { enumApiParameter } from "../../Types/General"

// https://adalisk.stoplight.io/docs/tp-api/b3A6MzgxMTk2MTY-get-group

const getGroup = async ( params: TApiGetGroup ): Promise<AxiosResponse<TApiGetGroupResponce>>  =>{
    return await API.get(
        
        `/groups/${params[enumApiParameter.GROUP_ID]}`
    
    )
}

export default getGroup
