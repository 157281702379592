import { Box, Button, TableBody, TableCell, TableRow } from "@mui/material"
import { TTableHederDescriptor } from "./header"
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { TWorkflowRule } from "../../../Types/Store/StoreWorkflow"
import EditRule from "../CreateRule/CreateRule"
import deleteRule from "../../../Api/Workflow/delete-rule";
import { useAction } from "../../../Store/Hook/use-action";

export type TRuleTableDataItem = {
    name    : string
    order   : number,
    active: boolean,
    version : number
    ruleText: string
}

export type TRulesTableBodyProps = {
    headerDescription: TTableHederDescriptor[]
    data: TWorkflowRule[]
}

const RulesTableBody = (props: TRulesTableBodyProps) => {

    const { headerDescription, data } = props
    const { 
        setWorkflowRulesNeedUpdateFromAPI,
        setWorkflowError,
    } = useAction()

    return(
        <TableBody>
            {
                data.map((item,index)=>{
                    
                    return(
                        <TableRow
                            hover
                            key={index}
                            sx={{ 
                                cursor: 'default',
                                backgroundColor: item.enabled ? undefined : '#eee'
                            }}
                        >
                            {

                                headerDescription.map((headerItem,index)=>{
                                    
                                    switch(headerItem.id){
                                        case 'name':
                                            return(
                                                <TableCell
                                                    key={ index }
                                                    align={ headerItem.numeric ? 'right' : 'left' }  
                                                >
                                                   
                                                        { item[headerItem.id] }
                                               
                                                </TableCell>
                                            )
                                        case 'enabled':
                                            return(
                                                <TableCell 
                                                    key={ index }
                                                    align={ 'center' }
                                                >
                                                    { item[headerItem.id] ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/> }
                                                </TableCell>
                                            )
                                        case 'edit':
                                            return(
                                                <TableCell 
                                                    key={ index }
                                                    align={ 'right' }
                                                    sx={{width: '160px'}}
                                                >
                                                    <Box
                                                        sx={{display:'flex'}}
                                                    >
                                                        <Button
                                                            variant="outlined" 
                                                            color='error'
                                                            onClick={()=>{
                                                                deleteRule({
                                                                    ruleId: item.id
                                                                })
                                                                .then((response)=>{
                                                                    setWorkflowRulesNeedUpdateFromAPI(true)
                                                                })
                                                                .catch((error)=>{
                                                                    setWorkflowError({
                                                                        needToShow: true,
                                                                        message: error.response.data.message,
                                                                        path: error.response.data.path,
                                                                        status: error.response.data.status
                                                                    })
                                                                    console.log("CANT DELETE CASE-WORKFLOW RULE", JSON.stringify(error))
                                                                })
                                                            }}
                                                        >
                                                            DELETE
                                                        </Button>
                                                        <EditRule key={`edit-rule-${ index }-${ item.id }`} rule = { item } />

                                                    </Box>
                                                </TableCell>
                                            )
                                        default:
                                            return(
                                                <TableCell
                                                    key={ index } 
                                                    align={ headerItem.numeric ? 'right' : 'left' } 
                                                >
                                                    { item[headerItem.id] }
                                                </TableCell>
                                            )
                                    }
                                    
                                    
                                })
                                
                            }
                            
                           
                            

                        </TableRow>
                    )
                    
                })
            }

        </TableBody>
    )


}

export default  RulesTableBody
