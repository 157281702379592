import { useState, useEffect } from 'react'
import { Paper, Table, TableContainer } from "@mui/material"
import { useAction } from "../../../../Store/Hook/use-action"
import { useTypedSelector } from "../../../../Store/Hook/use-typed-selector"
import { ITableHeaderDescription } from "../../../../Types/Components/table"
import { IDesktopGroupConfig, IDesktopUserConfig } from "../../../../Types/Store/StoreDesktopConfig"
import ConfigTableBody from "./ConfigTableBody"
import ConfigTableHeader from "./ConfigTableHeader"
import { userTableHeaderDescription } from "./tableHeaderDescription"
import createBoolArrayWithFixedLength from './createBoolArrayWithFixedLength'
import onDownloadConfig from './onDownloadConfig'
import MultiplySelectionTools from '../ToolBar/MultiplySelectionTools'
import { TApiPostCreateUpdateUserConfig } from '../../../../Types/Api/ApiDesktopConfigRequest'
import deleteUserConfig from '../../../../Api/DesktopConfiguration/v2/delete-user-config'
import { enumApiParameter, enumSortingField } from '../../../../Types/General'
import getListOfUserConfigSortByLoginV2 from '../../../../Api/DesktopConfiguration/v2/get-list-of-user-config-sort-by-login'
import getListOfUserConfigSortByUpdatedAtV2 from '../../../../Api/DesktopConfiguration/v2/get-list-of-user-config-sort-by-updated-at'
import { enumConfigType } from '../Dialogs/CreateConfig'
import putUpdateUserConfig from '../../../../Api/DesktopConfiguration/v2/put-update-user-config'

export type TToolbarUserConfig = {
    configsFor: enumConfigType.USER
    config    : IDesktopUserConfig[]
    headerDescription: ITableHeaderDescription[]     
}

export type TToolbarGroupConfig = {
    configsFor: enumConfigType.GROUP
    config    : IDesktopGroupConfig[]
    headerDescription: ITableHeaderDescription[] 
}

export type TToolbarData = TToolbarUserConfig | TToolbarGroupConfig

const UserConfigsTable = () => {

    const {
        configs,
        needUpdateFromAPI,
        selectedApplicationVersion,
        sortingField,
        sortingDirection,
        number,
        size,
        loginFilter,
        periodFrom,
        periodTo
    } = useTypedSelector(store => store.desktopConfigurations.users)

    const {
        SetUsersDesktopConfigurationsFromAPI,
        SetNeedUpdateUsersConfigsFromAPI,
        SetUsersPagination,
        SetUsersSortingDirection,
        SetError,
        SetErrorNeedToShow,
    } = useAction()

    const listData:TToolbarData =  {
        configsFor: enumConfigType.USER,
        config: configs,
        headerDescription: userTableHeaderDescription
    }

    const [ checkedRow, setCheckedRow ] = useState<boolean[]> (createBoolArrayWithFixedLength(configs.length))
    
    const onCheckRow = (checkedRowIndex: number) =>{

        const result = []
        for(let i = 0; i < checkedRow.length; i++){
            if(i === checkedRowIndex){
                result.push(!checkedRow[i])
            }else{
                result.push(checkedRow[i])
            }
        }

        setCheckedRow(result)
    }

    const onUserConfigUpdate = (
        params: TApiPostCreateUpdateUserConfig
    ) =>{
        putUpdateUserConfig({
            ...params
        }).then((response)=>{
            setCheckedRow(createBoolArrayWithFixedLength(configs.length))
            SetNeedUpdateUsersConfigsFromAPI(true)
        }).catch((error)=>{
            SetError({
                status  : error.response.data.status ? String(error.response.data.status) : '',
                path    : error.response.data.path   ? String(error.response.data.path)   : '',
                message : error.response.data.message ? String(error.response.data.message) : 'CANT CREATE/UPDATE USER CONFIG'
            })
            SetErrorNeedToShow(true)
            console.log("CANT CREATE/UPDATE APPLICATION CONFIG", JSON.stringify(error))
        })
    }

    const onConfigDelete = ( checkedRow: boolean[]) =>{
        if(checkedRow){
            const checkedIndex: number[] = []
            
            for(let i = 0; i<checkedRow.length; i++){
                if(checkedRow[i] === true){
                    checkedIndex.push(i)
                }
            }

            for(let i = 0; i<checkedIndex.length; i++){
                
                deleteUserConfig ({
                    application: selectedApplicationVersion ? selectedApplicationVersion.name : '',
                    version: selectedApplicationVersion ? selectedApplicationVersion.version : '',
                    login: configs[checkedIndex[i]].login 
                })
                .then(()=>{
                    SetNeedUpdateUsersConfigsFromAPI(true)
                })
                .catch((error)=>{
                    SetError({
                        status  : error.response.data.status ? String(error.response.data.status) : '',
                        path    : error.response.data.path   ? String(error.response.data.path)   : '',
                        message : error.response.data.message ? String(error.response.data.message) : 'CANT DELETE USER CONFIG'
                    })
                    SetErrorNeedToShow(true)
                    console.log("CANT DELETE USER CONFIG", JSON.stringify(error))
                })
            }
        }
    }

    useEffect(()=>{
        if(needUpdateFromAPI === true){
            
            if(sortingField === enumSortingField.LOGIN){
                getListOfUserConfigSortByLoginV2({
                    [enumApiParameter.APPLICATION]: selectedApplicationVersion ? selectedApplicationVersion.name : '',
                    [enumApiParameter.VERSION    ]: selectedApplicationVersion ? selectedApplicationVersion.version : '',
                    [enumApiParameter.DIRECTION  ]: sortingDirection,
                    [enumApiParameter.LOGIN      ]: loginFilter,
                    [enumApiParameter.PAGE_NUMBER]: number,
                    [enumApiParameter.PAGE_SIZE  ]: size,
                }).then((response)=>{
                    setCheckedRow(createBoolArrayWithFixedLength(response.data.content.length))
                    SetUsersDesktopConfigurationsFromAPI(response.data.content)
                    SetUsersPagination({
                        number       : response.data.number,
                        size         : response.data.size,
                        totalElements: response.data.totalElements
                    })
                }).catch((error)=>{
                    SetError({
                        status  : error.response.data.status ? String(error.response.data.status) : '',
                        path    : error.response.data.path   ? String(error.response.data.path)   : '',
                        message : error.response.data.message ? String(error.response.data.message) : 'CANT GET USER CONFIG SORTED BY LOGIN'
                    })
                    SetErrorNeedToShow(true)
                    console.log('CANT GET USER CONFIG SORTED BY LOGIN', JSON.stringify(error))
                })
            }

            if(sortingField === enumSortingField.UPDATED_AT){
                getListOfUserConfigSortByUpdatedAtV2({
                    [enumApiParameter.APPLICATION]: selectedApplicationVersion ? selectedApplicationVersion.name : '',
                    [enumApiParameter.VERSION    ]: selectedApplicationVersion ? selectedApplicationVersion.version : '',
                    [enumApiParameter.DIRECTION  ]: sortingDirection,
                    [enumApiParameter.PAGE_NUMBER]: number,
                    [enumApiParameter.PAGE_SIZE  ]: size,
                    [enumApiParameter.PERIOD_FROM]: periodFrom ? periodFrom : undefined,
                    [enumApiParameter.PERIOD_TO  ]: periodTo ? periodTo : undefined,
                }).then((response)=>{
                    setCheckedRow(createBoolArrayWithFixedLength(response.data.content.length))
                    SetUsersDesktopConfigurationsFromAPI(response.data.content)
                    SetUsersPagination({
                        number       : response.data.number,
                        size         : response.data.size,
                        totalElements: response.data.totalElements
                    })
                }).catch((error)=>{
                    SetError({
                        status  : error.response.data.status ? String(error.response.data.status) : '',
                        path    : error.response.data.path   ? String(error.response.data.path)   : '',
                        message : error.response.data.message ? String(error.response.data.message) : 'CANT GET USER CONFIG SORTED BY UPDATED AT'
                    })
                    SetErrorNeedToShow(true)
                    console.log('CANT GET USER CONFIG SORTED BY UPDATED AT', JSON.stringify(error))
                })
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[needUpdateFromAPI])

    useEffect(()=>{
        setCheckedRow(createBoolArrayWithFixedLength(configs.length))
    },[configs])

    return(
        <TableContainer component={Paper} >
            
            <MultiplySelectionTools
                checkedRow = { checkedRow       }
                onDownload = { onDownloadConfig }
                onUpdateUsers   = { onUserConfigUpdate }
                data       = { listData }
                onDelete   = { onConfigDelete }
            />
            
            <Table 
                sx={{ minWidth: 700 }} 
                aria-label="customized table"
            >
                
                <ConfigTableHeader
                    headerDescription    = { userTableHeaderDescription       }
                    sorting={{
                        sortingField,
                        sortingDirection
                    }}
                    SetSortingDirection  = { SetUsersSortingDirection         }
                    SetNeedUpdateFromAPI = { SetNeedUpdateUsersConfigsFromAPI }
                    isCheckable          = { true }
                />

                <ConfigTableBody
                    headerDescription    = { userTableHeaderDescription }
                    data        = { configs }
                    exclude     = {['application']}
                    isCheckable = { true }
                    checkedRow  = { checkedRow }
                    onRowCheck  = { onCheckRow }
                />
                
            </Table>
        </TableContainer>
    )
}

export default UserConfigsTable