import { AxiosResponse } from "axios"
import API from './api-base'
import { TApiGetGroupsByName         } from "../../Types/Api/ApiGroupsRequest"
import { TApiGetGroupsByNameResponce } from "../../Types/Api/ApiGroupsResponse"

// https://adalisk.stoplight.io/docs/tp-api/b3A6MzgxMTk2MjE-list-of-groups

const getGroupsByName = async ( params: TApiGetGroupsByName ): Promise<AxiosResponse<TApiGetGroupsByNameResponce>>  =>{
    return await API.get(
        
        '/groups/by-name',
        
        { 
            params 
        }
    )
}

export default getGroupsByName
