import { AxiosResponse } from "axios"
import { TApiDeleteApplicationConfig } from '../../../Types/Api/ApiDesktopConfigRequest'
import { enumApiParameter }            from "../../../Types/General"
import API from '../api-base'


// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=DesktopConfiguration%20service%20v2#/Application%20configuration%20API/delete_2

const deleteApplicationConfig = async ( params: TApiDeleteApplicationConfig ): Promise<AxiosResponse>  =>{
    return await API.delete(
        
        `/v2/configurations/applications/${ String(params[ enumApiParameter.APPLICATION ]).toLowerCase() }/${params[ enumApiParameter.VERSION ]}`
    )
}

export default deleteApplicationConfig
