import { AxiosResponse } from "axios"
import { TApiGetWorkflowRulesListSortedByOrder } from "../../Types/Api/ApiWorkflowRequest"
import { TApiGetWorkflowRulesListSortedByOrderResponse } from "../../Types/Api/ApiWorkflowResponse"
import API from './api-base'

// https://tp-workflow-api.tp-qa.smiledirect.services/swagger-ui/index.html#/Rules%20API/list

const getRulesListSortedByOrder = async ( requestParams: TApiGetWorkflowRulesListSortedByOrder ): Promise<AxiosResponse<TApiGetWorkflowRulesListSortedByOrderResponse>>  =>{
    return await API.get(
        
        `/rules`,
        { 
            params:{ ...requestParams} 
        }
    
    )
}

export default getRulesListSortedByOrder
