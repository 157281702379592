import { AxiosResponse } from "axios"
import API from './api-base'
import { TApiGetMtpCasesList      } from "../../Types/Api/ApiCaseManagementRequest"
import { TApiGetMtpCasesListResponse } from "../../Types/Api/ApiMtpResponse"

// https://adalisk.stoplight.io/docs/tp-api/b3A6MzYwMDY5Nzc-get-cases-by-case-id

const getMtpCasesList = async ( requestParams: TApiGetMtpCasesList ): Promise<AxiosResponse<TApiGetMtpCasesListResponse>>  =>{
    
    const params:TApiGetMtpCasesList = { ...requestParams}
    
    if(params["period.from"] === '') delete params["period.from"]
    if(params["period.to"  ] === '') delete params["period.to"  ]

    return await API.get(
        
        `/mtps`,
        {
            params:{
                ...params
            }
        }
    )
}

export default getMtpCasesList