import { AxiosResponse } from "axios"
import { TCaseStatusTransition } from "../../../Types/Api/ApiCaseManagementResponse"
import API from '../api-base'


// https://adalisk.stoplight.io/docs/tp-api/branches/main/9340e34b69441-create-status-transition

const deleteStatusTransition = async ( status: string  ): Promise<AxiosResponse<TCaseStatusTransition>>  =>{
    return await API.delete(
        `/v2/case-status-transitions/${ status }`
    )
}

export default deleteStatusTransition
