import { TCase } from "../../../../Types/Store/StoreCaseManagement"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from "@mui/material"
import deleteCaseLock from "../../../../Api/CaseManagement/v2/delete-unlock-case"
import { useAction } from "../../../../Store/Hook/use-action"
import postLockCase from "../../../../Api/CaseManagement/v2/post-lock-case"
import CircularProgress from '@mui/material/CircularProgress'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import  { useState, useEffect } from 'react'

type TCaseLock = {
    caseDescription: TCase
    setNeedUpdate: ()=> void
    isNeedUpdate : boolean
}

const CaseLock = (props: TCaseLock) => {

    const { caseDescription, setNeedUpdate, isNeedUpdate } = props
    const { locked, id } = caseDescription
    const { setCaseManagementError, setCaseManagementErrorNeedToShow } = useAction()
    const [open, setOpen] = useState(false)
    const [isNeedAPIRequest, setNeedAPIRequest] = useState(false)
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = (e:any) => {
        e.stopPropagation()
        e.preventDefault()
        setOpen(false)
    }

    const handleApply = (e:any) =>{
        e.stopPropagation()
        e.preventDefault()
        setOpen(false)
        setNeedAPIRequest(true)
    }
    const performApiRequest = () =>{
        if(locked === true){
            deleteCaseLock(id)
            .then(()=>{
                setNeedUpdate()
            })
            .catch((error)=>{
                console.log('CANT DELETE CASE LOCK',JSON.stringify(error))
                setCaseManagementError({
                    message: error.response.data.message,
                    path: error.response.data.path,
                    status: error.response.data.status
                })
                setCaseManagementErrorNeedToShow(true)
            })
        }else{
            postLockCase(id)
            .then(()=>{
                setNeedUpdate()
            })
            .catch((error)=>{
                console.log('CANT DELETE CASE LOCK',JSON.stringify(error))
                setCaseManagementError({
                    message: error.response.data.message,
                    path: error.response.data.path,
                    status: error.response.data.status
                })
                setCaseManagementErrorNeedToShow(true)
            })
        }
    }
    
    
    useEffect(()=>{
        if(isNeedAPIRequest === true){
            setNeedAPIRequest(false)
            performApiRequest()
        }
    },[isNeedAPIRequest])


    return(
        
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    fontSize: '12px',
                    margin: '0 10px 0 10px'
                }}
                
                onClick = { handleClickOpen }
            >
                { 
                    isNeedUpdate === true ? 
                        <CircularProgress size={20}/>
                    :
                        <>
                            {
                                locked === true ?
                                <Tooltip title={ 'Unlock Case' }>
                                    <LockIcon sx={{ fill: '#f00' }} /> 
                                </Tooltip>
                                :
                                <Tooltip title={ 'Lock Case' }>
                                    <LockOpenIcon sx={{ fill: '#0f0' }} />
                                </Tooltip>
                            }
                        </>
                }
                
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Warning</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to <strong>{locked===true ? 'unlock' : 'lock'}</strong> the case?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        
                        <Button onClick={handleClose}>
                            Cancel
                        </Button>
                        
                        <Button variant="contained" onClick={handleApply}>
                            Confirm
                        </Button>

                    </DialogActions>
                </Dialog>
            </Box>
    )
}

export default CaseLock
