import API from './api-base'
import { AxiosResponse } from "axios"
import { TWorkflowRuleAction } from "../../Types/Store/StoreWorkflow"

// https://tp-workflow-api.tp-qa.smiledirect.services/swagger-ui/index.html#/Actions%20API/list_1

const getListActions = async (  ): Promise<AxiosResponse<TWorkflowRuleAction[]>>  =>{
    return await API.get(
        `/actions`
    )
}

export default getListActions
