import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiPostCreateStatusTransition   } from "../../../Types/Api/ApiCaseManagementRequest"
import { TCaseStatusTransition } from "../../../Types/Api/ApiCaseManagementResponse"

// https://adalisk.stoplight.io/docs/tp-api/branches/main/9340e34b69441-create-status-transition

const postCreateStatusTransition = async ( params: TApiPostCreateStatusTransition ): Promise<AxiosResponse<TCaseStatusTransition>>  =>{
    return await API.post(
    
        `/v2/case-status-transitions`,
    
        { 
            ...params
        }
    )
}

export default postCreateStatusTransition
