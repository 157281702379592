import { useState, useEffect } from 'react'
import { Paper, Table, TableContainer } from "@mui/material"
import { useAction } from "../../../../Store/Hook/use-action"
import { useTypedSelector } from "../../../../Store/Hook/use-typed-selector"
import { enumSortingField } from "../../../../Types/General"
import ConfigTableHeader from "./ConfigTableHeader"
import createBoolArrayWithFixedLength from "./createBoolArrayWithFixedLength"
import { userGroupsTableHeaderDescription } from "./tableHeaderDescription"
import ConfigTableBody from './ConfigTableBody'
import MultiplySelectionTools from '../ToolBar/MultiplySelectionTools'
import onDownloadConfig from './onDownloadConfig'
import { TApiPostCreateUpdateGroupConfig } from '../../../../Types/Api/ApiDesktopConfigRequest'
import { enumConfigType } from '../Dialogs/CreateConfig'
import deleteGroupConfig from '../../../../Api/DesktopConfiguration/v2/delete-group-config'
import putUpdateGroupConfig from '../../../../Api/DesktopConfiguration/v2/put-update-group-config'

const GroupConfigsTable = () =>{

    const { 
        configs,
        selectedApplicationVersion,
        sorting,
    } = useTypedSelector(store => store.desktopConfigurations.groups)

    const { 
        SetNeedUpdateGroupConfigsFromAPI,
        SetGroupsSortingDirection,
        SetError,
        SetErrorNeedToShow
    } = useAction()
    
    const [ checkedRow, setCheckedRow ] = useState<boolean[]> (createBoolArrayWithFixedLength(configs.length))
    
    const onCheckRow = (checkedRowIndex: number) =>{

        const result = []
        for(let i = 0; i < checkedRow.length; i++){
            if(i === checkedRowIndex){
                result.push(!checkedRow[i])
            }else{
                result.push(checkedRow[i])
            }
        }

        setCheckedRow(result)
    }

    const onGroupConfigUpdate = (params: TApiPostCreateUpdateGroupConfig) =>{
        
        putUpdateGroupConfig({
            ...params
        }).then((response)=>{
            SetNeedUpdateGroupConfigsFromAPI(true)
            setCheckedRow(createBoolArrayWithFixedLength(configs.length))
        }).catch((error)=>{
            SetError({
                status  : error.response.data.status ? String(error.response.data.status) : '',
                path    : error.response.data.path   ? String(error.response.data.path)   : '',
                message : error.response.data.message ? String(error.response.data.message) : 'ANT CREATE/UPDATE GROUP CONFIG'
            })
            SetErrorNeedToShow(true)
            console.log("CANT CREATE/UPDATE APPLICATION CONFIG", JSON.stringify(error))
        })
    }


    const onGroupConfigDelete = ( checkedRow: boolean[]) =>{
        
        if(checkedRow){
            const checkedIndex: number[] = []
            
            for(let i = 0; i<checkedRow.length; i++){
                if(checkedRow[i] === true){
                    checkedIndex.push(i)
                }
            }

            for(let i = 0; i<checkedIndex.length; i++){

                deleteGroupConfig({
                    application : selectedApplicationVersion ? selectedApplicationVersion.name : '',
                    version     : selectedApplicationVersion ? selectedApplicationVersion.version : '',
                    groupId     : configs[checkedIndex[i]].groupId
                }).then((response)=>{
                    SetNeedUpdateGroupConfigsFromAPI(true)
                }).catch((error)=>{
                    SetError({
                        status  : error.response.data.status ? String(error.response.data.status) : '',
                        path    : error.response.data.path   ? String(error.response.data.path)   : '',
                        message : error.response.data.message ? String(error.response.data.message) : 'CANT DELETE GROUP CONFIG'
                    })
                    SetErrorNeedToShow(true)
                    console.log("CANT CREATE/UPDATE GROUP CONFIG", JSON.stringify(error))
                })
            }

            setCheckedRow(createBoolArrayWithFixedLength(configs.length))
        }
    }

    useEffect(()=>{
        setCheckedRow(createBoolArrayWithFixedLength(configs.length))
    },[configs])

    return(
        <TableContainer component={Paper} >

            <MultiplySelectionTools
                checkedRow      = { checkedRow                }
                onDownload      = { onDownloadConfig          }
                onUpdateGroups  = { onGroupConfigUpdate       }
                data            = {{
                    config: configs,
                    configsFor: enumConfigType.GROUP,
                    headerDescription: userGroupsTableHeaderDescription
                }}
                onDelete        = { onGroupConfigDelete       }
            />

            <Table 
                sx={{ minWidth: 700 }} 
            >
                <ConfigTableHeader
                    headerDescription    = { userGroupsTableHeaderDescription       }
                    sorting={{
                        sortingField: enumSortingField.UPDATED_AT,
                        sortingDirection: sorting
                    }}
                    SetSortingDirection  = { SetGroupsSortingDirection        }
                    SetNeedUpdateFromAPI = { SetNeedUpdateGroupConfigsFromAPI }
                    isCheckable          = { true }
                />

                <ConfigTableBody
                    headerDescription    = { userGroupsTableHeaderDescription }
                    data        = { configs       }
                    isCheckable = { true          }
                    exclude     = {['application']}
                    checkedRow  = { checkedRow    }
                    onRowCheck  = { onCheckRow    }
                />
            </Table>
        </TableContainer>
    )
}

export default GroupConfigsTable 
