import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiPostCreateUpdateGroupConfig } from "../../../Types/Api/ApiDesktopConfigRequest"
import { enumApiParameter } from "../../../Types/General"

// https://desktop-configuration-api.tp-dev-stable.smiledirect.services/swagger-ui/index.html?urls.primaryName=Version%202#/Group%20configuration%20API/create_1


const postCreateGroupConfig = async ( params: TApiPostCreateUpdateGroupConfig ): Promise<AxiosResponse>  =>{
    return await API.post(

        `/v2/configurations/groups/${String(params[enumApiParameter.APPLICATION])}/${params[enumApiParameter.VERSION]}/${params[enumApiParameter.GROUP_ID]}`,
        
        params.data
        
    )
}

export default postCreateGroupConfig
