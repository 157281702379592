import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"
import { useAction } from "../../../../Store/Hook/use-action"
import DateTimePicker from "../../Forms/DateTimePicker"
import AccountCircle        from '@mui/icons-material/AccountCircle'
import CancelIcon           from '@mui/icons-material/Cancel'
import { useTypedSelector } from "../../../../Store/Hook/use-typed-selector"
import { enumSortingField } from '../../../../Types/General'


const UserListFilters = () => {

    const {
        SetNeedUpdateUsersConfigsFromAPI,
        SetUsersLoginFilter,
        SetUsersSortingDirection,
        SetUsersPeriod,
        SetUsersSelectedApplicationVersion,
    } = useAction()

    const { 
        applicationsDescription
    } = useTypedSelector(store => store.desktopConfigurations)

    const {
        sortingField,
        sortingDirection,
        loginFilter,
        periodFrom,
        selectedApplicationVersion
    } = useTypedSelector(store => store.desktopConfigurations.users)


    const handleChangeApplication = (event: SelectChangeEvent) => {
        const selectedValue = event.target.value.split('|')
        SetUsersSelectedApplicationVersion({
            name: selectedValue[0],
            version: selectedValue[1]
        })
        SetNeedUpdateUsersConfigsFromAPI(true)
    }

    return(
        <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '18px 0 10px 0' }}>
            <FormControl>
                <InputLabel id="software-selector-label">Software</InputLabel>
                <Select
                    labelId="software-selector-label"
                    id="software-selector"
                    value={ typeof selectedApplicationVersion!=='undefined' ? `${selectedApplicationVersion.name}|${selectedApplicationVersion.version}` : ''}
                    label="Software"
                    onChange={ handleChangeApplication }
                    sx={{
                        margin: '0 10px 0 0', 
                        padding: 0,
                        width: '150px',
                    }}
                >
                    {
                        applicationsDescription.map((applicationItem, applicationItemindex)=>{
                            return(
                                
                                applicationItem.versions.map((versionItem, versionItemIndex)=>{
                                    return(
                                        <MenuItem 
                                            value={ `${applicationItem.name}|${versionItem}` }
                                            key = {`version-${applicationItemindex}-${versionItemIndex}`}
                                        >
                                            { `${applicationItem.name} ${versionItem}` }
                                        </MenuItem>
                                    )
                                })
                                
                            )
                        })
                    }
                </Select>
            </FormControl>

            {
                selectedApplicationVersion && selectedApplicationVersion.name ?
                    <div style = {{
                            display: 'flex'
                        }}>
                            <TextField
                                autoFocus
                                disabled={ periodFrom!==null? true : false }
                                id="filterByLogin"
                                label= { 'Filter By Login' }
                                type="text"
                                variant="outlined"
                                value={ loginFilter }

                                sx={{ marginRight: loginFilter!=='' ? undefined : '60px'}}

                                onChange={(e)=>{ 
                                    SetUsersLoginFilter(String(e.target.value))
                                    SetUsersSortingDirection({
                                        sortingField: enumSortingField.LOGIN,
                                        sortingDirection: sortingDirection
                                    })
                                    SetNeedUpdateUsersConfigsFromAPI(true)
                                }}

                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            {
                                loginFilter!=='' ?
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>{
                                            SetUsersLoginFilter('')
                                            SetNeedUpdateUsersConfigsFromAPI(true)
                                        }}
                                        sx={{ marginRight: loginFilter!=='' ? '20px' : undefined }}
                                    >
                                        <CancelIcon/>
                                </IconButton> 
                                : null
                            }
                            

                            <DateTimePicker
                                disabled={ loginFilter!==''? true : false }
                                from={ periodFrom }
                                setFrom = { (localPeriodFrom,localPeriodTo)=>{
                                    
                                    SetUsersPeriod({
                                        periodFrom: localPeriodFrom,
                                        periodTo: localPeriodTo
                                    })
                                    
                                    if(sortingField !== enumSortingField.UPDATED_AT){
                                        SetUsersSortingDirection({
                                            sortingField: enumSortingField.UPDATED_AT,
                                            sortingDirection
                                        })
                                    }
                                    SetNeedUpdateUsersConfigsFromAPI(true)
                                } }
                            />
                    </div>
                : null
            }
        </Box>
    )
}

export default UserListFilters
