import { AxiosResponse } from "axios"
import API from './api-base'
import { TApiPutCreateGroup } from "../../Types/Api/ApiGroupsRequest"


// https://adalisk.stoplight.io/docs/tp-api/b3A6MzgzNDUyMTc-create-group

const putCreateGroup = async ( params: TApiPutCreateGroup ): Promise<AxiosResponse>  =>{
    return await API.put(
        `/groups`,
        { 
            ...params 
        }
    )
}

export default putCreateGroup
