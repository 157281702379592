import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiGetAvalableApplicationsAndVersions } from "../../../Types/Api/ApiDesktopConfigResponse"


// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=DesktopConfiguration%20service%20v2#/Application%20configuration%20API/getAll

const getAvalableApplicationsAndVersions = async ( ): Promise<AxiosResponse<TApiGetAvalableApplicationsAndVersions>>  =>{

    return await API.get(
        `/v2/configurations/applications`
    )
}

export default getAvalableApplicationsAndVersions
