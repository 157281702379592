import { TWorkflowRuleAction } from "../../../../Types/Store/StoreWorkflow"
import { checkArrayValueRequired, checkValueRequired, checkValueRequiredEmailList } from "../../../formValidator"
import { TAttributeCheckResult, TErrorDescription } from "../ActionEditor"


const checkFormState = (actionToCheck: TWorkflowRuleAction, actionTemplates: TWorkflowRuleAction[] = []) => {
    
    const formCheckResults: TAttributeCheckResult[] = []
    const errorObject:any = {}

    let currentTemplateIndex = -1
    
    for(let i = 0; i<actionTemplates.length; i++){
        if(actionTemplates[i].type === actionToCheck.type){
            currentTemplateIndex = i
            break
        }
    }
 
    if(currentTemplateIndex >-1){

        const currentTemplate = actionTemplates[currentTemplateIndex]
        const templateAttributeEntries = Object.entries(currentTemplate.attributes)
        
        const arubutesToCheck = Object.entries(actionToCheck.attributes)

        for(let i = 0; i<templateAttributeEntries.length; i++){
            const attributeName = templateAttributeEntries[i][0]
            
            const foundedAttrubute = arubutesToCheck.filter((item)=>{
                return(
                    item[0] === attributeName
                )
            })
            
            errorObject[attributeName] = foundedAttrubute.length > 0 ? 
                {
                    value: foundedAttrubute[0][1],
                    requirement: templateAttributeEntries[i][1]
                } 
            : 
                {
                    value: '',
                    requirement: templateAttributeEntries[i][1]
                }
        }

        const errorEntries = Object.entries(errorObject) as TErrorDescription[]
        
        for(let i = 0; i< errorEntries.length; i++){    
            let checkResult
            if(errorEntries[i][1].requirement.required === true && errorEntries[i][1].requirement.type === "string"){
                if(Array.isArray( errorEntries[i][1].value ) === false){
                    checkResult = checkValueRequired( (errorEntries[i][1].value as unknown) as string )
                }
            }

            if(errorEntries[i][1].requirement.required === true && errorEntries[i][1].requirement.type === "array"){
                if(Array.isArray( errorEntries[i][1].value ) === true){
                    
                    if(errorEntries[i][1].requirement.items && errorEntries[i][1].requirement.items?.type === 'string'){
                        checkResult = checkArrayValueRequired (errorEntries[i][1].value as string[])
                    }else{
                        checkResult = checkValueRequiredEmailList( ((errorEntries[i][1].value as unknown) as string[]).join(', ') )
                    }

                }else{
                    if(errorEntries[i][1].value === '' || errorEntries[i][1].value === undefined){
                        checkResult= {
                            isValid : false,
                            error   : 'Empty value',
                        }
                    }else{
                        checkResult = checkValueRequiredEmailList( (errorEntries[i][1].value as unknown) as string )
                    }
                }
            }
            
            if(checkResult){
                if(checkResult.isValid === false){
                    formCheckResults.push({
                        attribute:`${errorEntries[i][0]}`,
                        ...checkResult
                    })
                }
            }
        }

    }

    return formCheckResults
}

export default checkFormState
