import { FunctionComponent, useState, useEffect } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import Layout from './Components/Layout'

import Groups               from './Pages/Groups'
import GroupItem            from './Pages/Groups/GroupItem'
import DesktopConfigurations from './Pages/DesktopConfigurations'

import { OktaAuth } from '@okta/okta-auth-js'
import { oktaAuthConfig } from './Security/okta-config'
import Error from './Pages/Error'
import { useAction } from './Store/Hook/use-action'
import getUserInfo from './Security/check-user'
import checkLocalToken from './Security/check-local-token'
import getTokensWithRedirect from './Security/get-tokens-with-redirect'
import Case from './Pages/CaseManagement/Case'
import WorkflowRules from './Pages/Workflow/WorkflowRules'
import Transitions from './Pages/CaseManagement/Transitions/Transitions'
import UserHistory from './Pages/CaseManagement/UserHistory'
import MTPCase from './Pages/MobileTreatmentPlan/MTPCase'
import CaseManagement from './Pages/CaseManagement'
import MTPCaseList from './Pages/MobileTreatmentPlan/MTPCaseList'
import getSavedUrlFromLocalStorage from './Utils/get-saved-url-from-local-storage'
import saveUrlToLocalStorage from './Utils/save-url-to-local-storage'
import clearLocalStorage from './Security/clear-local-storage'

export const authClient = new OktaAuth(oktaAuthConfig)
authClient.start()

const  App:FunctionComponent = () => {


    const [               , setIsAuthenticated ] = useState( false )
    const [ errorDetected,  setErrorDetected   ] = useState( false )
    const [ errorCode,      setErrorCode       ] = useState( ''    )
    const [ errorSummary,   setErrorSummary    ] = useState( ''    )
    const { setBaseUserInfoFromAPI } = useAction()

    useEffect(()=>{

        if (authClient.isLoginRedirect()) {
            // Parse token from redirect url
            authClient.token.parseFromUrl()
            .then(data => {
                
                const { accessToken } = data.tokens
                // Store parsed token in Token Manager
                if(accessToken){
                    authClient.tokenManager.add('accessToken', accessToken)
                }

                const { idToken } = data.tokens
                // Store parsed token in Token Manager
                if(idToken){
                    authClient.tokenManager.add('idToken', idToken)
                    setBaseUserInfoFromAPI(idToken.claims.email ? idToken.claims.email : '')
                }

            }).catch((error)=>{
                console.log('PARSE_FROM_URL ERROR', JSON.stringify(error) )
                
                setErrorCode(error.errorCode)
                setErrorSummary(error.errorSummary)
            })

        } else {
            // Attempt to retrieve ID Token from Token Manager
            authClient.tokenManager.get('accessToken')
            .then(accessToken => {
                if (!accessToken) {
                    // You're not logged in, you need a sessionToken
                    authClient.token.getWithRedirect({
                        responseType: ['code','id_token'],
                    }).catch((error)=>{
                        console.log('GET TOKEN WITH REDIRECT ERROR', JSON.stringify(error))
                        setErrorCode(error.errorCode)
                        setErrorSummary(error.errorSummary)
                    })
                }
            })
            .catch(e=>{
                console.log('Access token was not retreived', e)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        
        if(errorCode!==''){
            checkLocalToken(setIsAuthenticated)
        }
        // Check access token on start
        authClient.tokenManager.getTokens()
        .then(({ accessToken }) => {
            const isAccessExpired = authClient.tokenManager.hasExpired(accessToken)

            // update access and id token
            if(isAccessExpired === true){

                console.log('ACCESS TOKEN EXPIRED, logout for better times')
                clearLocalStorage()
                window.location.reload()

            }else{
                getUserInfo(
                    setBaseUserInfoFromAPI,
                    setErrorCode,
                    setErrorSummary
                )
            }

            // handle accessToken and idToken
        })
        .catch((e)=>{
            console.log('GET LOCAL TOKENS ERROR', JSON.stringify(e))
            getTokensWithRedirect(
                authClient,
                setErrorCode,
                setErrorSummary
            )
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]) 

    const history = useNavigate()
    const location = useLocation()

    useEffect(()=>{
        if(location.pathname === '/logincallback' && errorCode===''){
            if(errorDetected === false){
                const savedUrl = getSavedUrlFromLocalStorage()
                history( savedUrl ? savedUrl : '/')
            }
        }else{
            if(errorDetected === false && errorCode!==''){
                history('/error')
                setErrorDetected(true)
            }else{
                saveUrlToLocalStorage( `${location.pathname}${location.search}`)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location, errorDetected, errorCode]) 
    
    return (

        <Routes>
            <Route path="/" element={
                <Layout/>
            }>

                <Route index element={
                    <CaseManagement/>
                }/>

                <Route path="desktop-configuration" element={
                    <DesktopConfigurations/>
                }/>

                <Route path="groups" element={
                    <Groups/>
                }/>


                <Route path="case-management/case/:caseId" element={
                    <Case/>
                }/>

                    <Route path="case-management/case/:caseId" element={
                        <Case/>
                    }/>

                    <Route path="case-management/case/:caseId/files" element={
                        <Case activeTab='FILES'/>
                    }/>

                    <Route path="case-management/case/:caseId/history" element={
                        <Case activeTab='HISTORY'/>
                    }/>
                    <Route path="case-management/case/:caseId/inspector" element={
                        <Case activeTab='SMILE_INSPECTOR'/>
                    }/>
                    <Route path="case-management/user-history" element={
                        <UserHistory />
                    }/>
                <Route path="case-management/case-status-transitions" element={
                    <Transitions/>
                }/>
                <Route path="groups/:uuid" element={
                    <GroupItem/>
                }/>

                <Route path="case-management/workflow-rules/" element={
                    <WorkflowRules />
                }/>
                
                <Route path="mobile-treatment-plan" element={
                    <MTPCaseList/>
                }/>

                    <Route path="mobile-treatment-plan/:caseId" element={
                        <MTPCase/>
                    }/>

                <Route path="*" element = {
                    <div> 404 , PATH { location.pathname } </div>
                }/>

            </Route>

            <Route path="/error" element={
                <Error errorCode={ errorCode } errorSummary={errorSummary}/>
            }/>
            
        </Routes>

    )
}

export default App
