import { enumSortingField } from "../../../Types/General"


export type TCaseManagementTableHeaderId = 'id' | 'externalId' | 'source' | 'status'  | 'createdBy' | 'medicalRecordNumber' | 'version' |  'createdAt' | 'updatedAt' | 'locked' | 'inspector'
export type TCaseManagementTableHederDescriptor = {

    id        : TCaseManagementTableHeaderId
    label     : string
    numeric   : boolean
    align?    : 'left' | 'right' | 'center' | 'justify'
    isSortable: boolean
    sortingFieldId: enumSortingField
}

export const tableHeaderDescription:TCaseManagementTableHederDescriptor[] = [
    {
        id: 'locked',
        label: 'Locked',
        align: 'center',
        numeric: false,
        isSortable: false,
        sortingFieldId: enumSortingField.LOCKED,
    },
    {
        id: 'inspector',
        label: 'SI',
        align: 'center',
        numeric: false,
        isSortable: false,
        sortingFieldId: enumSortingField.INSPECTOR,
    },
    {
        id        : 'externalId',
        label     : 'External Id',
        numeric   : false,
        align     : 'left',
        isSortable: false, // disabled until enpoint will be developed
        sortingFieldId: enumSortingField.EXTERNAL_ID,
    },
    {
        id        : 'source',
        label     : 'Source',
        numeric   : false,
        align     : 'left',
        isSortable: false,
        sortingFieldId: enumSortingField.SOURCE,
    },
    {
        id: 'createdBy',
        label: 'Created By',
        align: 'left',
        numeric: false,
        isSortable: true,
        sortingFieldId: enumSortingField.LOGIN,
    },
    {
        id: 'createdAt',
        label: 'Created Date',
        align: 'left',
        numeric: false,
        isSortable: true,
        sortingFieldId: enumSortingField.CREATED_AT,
    },
    {
        id: 'updatedAt',
        label: 'Last Updated',
        align: 'left',
        numeric: false,
        isSortable: true,
        sortingFieldId: enumSortingField.UPDATED_AT,
    },
    {
        id         : 'status',
        label      : 'Status',
        numeric    : false,
        align      : 'left',
        isSortable : false,
        sortingFieldId: enumSortingField.STATUS,
    },
    {
        id         : 'id',
        label      : 'CMS ID',
        numeric    : false,
        align      : 'left',
        isSortable : false,
        sortingFieldId: enumSortingField.ID,
    },
]
