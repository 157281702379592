import { FormControl, FormHelperText } from "@mui/material"
import useValidation, { enumFormValidationRule } from "../../formValidator"
import TextField from '@mui/material/TextField'
import { Dispatch, SetStateAction, useState } from "react"

export type TActionEditorFormStringProps = {
    valueName: string
    startValue?: string
    type : string
    required : boolean
    emailList?: boolean
    description : string
    items?: {type:string} 
    multiline?: boolean
    index? : boolean
    sendValue?: Dispatch<SetStateAction<Object>>
    isValueWithError?:boolean
}

const ActionEditorFormString = (props: TActionEditorFormStringProps) =>{

    const { required, emailList, description, multiline, index, valueName, startValue, sendValue, items, isValueWithError, type } = props

    const [ isNeedToShow, setNeedToShow ] = useState(isValueWithError)

    const validationRules:enumFormValidationRule[] = []
    if( required === true  ) { validationRules.push(enumFormValidationRule.REQUIRED) }
    if( emailList === true ) { validationRules.push(enumFormValidationRule.EMAIL_LIST) }
    console.log(type)
    const [
        formString,
        isFormStringValid,
        fromStringValidationError,
        onFormStringChange
    ] = useValidation(validationRules, startValue)
    

    
    let value = ''
    if(Array.isArray(formString)){
        if(formString.length === 1){
            value = formString[0]
        }
        if(formString.length > 0){
            value = formString.join(', ')
        }
    }else{
        value = formString
    }

    

    return(
        <FormControl fullWidth error={ !isFormStringValid }>
            <TextField
                autoFocus = {index !== undefined && index === true }
                margin="dense"
                label={ description }
                multiline = { multiline }
                fullWidth
                variant="standard"
                value={ value }
                error={ ( isValueWithError && isNeedToShow ) || !isFormStringValid  }
                onChange={(e)=>{
                    onFormStringChange(e.target.value)

                    if(isNeedToShow === true){
                        setNeedToShow(false)
                    }

                    if(sendValue){
                        
                        sendValue((prevState)=>{
                            
                            let targetValue
                            // items comes from attribute description and mean "THIS IS ARRAY"
                            if(items){
                                if(items.type === 'string'){
                                    if(String(e.target.value).indexOf('\n')!==-1){
                                        targetValue =  String(e.target.value).split('\n').map(item=>item.trim())
                                    }
                                    if(String(e.target.value).indexOf(',')!==-1){
                                        targetValue =  String(e.target.value).split(',').map(item=>item.trim())
                                    }

                                    if(targetValue === undefined){
                                        targetValue = [e.target.value]
                                    }
                                }
                            }else{
                                targetValue = e.target.value
                            }

                            return({
                                ...prevState,
                                [valueName]: targetValue 

                            })
                        })
                    }
                }}
            />
            <FormHelperText>
                { fromStringValidationError }
            </FormHelperText>
        </FormControl>
    )
}

export default ActionEditorFormString
