import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiPostCommentRequest} from "../../../Types/Api/ApiCaseManagementRequest"
import { TApiPostCommentResponse } from "../../../Types/Api/ApiCaseManagementResponse"

// https://case-management-api.tp-dev-stable.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Comment%20API/createComment

const postCreateCaseComment = async ( params: TApiPostCommentRequest ): Promise<AxiosResponse<TApiPostCommentResponse>>  =>{
    return await API.post(
        `/v2/cases/${params.caseId}/comments`,
        {
            content: params.content
        }
    )
}

export default postCreateCaseComment
