import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiGetCaseHistoryResponse } from "../../../Types/Api/ApiCaseManagementResponse"
import { enumApiParameter, enumSORT } from "../../../Types/General"

export type  TApiGetEventLogListByUser = {
    [enumApiParameter.USER         ]?: string
    [enumApiParameter.PERIOD_FROM  ]? : string
    [enumApiParameter.PERIOD_TO    ]? : string
    [enumApiParameter.DIRECTION    ]? : enumSORT
    [enumApiParameter.PAGE_NUMBER  ]? : number
    [enumApiParameter.PAGE_SIZE    ]? : number
}

const getEventLogsListByUser = async ( requestParam: TApiGetEventLogListByUser ): Promise<AxiosResponse<TApiGetCaseHistoryResponse>>  =>{
    
    const params = {...requestParam}
    delete params.user

    return await API.get(
        `/v2/users/${requestParam.user}/logs/by-period`,
        { 
            params 
        }
    )
}

export default getEventLogsListByUser
