import { Box } from "@mui/material"
import { useEffect } from "react"
import getGroupsByName from "../../../Api/UserGroups/get-groups-by-name"
import { useAction } from "../../../Store/Hook/use-action"
import { useTypedSelector } from "../../../Store/Hook/use-typed-selector"
import { enumApiParameter, enumSORT } from "../../../Types/General"

import { enumConfigType } from "./Dialogs/CreateConfig"
import Warning from "./Dialogs/Warning"
import GroupConfigsTable from "./Tables/GroupConfigsTable"
import GroupsListFilters from "./ToolBar/GroupsListFilters"
import UserListTools from "./ToolBar/ListTools"

const GroupConfigs = () => {
    
    const { 
        SetGroupsDesktopGroupsListFromAPI,
        SetError,
        SetErrorNeedToShow,
    } = useAction()

    const { selectedApplicationVersion } = useTypedSelector(state => state.desktopConfigurations.groups)

    useEffect(()=>{
        getGroupsByName({
            [enumApiParameter.DIRECTION]  : enumSORT.ASC,
            [enumApiParameter.PAGE_NUMBER]: 0,
            [enumApiParameter.PAGE_SIZE]  : 64535,
        }).then((groupListResponse)=>{
            SetGroupsDesktopGroupsListFromAPI(groupListResponse.data.content.map(item=>{
                return({
                    groupId: item.id,
                    groupName: item.name,
                })
            }))
        }).catch((error)=>{
            SetError({
                status  : error.response.data.status ? String(error.response.data.status) : '',
                path    : error.response.data.path   ? String(error.response.data.path)   : '',
                message : error.response.data.message ? String(error.response.data.message) : 'CANT GET WHOLE LIST OF GROUPS CONFIGS SORTED BY UPDATED AT'
            })
            SetErrorNeedToShow(true)
            console.log('CANT GET LIST OF GROUP CONFIGS SORTED BY UPDATED AT', JSON.stringify(error))
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <Box 
            sx={{
                margin: '0 0 0 10px',
            }}
        >
            <GroupsListFilters />
            {
                selectedApplicationVersion && selectedApplicationVersion.name ?
                    <>
                        <UserListTools configType={ enumConfigType.GROUP } />
                        <GroupConfigsTable/>
                    </>
                :
                    <Warning
                        isEnabled = { true }
                    />
            }
            
        </Box>
    )
}

export default GroupConfigs
