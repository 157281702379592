import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiGetCasesByFilter      } from "../../../Types/Api/ApiCaseManagementRequest"
import { TCase } from "../../../Types/Store/StoreCaseManagement"

const getCasesByFilter = async ( requestParams: TApiGetCasesByFilter ): Promise<AxiosResponse<TCase[]>>  =>{
    return await API.get(
        
        `/v2/cases`,
        { 
            params:{ ...requestParams} 
        }
    )
}

export default getCasesByFilter
