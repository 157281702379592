import { enumSortingField } from "../../Types/General";
import { enumWorkflowStoreActionType, TWorkflowStore, TWorkflowStoreAction } from "../../Types/Store/StoreWorkflow"

const initialState:TWorkflowStore = {
    needUpdateDataFromAPI: true,

    rulesList: [
        
    ],

    pagination:{
        totalElements: 0,
        size         : 100,
        number       : 0,
    },

    sorting: 'ASC',
    sortingField: enumSortingField.ORDER,

    error:{
        needToShow: false,
        status    : '',
        message   : '',
        path      : '',
    }
}

export const workflowReducer = ( 

    state = initialState,
    action : TWorkflowStoreAction

) :TWorkflowStore  => {

    switch(action.type){

        case  enumWorkflowStoreActionType.SET_WORKFLOW_RULES_LIST :            
            return ({
                ...state,
                rulesList: action.payload
            })

        case enumWorkflowStoreActionType.SET_WORKFLOW_RULES_NEED_UPDATE_FROM_API:
            return ({
                ...state,
                needUpdateDataFromAPI: action.payload
            })

        case enumWorkflowStoreActionType.SET_WORKFLOW_RULES_PAGINATION:
            return ({
                ...state,
                pagination: action.payload
            })

        case enumWorkflowStoreActionType.SET_WORKFLOW_RULES_ERROR:
            
            if(action.payload.needToShow === false){
                return ({
                    ...state,
                    error:{
                        ...state.error,
                        needToShow: false
                    }
                })
            }
            
            return ({
                ...state,
                error: {
                    ...action.payload
                }
            })
            

            
        
        default:
            return state
    }

}
