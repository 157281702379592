import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiGetCasesExportCSV } from "../../../Types/Api/ApiCaseManagementRequest"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Cases%20API/exportCsv

const getCasesExportCSV = async ( requestParams: TApiGetCasesExportCSV ): Promise<AxiosResponse<string>>  =>{

    return await API.get(
        `/v2/cases/export/csv`,
        { 
            params:{ ...requestParams} 
        }
    )
}

export default getCasesExportCSV
