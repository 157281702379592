import { Box } from "@mui/material"
import { Dispatch, ReactElement, SetStateAction } from "react"
import { TAttributeCheckResult } from "../ActionEditor"
import ActionEditorFormString from "../ActionEditorFormString"


const generateFormEntries = (
    atributes:Object, 
    setFormState: Dispatch<SetStateAction<Object>>,
    startValues?: {[key:string]:string},
    checkErrorsResult: TAttributeCheckResult[] = []
) => {

    const formEntries = Object.entries(atributes)
    const formElements: ReactElement[] = []

    for(let i = 0; i<formEntries.length; i++){

        const salt = Date.now().toString()
        const key = `formEntries${salt}-${ i }`
        const valueName = formEntries[i][0]
        let formattedStartValue
        
        switch(formEntries[i][0]){
            case 'recipients':
                {
                    if(startValues){
                        formattedStartValue = startValues[valueName]
                        if(Array.isArray(formattedStartValue) && formattedStartValue.length === 1){
                            if(formattedStartValue[0].split(',').length > 1){
                                formattedStartValue = formattedStartValue[0].split(',').join(', ')
                            }
                        }
                    }
                    
                    let checkErrors = checkErrorsResult.filter( (item)=> item.attribute === formEntries[i][0] ) 
                    formElements.push(
                        <ActionEditorFormString
                            valueName={formEntries[i][0]}
                            key={ key } 
                            {...formEntries[i][1]}  
                            index = { i === 0 }
                            sendValue={setFormState}
                            startValue={ formattedStartValue }
                            emailList = {true}
                            isValueWithError = {  checkErrors.length>0 ? !checkErrors[0].isValid : undefined }
                        />)
                    break
                }

            case 'from':
                {
                    //  DEPRICATED BUT MAY BE WILL BE USEFULL

                    // let checkErrors = checkErrorsResult.filter( (item)=> item.attribute === formEntries[i][0] ) 

                    // formElements.push(
                    //     <ActionEditorFormString
                    //         valueName={formEntries[i][0]}
                    //         key={ key } 
                    //         {...formEntries[i][1] } 
                    //         description={formEntries[i][0]}
                    //         sendValue={setFormState}
                    //         startValue={ startValues && startValues[valueName] ? startValues[valueName] : undefined}
                    //         isValueWithError = {  checkErrors.length>0 ? !checkErrors[0].isValid : undefined }
                    //     />
                    // )
                    break
                }

            case 'subjectTemplate':
                {
                    let checkErrors = checkErrorsResult.filter( (item)=> item.attribute === formEntries[i][0] )
                    formElements.push(
                        <ActionEditorFormString
                            valueName={formEntries[i][0]}
                            key={ key } 
                            {...formEntries[i][1]}
                            sendValue={setFormState}
                            startValue={ startValues && startValues[valueName] ? startValues[valueName] : undefined}
                            isValueWithError = {  checkErrors.length>0 ? !checkErrors[0].isValid : undefined }
                        />)
                    break
                }
            case 'status':
                {
                    let checkErrors = checkErrorsResult.filter( (item)=> item.attribute === formEntries[i][0] )
                    formElements.push(
                        <ActionEditorFormString
                            valueName={formEntries[i][0]}
                            key={ key } 
                            {...formEntries[i][1]}
                            sendValue={setFormState}
                            startValue={ startValues && startValues[valueName] ? startValues[valueName] : undefined}
                            isValueWithError = {  checkErrors.length>0 ? !checkErrors[0].isValid : undefined }
                        />)
                    break
                }
            
            case 'messageTemplate':
                {
                    let checkErrors = checkErrorsResult.filter( (item)=> item.attribute === formEntries[i][0] )
                    formElements.push(
                        <ActionEditorFormString 
                            valueName={formEntries[i][0]}
                            key={ key } 
                            {...formEntries[i][1]} 
                            multiline={true}
                            sendValue={setFormState}
                            startValue={ startValues && startValues[valueName] ? startValues[valueName] : undefined}
                            isValueWithError = {  checkErrors.length>0 ? !checkErrors[0].isValid : undefined }
                        />)
                    break
                }
            
            case 'tags':{
                let checkErrors = checkErrorsResult.filter( (item)=> item.attribute === formEntries[i][0] )
                    //console.log(formEntries)
                    formElements.push(
                        <ActionEditorFormString
                            valueName={formEntries[i][0]}
                            key={ key } 
                            {...formEntries[i][1]}
                            sendValue={setFormState}
                            startValue={ startValues && startValues[valueName] ? startValues[valueName] : undefined}
                            isValueWithError = {  checkErrors.length>0 ? !checkErrors[0].isValid : undefined }
                            emailList = {false}
                            required
                        />)
                    break
            }

            default:
                formElements.push(<Box key={ key }   >{ formEntries[i][0] } = {JSON.stringify(formEntries[i][1])} </Box>)
                break
        }
    }
    return formElements
}

export default generateFormEntries
