import { 
    mdiApplicationCogOutline, 
    mdiTooth, 
    mdiBriefcaseCheck,
    mdiTransitConnectionVariant,
    mdiAccountGroup,
    mdiAccountClock,
    mdiCardAccountDetails,
} from '@mdi/js'


export type TMenuItemDescripion = {
    name       : string
    moduleType : TTPModuleType
    icon       : string
    child      : TMenuItemDescripion[]
    linkTo     : string
}

export type TTPModuleType = 
    `CASE${
        'S' |
        '_STATUS' |
        '_WORKFLOW' |
        '_STATUS_TRANSITIONS'
    }` |
    `DESKTOP_CONFIGURATION` |
    'GROUPS' |
    'MTP'

export const menuConfig:TMenuItemDescripion[] = [
    {
        name: 'Case Management',
        moduleType: 'CASES',
        icon: mdiTooth,
        linkTo:  '/',
        child:[
            {
                name: 'Workflow Rules',
                moduleType: 'CASE_WORKFLOW',
                icon: mdiBriefcaseCheck,
                linkTo:  'case-management/workflow-rules',
                child:[]
            },
            {
                name: 'Status Transitions',
                moduleType: 'CASE_WORKFLOW',
                icon: mdiTransitConnectionVariant ,
                linkTo:  'case-management/case-status-transitions',
                child:[]
            },
            {
                name: 'User History',
                moduleType: 'CASE_WORKFLOW',
                icon: mdiAccountClock ,
                linkTo:  'case-management/user-history',
                child:[]
            },
        ]
    },
    {
        name: 'Mobile Treatment Plan',
        moduleType: 'MTP',
        icon: mdiCardAccountDetails,
        linkTo:  'mobile-treatment-plan',
        child:[]
    },
    {
        name: 'Desktop Configuration',
        moduleType: 'DESKTOP_CONFIGURATION',
        icon: mdiApplicationCogOutline,
        linkTo:  'desktop-configuration',
        child:[]
    },
    {
        name: 'Groups',
        moduleType: 'GROUPS',
        icon: mdiAccountGroup,
        linkTo:  'groups',
        child:[]
    }
]



