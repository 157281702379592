import { AxiosResponse } from "axios"
import { TApiGetUrlForCaseFile } from "../../../Types/Api/ApiCaseManagementRequest"
import API from '../api-base'


// https://adalisk.stoplight.io/docs/tp-api/branches/main/8beeae4d2d7f9-delete-file-by-s3key

const deleteCaseFileByS3Key = async ( params: TApiGetUrlForCaseFile ): Promise<AxiosResponse>  =>{
    return await API.delete(
        `/v2/cases/${ params.id }/files/${ params.s3key }`
    )
}

export default deleteCaseFileByS3Key
