import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiGetUrlForCaseFile } from "../../../Types/Api/ApiCaseManagementRequest"
import { TApiGetUrlForCaseFileResponse } from "../../../Types/Api/ApiCaseManagementResponse"

const getUrlForCaseFile = async ( requestParams: TApiGetUrlForCaseFile ): Promise<AxiosResponse<TApiGetUrlForCaseFileResponse>>  =>{
    
    
    return await API.get(
        `/v2/cases/${ requestParams.id }/file-links/${ requestParams.s3key }`,
        { 
            params:{ ...requestParams}
        }
    )
}

export default getUrlForCaseFile
