import { AxiosResponse } from "axios"
import API from './api-base'
import { TApiDeleteUserFromGroup  } from "../../Types/Api/ApiGroupsRequest"
import { enumApiParameter } from "../../Types/General"


// https://adalisk.stoplight.io/docs/tp-api/b3A6MzgzNDUyMjQ-delete-user-from-group

const deleteUserFromGroup = async ( params: TApiDeleteUserFromGroup ): Promise<AxiosResponse>  =>{
    return await API.delete(
        
        `/groups/${params[enumApiParameter.GROUP_ID]}/users`,

        {
            data: {
                [enumApiParameter.LOGIN] : params[enumApiParameter.LOGIN]
            }
        }
    )
}

export default deleteUserFromGroup
