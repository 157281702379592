import { useEffect, useState } from "react"
import getCasesByFilter from "../../../Api/CaseManagement/v2/get-cases-by-filter"
import ErrorModal from "../ErrorModal"
import { Box } from "@mui/system"
import { useAction } from "../../../Store/Hook/use-action"
import { useTypedSelector } from "../../../Store/Hook/use-typed-selector"
import CaseManagementTable from "./CaseManagementTable"
import { CircularProgress } from "@mui/material"
import FilterPanel from "./FilterPanel"
import { TApiGetCasesByFilter } from "../../../Types/Api/ApiCaseManagementRequest"


const CaseManagement = () =>{
    const { 
        needUpdateListFromAPI,
        filterData
    } = useTypedSelector(state=>state.caseManagement)
    
    const [
        includeAutoTestFilter,
        setIncludeAutoTestFilter
    ] = useState(false)

    const { 
        setNeedUpdateCaseManagementFromAPI,
        setCaseManagementErrorNeedToShow,
        setCaseManagementError,
        setCaseManagementCases,
    } = useAction()

    const [ isLoading, setLoading ] = useState(true)
    const [ casesListLength, setCasesListLength ] = useState(0)
    const setAndShowRequestError = ( error: any, message: string ) =>{
        setCaseManagementError({
            status  : error.response.data.status ? String(error.response.data.status) : '',
            path    : error.response.data.path   ? String(error.response.data.path)   : '',
            message : error.response.data.message ? String(error.response.data.message) : message
        })
        setCaseManagementErrorNeedToShow(true)
    }

    useEffect(()=>{
        if(needUpdateListFromAPI === true){
            
            const requestParams:TApiGetCasesByFilter = { ...filterData }

            if(!requestParams.id             ) delete requestParams.id
            if(!requestParams.source         ) delete requestParams.source
            if(!requestParams.externalId     ) delete requestParams.externalId
            if(!requestParams.locked         ) delete requestParams.locked
            if(!requestParams.createdBy      ) delete requestParams.createdBy
            if(!requestParams.dateCreatedFrom) delete requestParams.dateCreatedFrom
            if(!requestParams.dateCreatedTo  ) delete requestParams.dateCreatedTo
            if(!requestParams.dateUpdatedFrom) delete requestParams.dateUpdatedFrom
            if(!requestParams.dateUpdatedTo  ) delete requestParams.dateUpdatedTo

            getCasesByFilter({
                ...requestParams
            })
            .then((response)=>{
                setCaseManagementCases(response.data)
                setCasesListLength(response.data.length)
            })
            .catch((error)=>{
                setAndShowRequestError(error,  "CANT GET CASES BY FILTER")
            })
            .finally(()=>{
                setLoading(false)
            })

            setNeedUpdateCaseManagementFromAPI(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[needUpdateListFromAPI])



    return(
        <div>
            <Box
                sx={{display: 'flex'}}
            >
                <FilterPanel 
                    setLoading              = { setLoading               }
                    casesListLength         = { casesListLength          }

                    includeAutoTestFilter   = { includeAutoTestFilter    }
                    setIncludeAutoTestFilter= { setIncludeAutoTestFilter }
                />

                {
                    isLoading ?
                        <Box sx={{
                            width: 'calc( 100% - 350px)', 
                            height: '426px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '4px',
                        }}>
                            <CircularProgress/>
                        </Box>
                    :
                        <CaseManagementTable
                            setLoading            = { setLoading            }
                            includeAutoTestFilter = { includeAutoTestFilter }
                        />
                }

            </Box>

            <ErrorModal/>
        </div>
    )
}

export default CaseManagement
