import { useTypedSelector } from "../../../Store/Hook/use-typed-selector"
import { useEffect, useState } from 'react'
import { useAction } from "../../../Store/Hook/use-action"
import BookOnlineIcon from '@mui/icons-material/BookOnline'
import { Box, CircularProgress } from "@mui/material"
import Paginaton from "../../CaseManagement/IndexPage/CaseManagementPaginaton"
import getMtpCasesList from "../../../Api/MobileTreatmentPlan/get-mtp-cases-list"
import MTPCaseListTable from "./MTPCaseListTable"
import MtpFilterPanel from "./MtpFilterPanel"
import ErrorModal from "../../Workflow/CreateRule/ErrorModal"
import colorLibrary from "../../../Utils/colors"

const warningStyle = {
    width: 'calc( 100% - 350px)', 
    height: '426px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #ddd',
    borderRadius: '4px',
}

const MTPCaseList = () => {
    const { 
        needUpdateFromApi,
        list,
        filterData,
    } = useTypedSelector(state => state.mtp.mtpCasesList)

    const { error } = useTypedSelector(state => state.mtp)

    const { 
        setMtpNeedUpdateCasesListFromAPI,
        setMtpList,
        setMtpListPageNumber,
        setMtpListPageSize,
        showMtpError,
        hideMtpError
    } = useAction()
    
    const [ isLoading      , setLoading         ] = useState(true)
    const [ isDataReceived , setDataReceived    ] = useState(false)
    const [ casesListLength, setCasesListLength ] = useState(list.length)

    useEffect(()=>{ // browser "back" action fix
        if(list.length>0){
            setLoading(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])

    const setAndShowRequestError = ( error: any, message: string ) =>{
        showMtpError({
            status  : error.response.data.status ? String(error.response.data.status) : '',
            path    : error.response.data.path   ? String(error.response.data.path)   : '',
            message : error.response.data.message ? String(error.response.data.message) : message
        })
        setMtpNeedUpdateCasesListFromAPI(true)
    }

    useEffect(()=>{
        if(needUpdateFromApi === true){
            setDataReceived(false)
            getMtpCasesList( filterData )
            .then((response)=>{
                setMtpList(response.data)
                setCasesListLength(response.data.length)
                setDataReceived(true)
            })
            .catch((error)=>{
                setAndShowRequestError(error,  "CANT GET CASES BY FILTER")
            })
            .finally(()=>{
                setLoading(false)
            })
            setMtpNeedUpdateCasesListFromAPI(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[needUpdateFromApi])

    return(
        <div>

            {/* ICON + HEADER */}
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                margin: '6px 0 0 0'
            }} >
                <Box
                    sx={{
                        display: 'flex',

                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <BookOnlineIcon
                            sx={{
                                height: 42,
                                width: 42,
                                fill: colorLibrary.backgroundColor,
                                margin: '0 16px 0 0',
                            }}
                        />
                        <h2> MTP Cases  </h2>
                    </Box>
                </Box>
            </Box>

            <Paginaton 
                setPageNumber={ ( pageNumber:number ) => {
                    setMtpListPageNumber(pageNumber)
                    setMtpNeedUpdateCasesListFromAPI(true) 
                    setLoading(true)
                }}
                setPageSize  ={ (pageSize:number)=> {
                    setMtpListPageSize(pageSize)
                    setMtpNeedUpdateCasesListFromAPI(true)
                    setLoading(true)
                }}
                currentPageNumber       = { filterData['page.number']!==undefined ? filterData['page.number'] : 0  }
                currentPageSize         = { filterData['page.size'  ]!==undefined ? filterData['page.size'  ] : 20 }
                lastRequestRecordsCount = { casesListLength }
            />
            <Box
                sx={{display: 'flex'}}
            >
                <MtpFilterPanel 
                    setLoading={ setLoading }
                />
                {
                    isLoading === true &&
                    <Box sx={warningStyle}>
                        <CircularProgress/>
                    </Box>
                }

                {
                    isLoading === false && isDataReceived === true && casesListLength > 0 &&
                    <MTPCaseListTable
                        setLoading={ setLoading }
                    />
                }

                {
                    isLoading === false && isDataReceived === true && casesListLength === 0 &&
                    <Box sx={ warningStyle }>
                        Query result is empty
                    </Box>
                }

                {
                    isLoading === false && isDataReceived === false && casesListLength === 0 &&
                    <Box sx={ warningStyle }>
                        Query result was not received
                    </Box>
                }

            </Box>

            <ErrorModal
                header= { 'MTP Cases List Error' }
                error        = { error }
                errorControl = { hideMtpError } 
            />

        </div>
    )
}

export default MTPCaseList
