import { Dispatch } from 'redux'
import { THistoryRecord } from '../../Types/Api/ApiCaseManagementResponse'
import { enumSORT } from '../../Types/General'

import { 
    enumStoreCaseManagementUserHistoryAction,
    TPagination, 
    TCaseManagementUserHistorySetPagination,
    TCaseManagementUserHistorySetNeedUpdateFromAPI,
    TCaseManagementSetErrorPayload,
    TCaseManagementUserHistorySetErrorNeedToShow,
    TCaseManagementUserHistorySetError,
    TCaseManagementUserHistoryListAction,
    TCaseManagementUserHistorySetFilter,
    TCaseManagementUserHistorySetSortingDirectionAction,

} from '../../Types/Store/StoreCaseManagement'


export const setNeedUpdateCaseManagementUserHistoryFromAPI = ( isNeedToUpdate: boolean) =>{
    return((dispatch: Dispatch<TCaseManagementUserHistorySetNeedUpdateFromAPI>) =>{
        dispatch({
            type: enumStoreCaseManagementUserHistoryAction.SET_CMUH_NEED_UPDATE_FROM_API,
            payload: isNeedToUpdate
        })
    })
}

export const setCaseManagementUserHistoryPagination = (pagination: TPagination) => {
    return((dispatch: Dispatch<TCaseManagementUserHistorySetPagination>) =>{
        dispatch({
            type: enumStoreCaseManagementUserHistoryAction.SET_CMUH_SET_PAGINATION,
            payload: pagination
        })
    })
}


export const setCaseManagementUserHistoryError = ( error: TCaseManagementSetErrorPayload ) => {
    return((dispatch: Dispatch<TCaseManagementUserHistorySetError>) =>{
        dispatch({
            type: enumStoreCaseManagementUserHistoryAction.SET_CMUH_ERROR,
            payload: error
        })
    })
}

export const setCaseManagementUserHistoryErrorNeedToShow = ( isErrorNeedToShow: boolean ) => {
    return((dispatch: Dispatch<TCaseManagementUserHistorySetErrorNeedToShow>) =>{
        dispatch({
            type: enumStoreCaseManagementUserHistoryAction.SET_CMUH_ERROR_NEED_TO_SHOW,
            payload: isErrorNeedToShow
        })
    })
}

export const setCaseManagementUserHistory = ( historyList: THistoryRecord[] ) =>{
    return((dispatch: Dispatch<TCaseManagementUserHistoryListAction>) =>{
        dispatch({
            type: enumStoreCaseManagementUserHistoryAction.SET_CMUH_HISTORY_LIST,
            payload: historyList
        })
    })
}

export const setCaseManagementUserHistoryFilter = (filterData: {
    user : string
    from : string
    to : string
}) => {
    return((dispatch: Dispatch<TCaseManagementUserHistorySetFilter>) =>{
        dispatch({
            type: enumStoreCaseManagementUserHistoryAction.SET_CMUH_FILTER_DATA,
            payload: filterData
        })
    })
}

export const setCaseManagementUserHistorySortingDirection = ( sorting: enumSORT ) =>{
    return((dispatch: Dispatch<TCaseManagementUserHistorySetSortingDirectionAction>) =>{
        dispatch({
            type: enumStoreCaseManagementUserHistoryAction.SET_CMUH_DIRECTION,
            payload: sorting
        })
    })
}

