import { Box, Button } from "@mui/material"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { TCaseFileDescription } from "../../../../Types/Api/ApiCaseManagementResponse"
import FileThreeAddNewFolder from "./FilesToolbarActionAddNewFolder"
import FileThreeUpload from "./FilesToolbarActionUploadFile"
import FilesToolbarActionDelete from "./FilesToolbarActionDelete"
import FilesToolbarActionDownloadFile from "./FilesToolbarActionDownloadFile"
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'

export const toolbarButtonStyle = {
    color: '#ddd',
    border: '1px solid #ffffff00',
    borderRadius: '5px',
    textTransform: 'none',
    margin: '0 10px 0 0',
    backgroundColor: '#ffffff33',
    ':hover':{
        color: '#fff',
        border: '1px solid #ffffffff',
        backgroundColor: '#ffffff00'
    },
    ':last-child':{
        margin: '0',
    }
}


type TdetectNodeOptions = {
    selectedNodes: string[]
    filesList    : TCaseFileDescription[]
}
export type TFilesToolbarProps = TdetectNodeOptions & {
    caseId: string
    isFileUploaded: boolean
    pathInCase    : string[]
    uploadProgress: number
    setNewFolder: (folderName:string)=>void
    removeSelectedNewFolder: (folderToRemove:string)=>void
    setFileListNotLoaded : ()=>void
    setFileUploaded : ( isFileUploaded:boolean)=>void
    setUploadProgress : ( progressInPercent      : number ) => void
    setFilesToOpenInSMI: (filsToopen: string[]) => void
}


const detectNodeOptions = (props: TdetectNodeOptions) =>{
    const { selectedNodes, filesList } = props

    let isSingleFileSelected   = false
    let isSingleFolderSelected = false
    let isSingleFolderEmpty    = true
    let isMultiplyFilesSelected = false

    if(selectedNodes.length === 1 && selectedNodes[0].indexOf('FILE|')>-1){
        isSingleFileSelected = true
        isMultiplyFilesSelected = false
    }else if(selectedNodes.length === 1 && selectedNodes[0].indexOf('FOLDER|')>-1){
        isSingleFolderSelected = true
        const splittedPath = selectedNodes[0].split('/')
        const clearedPath = `/${splittedPath.slice(1,splittedPath.length).join('/')}/`
        if(selectedNodes[0] === 'ROOTFOLDER|'){
            isSingleFolderEmpty = false
        }else{
            for(let i = 0; i<filesList.length; i++){
                if(filesList[i].s3key.indexOf(clearedPath)>-1){
                    if(isSingleFolderEmpty === true){
                        isSingleFolderEmpty = false
                    }
                }
            }
        }
    }

    if(selectedNodes.length > 1){
        const selectedFiles = selectedNodes.filter((nodeId)=>{
            return nodeId.indexOf('FILE|')>-1
        })
        if(selectedFiles.length === selectedNodes.length){
            isMultiplyFilesSelected = true
        }
    }


    return({
        isSingleFileSelected,
        isSingleFolderSelected,
        isSingleFolderEmpty,
        isMultiplyFilesSelected,
    })
}

const FilesToolbar = (props:TFilesToolbarProps) =>{
    const { 
        setNewFolder,
        caseId,
        isFileUploaded,
        pathInCase,
        uploadProgress,
        setFileListNotLoaded,
        setFileUploaded,
        setUploadProgress,
        filesList,
        selectedNodes,
        removeSelectedNewFolder,
        setFilesToOpenInSMI,
    } = props
    const {
        isSingleFileSelected,
        isSingleFolderEmpty,
        isSingleFolderSelected,
        isMultiplyFilesSelected,
    } = detectNodeOptions(props)


    let fileDescription:TCaseFileDescription = filesList[0]
    if(isSingleFileSelected){
        const splitted = selectedNodes[0].split('/')
        const clearedPath = splitted.slice(1,splitted.length).join('/')

        filesList.forEach((fileItem)=>{
            if(fileItem.s3key.indexOf(clearedPath)>-1){
                fileDescription = fileItem
            }
        })
    }


    const onOpenFilsHandler = () =>{
        setFilesToOpenInSMI(selectedNodes)
    }

    return(
        <Box sx={{
            backgroundColor: '#5700ff',
            color: '#fff',
            padding: '4px 8px',
            display: 'flex',
            justifyContent: 'space-between',
            fill: '#fff',
            width: '100%',
            height: '39px',
        }}>
            
                {
                    isSingleFolderSelected &&
                    
                    <Box 
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>

                            <FileThreeAddNewFolder
                                getNewFolderName={(newFolderName:string)=>{
                                    setNewFolder(newFolderName)
                                }}
                            />
                            
                            <FileThreeUpload 
                                caseId                = { caseId               }
                                isFileUploaded        = { isFileUploaded       }
                                pathInCase            = { pathInCase           }
                                uploadProgress        = { uploadProgress       }
                                setFileListNotLoaded  = { setFileListNotLoaded }
                                setFileUploaded       = { setFileUploaded      }
                                setUploadProgress     = { setUploadProgress    }
                            />
                            
                        </Box>
                        {
                            isSingleFolderEmpty &&
                            <Button
                                size="small"
                                variant="outlined"
                                sx={toolbarButtonStyle} 
                                startIcon={<DeleteForeverIcon />}
                                onClick={()=>{
                                    const splitted = selectedNodes[0].split('/')
                                    const clearedPath = `/${splitted.slice(1,splitted.length).join('/')}/`
                                    removeSelectedNewFolder(clearedPath)
                                }}
                            >
                                Delete Folder
                            </Button>
                            
                        }
                    </Box>
                    
                }

            {
                isSingleFileSelected &&
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >

                    <Box
                        sx={{display: 'flex'}}
                    >
                        <Button
                            size="small"
                            variant="outlined"
                            sx={toolbarButtonStyle} 
                            startIcon={<OpenInBrowserIcon />}
                            onClick={ onOpenFilsHandler }
                        >
                            Open
                        </Button>

                        <FilesToolbarActionDownloadFile
                            id       = { caseId                   }
                            fileName = { fileDescription.fileName }
                            s3key    = { fileDescription.s3key    }
                        />

                    </Box>
                    
                    <FilesToolbarActionDelete 
                        fileDescription      = { fileDescription   }
                        setNeedUpdateFileTree= { setFileListNotLoaded }
                    />
                    
                </Box>
            }

            {
                isMultiplyFilesSelected &&
                    <Button
                        size="small"
                        variant="outlined"
                        sx={toolbarButtonStyle} 
                        startIcon={<OpenInBrowserIcon />}
                        onClick={ onOpenFilsHandler }
                    >
                        Open
                    </Button>
  
            }

        </Box>
    )
}

export default FilesToolbar
