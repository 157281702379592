import { AxiosResponse } from "axios"
import API from './api-base'
import { TApiGetCaseByCaseId      } from "../../Types/Api/ApiCaseManagementRequest"
import { TApiGetMtpCaseByCaseIdResponse } from "../../Types/Api/ApiMtpResponse"

// https://adalisk.stoplight.io/docs/tp-api/b3A6MzYwMDY5Nzc-get-cases-by-case-id

const getMtpCaseByCaseId = async ( requestParams: TApiGetCaseByCaseId ): Promise<AxiosResponse<TApiGetMtpCaseByCaseIdResponse>>  =>{
    return await API.get(
        
        `/mtps/${ requestParams.caseId }`
    )
}

export default getMtpCaseByCaseId
