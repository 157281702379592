import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { useTypedSelector } from '../../../Store/Hook/use-typed-selector'
import { useAction } from '../../../Store/Hook/use-action'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'max-content',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

const ErrorModal = () => {

    const {
        needToShow,
        status,
        path,
        message
    } = useTypedSelector(store => store.desktopConfigurations.error)

    const {
        SetErrorNeedToShow,
    } = useAction()


    const handleClose = () =>{
        SetErrorNeedToShow(false)
    } 

    

    return (
        needToShow === true ? 
            <div>
                <Modal
                    open={needToShow}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Error { ` ${ status }`}
                        </Typography>
                        
                        {
                            path ?
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    { `Path: ${ path } `}
                                </Typography>
                            :
                                null
                        }

                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            { `Message: ${ message }`}
                        </Typography>
                    </Box>
                </Modal>
            </div>
        : 
            null        
    )
}

export default ErrorModal
