import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiGetCaseHistory } from "../../../Types/Api/ApiCaseManagementRequest"
import { TApiGetCaseHistoryResponse } from "../../../Types/Api/ApiCaseManagementResponse"

// https://adalisk.stoplight.io/docs/tp-api/2fb81617f240c-get-list-of-event-logs-by-case-id

const getCaseHistory = async ( requestParams: TApiGetCaseHistory ): Promise<AxiosResponse<TApiGetCaseHistoryResponse>>  =>{
    
    const { caseId } = requestParams

    return await API.get(
        `/v2/cases/${caseId}/logs`,
        { 
            params:{ ...requestParams} 
        }
    )
}

export default getCaseHistory
