import { Dispatch } from 'redux'
import { TPagination } from '../../Types/Store/StoreCaseManagement'
import { 
    enumWorkflowStoreActionType, 
    TWorkflowRule, 
    TWorkflowSetErrorPayload, 
    TWorkflowStoreActionSetError, 
    // TWorkflowStoreActionSetErrorNeedToShow, 
    TWorkflowStoreActionSetNeedUpdateRulesListFromAPI, 
    TWorkflowStoreActionSetPagination, 
    TWorkflowStoreActionSetRulesList 
} from '../../Types/Store/StoreWorkflow'

export const setWorkflowRulesList = ( actionPayload: TWorkflowRule[]) =>{
    return((dispatch: Dispatch<TWorkflowStoreActionSetRulesList>) =>{
        dispatch({
            type: enumWorkflowStoreActionType.SET_WORKFLOW_RULES_LIST,
            payload: actionPayload
        })
    })
}

export const setWorkflowRulesPagination = (pagination: TPagination) => {
    return((dispatch: Dispatch<TWorkflowStoreActionSetPagination>) =>{
        dispatch({
            type: enumWorkflowStoreActionType.SET_WORKFLOW_RULES_PAGINATION,
            payload: pagination
        })
    })
}

export const setWorkflowRulesNeedUpdateFromAPI = ( isNeedToUpdate: boolean) =>{
    return((dispatch: Dispatch<TWorkflowStoreActionSetNeedUpdateRulesListFromAPI>) =>{
        dispatch({
            type: enumWorkflowStoreActionType.SET_WORKFLOW_RULES_NEED_UPDATE_FROM_API,
            payload: isNeedToUpdate
        })
    })
}

export const setWorkflowError = ( error: TWorkflowSetErrorPayload ) => {
    return((dispatch: Dispatch<TWorkflowStoreActionSetError>) =>{
        dispatch({
            type: enumWorkflowStoreActionType.SET_WORKFLOW_RULES_ERROR,
            payload: error
        })
    })
}



