import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiGetApplicationConfigByNameAndVersionResponse } from "../../../Types/Api/ApiDesktopConfigResponse"


// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=DesktopConfiguration%20service%20v2#/Application%20configuration%20API/get

const getApplicationConfigByNameAndVersion = async ( appName: string, appVersion: string ): Promise<AxiosResponse<TApiGetApplicationConfigByNameAndVersionResponse>>  =>{

    return await API.get(
        `/v2/configurations/applications/${appName}/${appVersion}`
    )
}

export default getApplicationConfigByNameAndVersion
