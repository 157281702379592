import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiPatchUpdateCasePreviousIdRequest } from "../../../Types/Api/ApiCaseManagementRequest"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Cases%20API/updateCase

const patchUpdateCasePreviousCaseId = async ( params:TApiPatchUpdateCasePreviousIdRequest  ): Promise<AxiosResponse>  =>{
    return await API.patch(
        `/v2/cases/${params.caseId}`,
        { 
            previousCaseId: params.previousCaseId
        }
    )
}

export default patchUpdateCasePreviousCaseId
