import { enumSORT } from "../../Types/General"
import { enumStoreCaseManagementAction, IStoreCaseManagement, TCaseManagementStoreAction } from "../../Types/Store/StoreCaseManagement"

const initialState:IStoreCaseManagement = {
    
    needUpdateListFromAPI: false,
    
    list: [],

    filterData:{
        
        // pagination
        direction        : enumSORT.DESC,
        "page.number"    : 0,
        "page.size"      : 100,
        sortBy           : "DATE_CREATED",

        // filter
        createdBy       : undefined,
        dateCreatedFrom : undefined,
        dateCreatedTo   : undefined,
        dateUpdatedFrom : undefined,
        dateUpdatedTo   : undefined,
        externalId      : undefined,
        source          : undefined,
        id              : undefined,
        locked          : undefined,
        status          : undefined,
    },

    error:{
        needToShow: false,
        status    : '',
        path      : '',
        message   : '',
    }
}

export const caseManagementReducer = ( 

    state = initialState,
    action : TCaseManagementStoreAction

) : IStoreCaseManagement => {
    
    switch(action.type){
        case  enumStoreCaseManagementAction.SET_CM_NEED_UPDATE_GROUPS_FROM_API :            
            return ({
                ...state,
                needUpdateListFromAPI: action.payload,
            })

        case enumStoreCaseManagementAction.SET_CM_CASES_LIST:

            return({
                ...state,
                list: action.payload.casesList,
            })

        case enumStoreCaseManagementAction.SET_CM_ERROR_NEED_TO_SHOW:{
            return({
                ...state,
                error:{
                    ...state.error,
                    needToShow: action.payload
                }
            })
        }
        
        case enumStoreCaseManagementAction.SET_CM_ERROR:{
            return({
                ...state,
                error:{
                    ...state.error,
                    ...action.payload
                }
            })
        }

        case enumStoreCaseManagementAction.SET_CM_CASES :{
            return({
                ...state,
                list: action.payload,
            })
        }

        case enumStoreCaseManagementAction.SET_CM_PAGE_SIZE :{
            return({
                ...state,
                filterData:{
                    ...state.filterData,
                    "page.size": action.payload,
                    "page.number": 0
                }
            })
        }

        case enumStoreCaseManagementAction.SET_CM_PAGE_NUMBER :{
            return({
                ...state,
                filterData:{
                    ...state.filterData,
                    "page.number": action.payload
                }
            })
        }

        case enumStoreCaseManagementAction.SET_CM_FILTER_DATA :{
            return({
                ...state,
                filterData:{
                    ...state.filterData,
                    ...action.payload 
                }
            })
        }

        default:
            return state
    }
}
