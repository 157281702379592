import { Tab, Tabs } from "@mui/material"
import { Box } from "@mui/system"
import { useEffect, useState, SyntheticEvent } from "react"
import { useNavigate, useParams } from "react-router-dom"
import getCaseByCaseId from "../../../Api/CaseManagement/v2/get-case-by-case-id"
import { useAction } from "../../../Store/Hook/use-action"
import { enumApiParameter } from "../../../Types/General"
import { TCase } from "../../../Types/Store/StoreCaseManagement"
import ErrorModal from "../ErrorModal"
import CaseView from "./CaseInfo/CaseView"
import CaseHistoryView from "./CaseHistoryView"
import checkUuid from "../../../Utils/check-uuid"
import SmileInspector from "./SmileInspector/SmileInspector"
import CasePropertiesView from "./CasePropertiesView"
import CaseFiles from "./CaseFiles/CaseFiles"
import Comments from "./Comments"

type TActiveTab = 'INFO'| 'FILES' | 'HISTORY' | 'SMILE_INSPECTOR'
export type TCasePropertyForUpdate = '' | 'all' | 'locked' | 'status'
export type TCaseProps = {
    activeTab?: TActiveTab
}

const tabHeightInPX = '26px'

const tabStyle = { 
    textTransform: 'none',
    border: '2px solid #f8f8f8',
    borderBottom: 'none',
    borderRight: 'none',
    ':last-child':{
        borderRight: '2px solid #f8f8f8',
    },
    height: tabHeightInPX,

}

const Case = (props:TCaseProps) => {
    const activeTabToIndex = (tabName?: TActiveTab): number => {
        switch(tabName) {
            case 'FILES': return 1
            case 'HISTORY': return 2
            case 'SMILE_INSPECTOR' : return 3
            default: return 0
        }
    }

    const { activeTab } = props
    const { caseId } = useParams()
    const { setCaseManagementError , setCaseManagementErrorNeedToShow } = useAction()
    const [ isNeedUpdateProperty   , setNeedUpdateProperty            ] = useState<TCasePropertyForUpdate>('')
    const [ currentCase            , setCurrentCase                   ] = useState<TCase | null>(null)
    const [ tabIndexValue          , setTabIndexValue                 ] = useState(activeTabToIndex(activeTab))
    const [ filesToOpenInSMI       , setFilesToOpenInSMI              ] = useState<string[]>([])
    const history = useNavigate()
    
    const onTabClick = (event: SyntheticEvent, newValue: number) => {
        setTabIndexValue(newValue)
    }
    
    useEffect(()=>{
        if(checkUuid(caseId)){
            if (!currentCase) {
                getCaseByCaseId({
                    [enumApiParameter.CASE_ID]: caseId ? caseId : '' 
                }).then((response)=>{
                    setCurrentCase(response.data)
                }).catch((error)=>{
                    setCaseManagementError({
                        status  : error.response.data.status ? String(error.response.data.status) : '',
                        path    : error.response.data.path   ? String(error.response.data.path)   : '',
                        message : error.response.data.message ? String(error.response.data.message) : "CANT GET CASE BY CASE_ID"
                    })
                    setCaseManagementErrorNeedToShow(true)
                    console.log("CANT GET CASE BY CASE_ID", error, error.request)
                })
            }else{

                if(isNeedUpdateProperty !== '' || currentCase.id !== caseId){

                    getCaseByCaseId({
                        [enumApiParameter.CASE_ID]: caseId ? caseId : '' 
                    }).then((response)=>{
                        setCurrentCase(response.data)
                    }).catch((error)=>{
                        setCaseManagementError({
                            status  : error.response.data.status ? String(error.response.data.status) : '',
                            path    : error.response.data.path   ? String(error.response.data.path)   : '',
                            message : error.response.data.message ? String(error.response.data.message) : "CANT GET CASE BY CASE_ID"
                        })
                        setCaseManagementErrorNeedToShow(true)
                        console.log("CANT GET CASE BY CASE_ID", error, error.request)
                    })
                    .finally(()=>{
                        setNeedUpdateProperty('')
                    })
                }

            }
        }else{
            setCaseManagementError({
                status  : '',
                path    : '',
                message : `Invalid CaseId = ${ caseId }`
            })
            setCaseManagementErrorNeedToShow(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[caseId, currentCase, isNeedUpdateProperty])



    useEffect(()=>{
        switch(tabIndexValue){
            case 0:
                history(`/case-management/case/${caseId}`)
                break
            case 1:
                history(`/case-management/case/${caseId}/files`)
                break
            case 2:
                history(`/case-management/case/${caseId}/history`)
                break
            case 3:
                history(`/case-management/case/${caseId}/inspector`)
                break

            default:
                history(`/case-management/case/${caseId}`)
        } 
    },[tabIndexValue])

    

    return(

        <div> 
            
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                width: '100%',
            }} >
                
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <CasePropertiesView
                        currentCase           = { currentCase       }
                        isNeedUpdateProperty  = { isNeedUpdateProperty  }
                        setNeedUpdateProperty = { setNeedUpdateProperty }
                    />
                </Box>

                <Tabs
                    value    = { tabIndexValue          }
                    onChange = { onTabClick             }
                    sx={{
                        minHeight: tabHeightInPX,
                        'div':{
                            height: tabHeightInPX,
                            'button': {
                                minHeight: tabHeightInPX,
                                'span':{
                                    height: tabHeightInPX,
                                }
                            }
                        },
                    }}
                >
                    <Tab
                        label="Info"      
                        sx={{...tabStyle,
                            backgroundColor: tabIndexValue === 0 ? '#eee' : 'unset',
                        }}
                    />
                    
                    <Tab 
                        label="Files"
                        sx={{...tabStyle,
                            backgroundColor: tabIndexValue === 1 ? '#eee' : 'unset',
                        }}     
                    />

                    
                    
                    <Tab 
                        label="History"
                        sx={{...tabStyle,
                            backgroundColor: tabIndexValue === 2 ? '#eee' : 'unset',
                        }}       
                    />

                    <Tab 
                        label="Smile Inspector"
                        sx={{...tabStyle,
                            backgroundColor: tabIndexValue === 3 ? '#eee' : 'unset',
                        }}      
                    />

                    <Tab 
                        label="Comments"
                        sx={{...tabStyle,
                            backgroundColor: tabIndexValue === 4 ? '#eee' : 'unset',
                        }}      
                    />

                </Tabs>
                
            </Box>



            {   // ======================================
                // tab INFO
                // ======================================

                tabIndexValue === 0 ?
                <>

                    <Box
                        sx={{
                            borderTop: '2px solid #eee',
                        }}
                    >
                        {
                            currentCase !== null ?
                                <CaseView 
                                    caseToView= {{...currentCase}}
                                    setNeedUpdateProperty={ setNeedUpdateProperty } 
                                />
                            : 
                                null
                        }

                    </Box>
                    
                </>

                :null
            }

            {   // ======================================
                // tab FILES
                // ======================================
                tabIndexValue === 1 ?
                <Box
                    sx={{
                        borderTop: '2px solid #eee',
                    }}
                >

                    <CaseFiles
                        caseId = {caseId}
                        setFilesToOpenInSMI = { (filesToOpen:string[], isReset?:boolean)=>{
                            setFilesToOpenInSMI(filesToOpen)
                            if(isReset !== true){
                                setTabIndexValue(3)
                            }
                        }}
                    />

                </Box>
                :null
            }


            {   // ======================================
                // tab HISTORY
                // ======================================
                tabIndexValue === 2 ?
                <Box
                    sx={{
                        borderTop: '2px solid #eee',
                    }}
                >

                    <CaseHistoryView
                        caseId = {caseId}
                    />

                </Box>
                :null
            }

            {   // ======================================
                // tab SMILE INSPECTOR
                // ======================================
                tabIndexValue === 3 ?
                <Box
                    sx={{
                        borderTop: '2px solid #eee',
                        padding: 0,
                        minHeight: 'calc( 100vh - 148px)',
                        display: 'flex',
                    }}
                >

                    <SmileInspector
                        caseId = {caseId}
                        filesToOpenInSMI = { filesToOpenInSMI }
                    />

                </Box>
                :null
            }

            {   // ======================================
                // tab COMMENTS
                // ======================================
                tabIndexValue === 4 &&
                <Comments
                    caseId = { caseId }
                />
                
            }

            <ErrorModal/>
        </div>
    )
}

export default Case
