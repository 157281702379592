import { AxiosResponse } from "axios"
import API from '../api-base'
import { TCaseStatusTransition } from "../../../Types/Api/ApiCaseManagementResponse"

const getTransitionFromStatus = async ( status: string ): Promise<AxiosResponse<TCaseStatusTransition>>  => {
    return await API.get(
        `/v2/case-status-transitions/${ status }`
    )
}

export default getTransitionFromStatus
