import { useState } from 'react'
import { TextField, IconButton} from '@mui/material'
import DateAdapter from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DesktopDatePicker  from '@mui/lab/DesktopDatePicker'
import CancelIcon from '@mui/icons-material/Cancel'
import { getDateInputTemplate } from '../../../Utils/format-date-to-local'


interface IDateTimePickerProps {
    from: null | string
    disabled? : boolean
    label? : string
    setFrom( 
        periodFrom: null | string,
        periodTo  : null | string
    ): void
    error?: boolean
}

function isValidDate(d: unknown) {
    return d instanceof Date && !isNaN(Number(d))
}

const DateTimePicker = (props: IDateTimePickerProps) => {

    const { 
        from,
    } = props
    
    const { 
        setFrom,
    } = props

    const { 
        error,
    } = props

    const dateConfig = getDateInputTemplate() 

    const [isErrorDetected, setErrorDetected ] = useState(false)

    return (

        
        <LocalizationProvider dateAdapter={DateAdapter}>

            <DesktopDatePicker
                inputFormat={ dateConfig[0] }
                mask       ={ dateConfig[1] }
                disabled = { props.disabled ? true : false }
                label={ props.label ? props.label : 'Updated Date' }
                value={ from === '' ? null : from }
                
                onChange={(newValue) => {
                    if(isValidDate(newValue)){
                        const newDate = new Date(newValue!)
                        const newTimeFrom = new Date(newDate.getFullYear()+'-'+(newDate.getMonth()+1)+'-'+newDate.getDate())
                        const newTimeTo   = new Date(newDate.getFullYear()+'-'+(newDate.getMonth()+1)+'-'+newDate.getDate())
                        newTimeTo.setDate(newTimeFrom.getDate() +1)
                        
                        if(isNaN(newTimeFrom.getDate())===false){
                            setFrom(newTimeFrom.toISOString(), newTimeTo.toISOString())
                            setErrorDetected(false)
                        }else{
                            setFrom(null, null)
                        }
                    }else{
                        setErrorDetected(true)
                    }
                }}
                
                renderInput={
                    (params) => 
                        <TextField 
                            {...params} 
                            sx={{ 
                                marginRight: from ? undefined : '28px', 
                                width: 160,
                            }}
                            error={ error ? error: ( isErrorDetected ? isErrorDetected : undefined)}
                        />
                }
            />
            
            {
                from ?
                    <IconButton
                        onClick={()=>{
                            setFrom(null, null)
                        }}
                        edge="end"
                    >
                        <CancelIcon/>
                    </IconButton>
                :
                    null
            }
            
        </LocalizationProvider>

    )
}

export default DateTimePicker
