import { TNode, TNodeRecord } from "../CaseFiles"

const convertNodesRecordsToNodesTree = (nodesRecords:TNodeRecord[], idPrefix?:string ):TNode[]=>{
    const nodesTree:TNode[] = []


    nodesRecords
    .forEach((nodeRecord)=>{
        let newNode:TNode | undefined = undefined
        if(nodeRecord.path.length === 1){ // FOLDER OR FILE WITHOUT CHILDS

            if(nodeRecord.fileDescription){ // FILE
                newNode = {
                    id             : `FILE|${idPrefix ? idPrefix : ''}/${nodeRecord.path[0]}`,
                    path           : [],
                    type           : 'FILE',
                    child          : [],
                    name           : nodeRecord.path[0],
                    fileDescription: nodeRecord.fileDescription
                }
            }else{ // NEW FOLDER
                newNode = {
                    id             : `FOLDER|${idPrefix ? idPrefix : ''}/${nodeRecord.path[0]}`,
                    path           : [],
                    type           : 'FOLDER',
                    child          : [],
                    name           : nodeRecord.path[0],
                    fileDescription: undefined
                }
            }
            nodesTree.push(newNode)

        }else if(nodeRecord.path.length > 1){ // FOLDER WITH CHILDS

            const newPrefix:string = nodeRecord.path[0]
            const newPath = [...nodeRecord.path]
            newPath.shift()

            const nextLevelRecords:TNodeRecord[] = [...nodesRecords]
            .filter((record)=>{
                return( record.path[0] === newPrefix )
            })
            .map((record)=>({
                path: record.path.slice(1,record.path.length),
                fileDescription: record.fileDescription
            }))
            
            newNode = {
                id: `FOLDER|${idPrefix ? idPrefix : ''}/${newPrefix}`,
                path: nodeRecord.path,
                name: newPrefix,
                type: 'FOLDER',
                fileDescription: undefined,
                child: [...convertNodesRecordsToNodesTree(nextLevelRecords,`${idPrefix ? idPrefix : ''}/${newPrefix}`)]
            }
            
        }

        let isNodeAlreadyExist = false
        nodesTree.forEach((nodeItem)=>{
            if( newNode && nodeItem.id === newNode.id){
                isNodeAlreadyExist = true
            }
        })

        if(newNode && isNodeAlreadyExist === false){
            nodesTree.push(newNode)
        }
    })
    
    return([
        ...nodesTree
            .filter(nodeItem=>nodeItem.type === 'FOLDER')
            .sort((a,b)=>(
                a.name.localeCompare(b.name)
            )),
        ...nodesTree
            .filter(nodeItem=>nodeItem.type === 'FILE')
            .sort((a,b)=>(
                a.name.localeCompare(b.name)
            ))
    ])
}

// Баг с загрузкой файла в новуб папку
// Множественная загрузка файлов

export default convertNodesRecordsToNodesTree
