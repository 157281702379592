import { TMtpCaseFileDescription } from "../../../Types/Api/ApiMtpResponse"



export type TMTPFileDescription = {
    presignedUrl: {
        expiration: number
        url: string
        method: 'PUT' | 'GET'
    }
    mimeType: string
    name: string
}


export type TMTPTreeFileDescription = {
    name: string
    type: 'FOLDER' | 'FILE'
    child: TMTPTreeFileDescription[]
    fileDescription?:TMTPFileDescription
}

const getFileTree = (filesDescritpion: TMtpCaseFileDescription[]) => {
    
    const fileTree:TMTPTreeFileDescription[] = []

    // topLevelFolders
    filesDescritpion.filter((fileItem)=>{
        const splittedPath = fileItem.path.split('/')
        return(splittedPath.length > 1 && splittedPath[0])
    })
    .map((item) => {
        const splittedPath = item.path.split('/')
        return(
            splittedPath[0]
        )
    })
    .filter((value, index, self) => {
        return self.indexOf(value) === index
    })
    .forEach( (item) => {

        const nextLevelRecords = 
        
        filesDescritpion
        .filter( recordItem => recordItem.path.split('/')[0] === item )
        .map((item) => {

            return({
                ...item,
                path: item.path.split('/').slice(1, item.path.length).join('/')
            })
        })

        fileTree.push({
            name: item,
            type: 'FOLDER',
            child: [...getFileTree(nextLevelRecords)]
        })
    })

    // topLevelFiles
    filesDescritpion
    .filter((fileItem)=>{
        const splittedPath = fileItem.path.split('/')
        return(splittedPath.length === 1)
    })
    .forEach((item) => {
        
        fileTree.push({
            name: item.path,
            type: 'FILE',
            child: [],
            fileDescription: {
                ...item,
                name: item.path
            }
        })
    })

    return fileTree

}

export default getFileTree

