import { Table } from "@mui/material"
import RulesTableHeader, { TTableHederDescriptor } from "./header"
import RulesTableBody from "./body"
import { useTypedSelector } from "../../../Store/Hook/use-typed-selector"

export const tableHeaderDescription:TTableHederDescriptor[] = [
    {
        id:      'name',
        label:   'Name',
        numeric: false,
        isSortable: false,
    },
    {
        id:      'order',
        label:   'Priority',
        numeric: true,
        isSortable: false,
    },
    {
        id:      'enabled',
        label:   'Enabled',
        align:    'center',
        numeric: false,
        isSortable: false,
    },
    {
        id:      'version',
        label:   'Version',
        numeric: true,
        isSortable: false,
    },
    {
        id: 'edit',
        label: '',
        numeric: false,
        isSortable: false,
    }
]

const RulesTable = () =>{
    
    const { rulesList } = useTypedSelector(state => state.workflow)

    return(
        <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
        >
            <RulesTableHeader
                headerDescription = { tableHeaderDescription }
            />
            <RulesTableBody
                headerDescription = { tableHeaderDescription }
                data = { rulesList }
            />
        </Table>
    )
}

export default RulesTable
