import { AxiosResponse } from "axios"
import { TApiUpdateCaseStatusRequest } from "../../../Types/Api/ApiCaseManagementRequest"
import { enumApiParameter } from "../../../Types/General"
import { TCase } from "../../../Types/Store/StoreCaseManagement"
import API from '../api-base'

// https://adalisk.stoplight.io/docs/tp-api/branches/main/d811dbdf3cbba-lock-case

const postUpdateCaseTransitionStatus = async ( params: TApiUpdateCaseStatusRequest ): Promise<AxiosResponse<TCase>>  =>{
    return await API.post(
        `/v2/cases/${ params.caseId }/status`,
        {
            [ enumApiParameter.STATUS      ] : params[enumApiParameter.STATUS],
            [ enumApiParameter.SMO_VERSION ] : params[enumApiParameter.SMO_VERSION],
        }
    )
}

export default postUpdateCaseTransitionStatus
