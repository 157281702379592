import { AxiosResponse } from "axios"
import API from './api-base'
import { TApiPostUpdateGroup } from "../../Types/Api/ApiGroupsRequest"

// https://adalisk.stoplight.io/docs/tp-api/b3A6MzgxMTk2MTc-update-group

const postUpdateGroup = async ( params: TApiPostUpdateGroup ): Promise<AxiosResponse>  =>{
    return await API.post(
        
        `/groups`,
        
        { 
            ...params 
        }
    )
}

export default postUpdateGroup
