import { Box } from "@mui/material"
import postCreateApplicationConfig from "../../../../Api/DesktopConfiguration/v2/post-create-application-config"
import { useAction } from "../../../../Store/Hook/use-action"
import { TApiPostCreateUpdateApplicationConfig } from "../../../../Types/Api/ApiDesktopConfigRequest"
import CreateConfig, { enumConfigType } from "../Dialogs/CreateConfig"

const ApplicationFilters = () =>{

    const { 
        SetNeedUpdateApplicationConfigsFromAPI,
        SetError,
        SetErrorNeedToShow,
    } = useAction()


    const onApplicationConfigCreate = (params: TApiPostCreateUpdateApplicationConfig) =>{
 
        postCreateApplicationConfig({
            ...params
        }).then((response)=>{
            SetNeedUpdateApplicationConfigsFromAPI(true)
        }).catch((error)=>{
            SetError({
                status  : error.response.data.status ? String(error.response.data.status) : '',
                path    : error.response.data.path   ? String(error.response.data.path)   : '',
                message : error.response.data.message ? String(error.response.data.message) : "CANT CREATE/UPDATE APPLICATION CONFIG"
            })
            SetErrorNeedToShow(true)
            console.log("CANT CREATE/UPDATE APPLICATION CONFIG", JSON.stringify(error))
        })
    }

    return(
        <Box sx={{ display: 'flex',justifyContent: 'flex-end', marginBottom: 1 }}>
            <CreateConfig
                application    = { ''                         }
                appVersion     = { ''                         }
                configType     = { enumConfigType.APPLICATION }
                onCreateConfig = { onApplicationConfigCreate  }
            />
        </Box>
    )
}

export default ApplicationFilters