import { AxiosResponse } from "axios"
import API from './api-base'
import { TApiPostAddUserToGroup } from "../../Types/Api/ApiGroupsRequest"
import { enumApiParameter } from "../../Types/General"

// https://adalisk.stoplight.io/docs/tp-api/b3A6MzgzNDUyMjM-add-user-to-group

const postAddUserToGroup = async ( params: TApiPostAddUserToGroup ): Promise<AxiosResponse>  =>{
    return await API.post(
    
        `/groups/${params[enumApiParameter.GROUP_ID]}/users`,
    
        { 
            [enumApiParameter.LOGIN] : params[enumApiParameter.LOGIN] 
        }
    )
}

export default postAddUserToGroup
