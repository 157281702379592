import { TableCell, TableHead, TableRow } from "@mui/material"
import { tableHeaderDescription } from "./table"

export type TTableHeaderRuleId = 'name' | 'order' | 'enabled' | 'version' | 'edit'
export type TTableHederDescriptor = {

    id        : TTableHeaderRuleId
    label     : string
    numeric   : boolean
    align?    : 'left' | 'right' | 'center' | 'justify'
    isSortable: boolean
}

export type TRulesTableHeaderProps = {
    headerDescription: TTableHederDescriptor[]
}



const RulesTableHeader = (props: TRulesTableHeaderProps) =>{

    
    return(

        <TableHead
            sx={{
                backgroundColor:'#5700ff'
            }}
        >
            <TableRow>
                {
                    tableHeaderDescription.map((item, index)=>{
                        return(
                            <TableCell
                                key={`rule_${item.id}`}
                                align={ item.align !== undefined ? item.align :  (item.numeric === true ? 'right' : 'left')}
                                padding='normal'
                                sx={{ fontWeight: 'bold', color: '#fff' }}
                            >
                                { item.label }
                            </TableCell>
                        )
                    })
                }
            </TableRow>
        </TableHead>
    )
}

export default RulesTableHeader
