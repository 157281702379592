import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiGetCaseStatusTransitionsResponse } from "../../../Types/Api/ApiCaseManagementResponse"

// https://adalisk.stoplight.io/docs/tp-api/branches/main/7ce813ac2f546-get-case-status-transitions

const getCaseStatusTransitions = async (  ): Promise<AxiosResponse<TApiGetCaseStatusTransitionsResponse>>  => {
    return await API.get(
        `/v2/case-status-transitions`
    )
}

export default getCaseStatusTransitions
