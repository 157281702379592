import { useEffect } from 'react'
import { Box, Button, Table, TableBody, TableCell, TableRow } from "@mui/material"
import { useAction } from "../../../Store/Hook/use-action"
import { useTypedSelector } from "../../../Store/Hook/use-typed-selector"
import { enumSORT, enumSortingField } from '../../../Types/General'
import ConfigTableHeader from './Tables/ConfigTableHeader'
import { applicationTableHeaderDescription } from './Tables/tableHeaderDescription'
import getApplicationConfigByNameAndVersion from '../../../Api/DesktopConfiguration/v2/get-application-config-by-name-and-version'
import { saveAs } from 'file-saver'
import { TApiDeleteApplicationConfig, TApiPostCreateUpdateApplicationConfig } from '../../../Types/Api/ApiDesktopConfigRequest'
import deleteApplicationConfig from '../../../Api/DesktopConfiguration/v2/delete-application-config'
import { enumConfigType } from './Dialogs/CreateConfig'
import ApplicationFilters from './ToolBar/ApplicationFilters'
import getAvalableApplicationsAndVersions from '../../../Api/DesktopConfiguration/v2/get-avalable-applications-and-versions'
import colorLibrary from '../../../Utils/colors'
import putUpdateApplicationConfig from '../../../Api/DesktopConfiguration/v2/put-update-application-config'
import EditConfig from './Dialogs/EditConfig'


const ApplicationConfigs = () => {

    const { applications, applicationsDescription } = useTypedSelector(state => state.desktopConfigurations)
    const {
        SetNeedUpdateApplicationConfigsFromAPI,
        SetError,
        SetErrorNeedToShow,
        SetApplicationsDescription,
    } = useAction()

    const { needUpdateFromAPI } = applications

    useEffect(()=>{

        if(needUpdateFromAPI === true){
            getAvalableApplicationsAndVersions()
            .then((response)=>{
                SetApplicationsDescription(response.data)
            })
            .catch((error)=>{
                SetError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : "CANT GET LIST OF APPLICATION'S CONFIG SORTED BY NAME"
                })
                SetErrorNeedToShow(true)
                console.log("CANT GET LIST OF APPLICATION'S CONFIG SORTED BY NAME", JSON.stringify(error))
            })

            SetNeedUpdateApplicationConfigsFromAPI(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[needUpdateFromAPI])
    
    const onDownloadApplicationConfig = ( appName: string, appVersion: string) =>{
        getApplicationConfigByNameAndVersion(
            appName,
            appVersion
        )
        .then((response)=>{
            
            let result:unknown = {}
            if(response.data && response.data.data){
                result = response.data.data
            }else{
                result = response.data
            }
            saveAs(
                new Blob(
                    [JSON.stringify(result)],
                    {type: 'application/json'}
                ),
                `config-${appName}-${appVersion}.json`
            )
        })
        .catch((error)=>{
            SetError({
                status  : error.response.data.status ? String(error.response.data.status) : '',
                path    : error.response.data.path   ? String(error.response.data.path)   : '',
                message : error.response.data.message ? String(error.response.data.message) : "CANT GET LIST OF APPLICATION'S CONFIG SORTED BY NAME"
            })
            SetErrorNeedToShow(true)
            console.log("CANT GET LIST OF APPLICATION'S CONFIG SORTED BY NAME", JSON.stringify(error))
        })
    }



    const onApplicationConfigDelete = (params: TApiDeleteApplicationConfig) =>{
        deleteApplicationConfig({ ...params })
        .then((response)=>{
            SetNeedUpdateApplicationConfigsFromAPI(true)
            
        })
        .catch((error)=>{
            SetError({
                status  : error.response.data.status ? String(error.response.data.status) : '',
                path    : error.response.data.path   ? String(error.response.data.path)   : '',
                message : error.response.data.message ? String(error.response.data.message) : "CANT DELETE APPLICATION CONFIG"
            })
            SetErrorNeedToShow(true)
            console.log("CANT DELETE APPLICATION CONFIG", JSON.stringify(error))
        })
    }

    const onApplicationConfigUpdate = (params: TApiPostCreateUpdateApplicationConfig) =>{
        
        putUpdateApplicationConfig({
            ...params
        }).then((response)=>{
            SetNeedUpdateApplicationConfigsFromAPI(true)
        }).catch((error)=>{
            SetError({
                status  : error.response.data.status ? String(error.response.data.status) : '',
                path    : error.response.data.path   ? String(error.response.data.path)   : '',
                message : error.response.data.message ? String(error.response.data.message) : "CANT CREATE/UPDATE APPLICATION CONFIG"
            })
            SetErrorNeedToShow(true)
            console.log("CANT CREATE/UPDATE APPLICATION CONFIG", JSON.stringify(error))
        })
    }

    return(
        <Box sx={{ margin: '20px 0 0 10px', maxWidth: 'calc( 100% - 10px)' }}>
                <ApplicationFilters />
            <Table aria-label="customized table" size='small' sx={{border: `2px solid ${colorLibrary.backgroundColor}`}}>

                <ConfigTableHeader
                    headerDescription={ applicationTableHeaderDescription }
                    sorting = { {
                        sortingDirection: enumSORT.ASC,
                        sortingField: enumSortingField.ID
                    }}
                    SetNeedUpdateFromAPI = { SetNeedUpdateApplicationConfigsFromAPI }
                />

                <TableBody>
                    {
                        applicationsDescription.length > 0 ?
                            
                            applicationsDescription.map((applicationItem,applicationItemIndex)=>{
                                return(
                                    applicationItem.versions.map((versionItem,versionItemIndex)=>{
                                        return(
                                            <TableRow key={`application-row${applicationItemIndex}-${versionItemIndex}`}>
                                                <TableCell 
                                                    key={`application-1-cell${applicationItemIndex}-${versionItemIndex}`}
                                                    align={'left'}
                                                >
                                                    { applicationItem.name }
                                                </TableCell>
                                                <TableCell 
                                                    key={`application-2-cell${applicationItemIndex}-${versionItemIndex}`}
                                                    align={'right'}
                                                >
                                                    { versionItem }
                                                </TableCell>
                                                <TableCell 
                                                    key={`application-3-cell${applicationItemIndex}-${versionItemIndex}`}
                                                    align={'right'}
                                                >
                                                    <EditConfig
                                                        configType = { enumConfigType.APPLICATION }
                                                        updateData = {{
                                                            application: applicationItem.name,
                                                            appVersion: versionItem,
                                                        }}
                                                        buttonDisable = { false }
                                                        onUpdateConfig = { onApplicationConfigUpdate }
                                                    />

                                                    <Button
                                                        onClick={()=>{
                                                            onDownloadApplicationConfig(applicationItem.name,versionItem)
                                                        }}
                                                        variant={'contained'}
                                                        color={'success'}
                                                        size={'small'}
                                                        sx={{margin: '0 8px 0 8px'}}
                                                    >
                                                        Download
                                                    </Button>
                                                    <Button
                                                        variant={'contained'}
                                                        color={'warning'}
                                                        size={'small'}
                                                        onClick = {()=>{
                                                            onApplicationConfigDelete({
                                                                application: applicationItem.name,
                                                                version: versionItem,
                                                            })
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                )
                            })
                            
                        :
                            <TableRow>
                                <TableCell>
                                    Nothing to show
                                </TableCell>
                            </TableRow>
                    }
                </TableBody>
            </Table>
        </Box>
    )
}

export default ApplicationConfigs
