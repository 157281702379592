import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from "@mui/material"
import { Link } from "react-router-dom"
import { useTypedSelector } from "../../../Store/Hook/use-typed-selector"
import CaseManagementTableHeader from "./CaseManagementTableHeader"
import { tableHeaderDescription } from "./caseManagementTableHeaderDescription"
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import formatDateToLocal from "../../../Utils/format-date-to-local"
import colorLibrary from "../../../Utils/colors"
import VisibilityIcon from '@mui/icons-material/Visibility'

export type TCaseManagementTableProps = {
    setLoading:React.Dispatch<React.SetStateAction<boolean>>
    includeAutoTestFilter: boolean
}

const CaseManagementTable = (props: TCaseManagementTableProps) => {
    const { 
        setLoading,
        includeAutoTestFilter,
    } = props
    const { list } = useTypedSelector(state=>state.caseManagement)


    const tableBodyContent = list
    .filter(item => includeAutoTestFilter === false ? (item.source==='tp-cloud-auto-test' ? false : true ) : true)
    .map((item, index)=>{
        return(
            <TableRow
                hover
                tabIndex={-1}
                key={`case-management-table-row${index}`}
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all 0.2s ease-out',
                    ':nth-child(even)':{
                        backgroundColor: '#f4f4f4',
                        ':hover':{
                            backgroundColor: '#1100ff33',
                        },
                    },
                    ':nth-child(odd)':{
                        ':hover':{
                            backgroundColor: '#1100ff33',
                        },
                    },
                }}
            >
                {
                    tableHeaderDescription.map((headerItem, headerIndex)=>{
                        let  result
                        switch(headerItem.id){
                            case 'id':
                                result = 
                                <Link to={`/case-management/case/${item.id}`}>
                                    { item.id }
                                </Link> 
                                break
                            case 'source':
                                result = item.source
                                if( result === 'itero') result = 'iTero'
                                break

                            case 'externalId':
                                result =
                                <Link to={`/case-management/case/${item.id}`}>
                                    { item.externalId }
                                </Link> 
                                
                                break

                            case 'locked':
                                result = item.locked === true ?
                                        <Tooltip title={`Case is Locked by ${item.lockedBy} at ${formatDateToLocal(item.createdAt)}`}>
                                            <LockIcon sx={{ fill: colorLibrary.backgroundColor }} /> 
                                        </Tooltip>
                                    : 
                                        <Tooltip title={'Unlocked'}>
                                            <LockOpenIcon sx={{ fill: '#ddd' }} />
                                        </Tooltip>
                                break

                            case 'createdBy':
                                result = item.createdBy
                                break

                            case 'createdAt':
                                result = formatDateToLocal(item.createdAt)
                                break
                                
                            case 'updatedAt':
                                result = formatDateToLocal(item.updatedAt)
                                break
                            case 'inspector':
                                result =
                                    <Link to={`/case-management/case/${item.id}/inspector`}>
                                        <VisibilityIcon sx={{fill: '#5700ff'}} />
                                    </Link>
                                break

                            case 'status':
                                result = <span style={{fontSize: '10px'}}>
                                    { item.status }
                                </span>
                                break

                            default:
                                return(null)
                        }

                        return(
                            <TableCell 
                                align={headerItem.align}
                                sx={{
                                    fontSize: '13px',
                                    padding: 0,
                                    margin: 0,
                                }}
                                key={`case-management-table-row${index}-${headerIndex}`}
                            > 
                                { result }
                            </TableCell>
                        )
                    })
                }

            </TableRow>
        )
    })
    

    return(
        <Box 
            sx={{ 
                width: 'calc( 100% - 250px)',
                position: 'relative',
                margin: '10px 0 0 18px'
            }}>

            <Paper sx={{ width: '100%' }}>
                
                <TableContainer
                    sx={{ borderRadius: '4px'}}
                >
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size="small"
                    >

                        <CaseManagementTableHeader 
                            headerDescription={tableHeaderDescription}
                            setLoading={setLoading} 
                        />

                        <TableBody>

                            {
                                tableBodyContent.length === 0 ?
                                    ( 
                                        list.length> 0 && includeAutoTestFilter === false ?
                                            <TableCell align="center">The search result is empty because cases created by <strong>tp-cloud-auto-test</strong> are not shown</TableCell>
                                        :
                                            <TableCell align="center">The result is empty, please change search conditions</TableCell>
                                    )
                                : 
                                    
                                        tableBodyContent
                                    
                                    
                            }

                        </TableBody>
                        
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    )
}

export default CaseManagementTable
