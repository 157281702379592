export enum enumSORT {
    ASC = 'ASC',
    DESC = 'DESC',
}

export type TSort = 'ASC' | 'DESC'

export enum enumApiParameter {
    DIRECTION    = 'direction', 
    PAGE_NUMBER  = 'page.number',
    PAGE_SIZE    = 'page.size',

    LOGIN        = 'login',
    CREATED_BY   = 'createdBy',
    GROUP_ID     = 'groupId',

    ID           = 'id',
    EXTERNAL_ID  = 'externalId',
    NAME         = 'name',
    DESCRIPTION  = 'description',

    APPLICATION  = 'application',
    PERIOD_FROM  = 'period.from',
    PERIOD_TO    = 'period.to',
    DATA         = 'data',
    SOURCE       = 'source',
    CASE_ID      = 'caseId',

    MIME_TYPE    = 'mimeType',
    PATH_IN_CASE = 'pathInCase',
    FILE_NAME    = 'fileName',
    HASH         = 'hash',
    S3_KEY       = 's3key',
    SIZE         = 'size',
    VERSION      = 'version',
    CREATED_AT   = 'createdAt',
    UPDATED_AT   = 'updatedAt',
    
    EVENT        = 'event',
    EXTRA_ATTRIBURES = 'extraAttributes',

    KIND         = 'kind',
    STATUS       = 'status',
    FILE_LINK    = 'fileLink',
    USER         = 'user',      
    ORDER        = 'order',
    SMO_VERSION  = 'smoVersion',

    TAGS         = 'tags',
    PREVIOUS_CASE_ID  = 'previousCaseId'
}

export enum enumSortingField {

    ID          = 'ID',
    EXTERNAL_ID = 'EXTERNAL_ID',
    SOURCE      = 'SOURCE',
    LOGIN       = 'LOGIN',
    CREATED_BY  = 'CREATED_BY',
    CASE_ID     = 'CASE_ID',
    CREATED_AT  = 'CREATED_AT',
    UPDATED_AT  = 'UPDATED_AT',
    VERSION     = 'VERSION',
    LOCKED      = 'LOCKED',
    CONFIG_DATA = 'CONFIG_DATA',
    ACTIONS     = 'ACTIONS',
    GROUP_NAME  = 'GROUP_NAME',
    GROUP_ID    = 'GROUP_ID',
    APPLICATION = 'APPLICATION',
    DESCRIPTION = 'DESCRIPTION',
    ORDER       = 'ORDER',
    INSPECTOR   = 'INSPECTOR',
    STATUS      = 'STATUS',
}
