import { ReactElement, useEffect, useMemo, useState } from "react"
import getCaseHistory from "../../../Api/CaseManagement/v2/get-case-history"
import { THistoryRecord } from "../../../Types/Api/ApiCaseManagementResponse"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { Box } from "@mui/system"
import CaseExtraView from "./CaseExtraView"
import { enumSORT } from "../../../Types/General"
import { useAction } from "../../../Store/Hook/use-action"
import Preloader from "../../../Components/Preloader"
import formatDateToLocal from "../../../Utils/format-date-to-local"
export type TCaseHistoryViewProps = {
    caseId?: string
}

const CaseHistoryView = (props: TCaseHistoryViewProps) =>{
    
    const { caseId } = props
    const [ history, setHistory ] = useState<THistoryRecord[] | undefined >()
    const [ needUpdateFromAPI, setNeedUpdateFromAPI ] = useState(true)
    
    const { setCaseManagementError, setCaseManagementErrorNeedToShow } = useAction()

    useEffect(()=>{
        if(needUpdateFromAPI === true){
            setNeedUpdateFromAPI(false)
            getCaseHistory({
                caseId: caseId ? caseId : '',
                direction: enumSORT.ASC,
                "page.number": 0,
                "page.size"  : 65535
            })
            .then((response)=>{
                setHistory(response.data)
            })
            .catch((error)=>{
                
                setCaseManagementError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : 'CANT GET CASE HISTORY'
                })
                setCaseManagementErrorNeedToShow(true)

                console.log( 'CANT GET CASE HISTORY', JSON.stringify(error))
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[caseId, needUpdateFromAPI])


    const historyList = useMemo(()=>{

        const result:ReactElement[] = []

        if(history){

            for(let i = 0; i<history.length; i++){
    
                result.push(
                    <TableRow
                        hover
                        key={i}
                        sx={{
                            verticalAlign: 'top'
                        }}
                    >
                        <TableCell align="left" width={200}>
                            { formatDateToLocal(history[i].createdAt) }
                        </TableCell>
    
                        <TableCell 
                            align="left"
                        >
                            { history[i].createdBy }
                        </TableCell>
                        
                        <TableCell 
                            align="left"
                        >
                            { history[i].event }
                        </TableCell>
    
                        <TableCell align="left" sx={{fontSize: 10}}>
                            {
                                history[i].extraAttributes ?
                                    <CaseExtraView extra={ Object.entries(history[i].extraAttributes) } />
                                :   
                                    null
                            }
                        </TableCell>
    
                    </TableRow>
                )
            }
        }

        return(result)
    },[history])

    return(

        history === undefined ? 
        
            <Preloader/>

        : (

            history.length === 0 ?
                <div>
                    CASE EVENTS NOT FOUND FOR caseId = { caseId ? caseId : '<null>' }
                </div>
            :
                <Box sx={{ margin: 1 }}>

                    <Table size="small" aria-label="purchases">
                        <TableHead>

                            <TableRow>
                                <TableCell> Created Date     </TableCell>
                                <TableCell> User             </TableCell>
                                <TableCell> Event            </TableCell>
                                <TableCell> Extra Attributes </TableCell>
                            </TableRow>

                        </TableHead>

                        <TableBody>
                            {
                                historyList
                            }
                        </TableBody>
                    </Table>
                </Box>
        )

    )
}

export default CaseHistoryView
