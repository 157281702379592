import { Button } from "@mui/material"
import { useTypedSelector } from "../../../../Store/Hook/use-typed-selector"
import { TApiPostCreateUpdateGroupConfig, TApiPostCreateUpdateUserConfig } from "../../../../Types/Api/ApiDesktopConfigRequest"
import { IDesktopApplicationConfig, IDesktopGroupConfig, IDesktopUserConfig } from "../../../../Types/Store/StoreDesktopConfig"
import colorLibrary from "../../../../Utils/colors"
import { enumConfigType } from "../Dialogs/CreateConfig"
import { TToolbarData } from "../Tables/UserConfigsTable"
import EditConfig from "../Dialogs/EditConfig"

export type TUserListSelectionToolsProps = {
    checkedRow?: boolean[]
    onDownload?: ( itemIndex: number, configs:IDesktopUserConfig[] | IDesktopGroupConfig[] | IDesktopApplicationConfig[]) => void
    data       : TToolbarData
    onUpdateUsers?  : ( params:TApiPostCreateUpdateUserConfig )=>void
    onUpdateGroups?  : ( params:TApiPostCreateUpdateGroupConfig )=>void
    onDelete?  : ( checkedRows: boolean[] ) => void
}

const MultiplySelectionTool = (props:TUserListSelectionToolsProps) => {

    const {
        checkedRow,
        onDownload,
        onUpdateUsers,
        onUpdateGroups,
        onDelete,
        data,
    } = props

    const {
        config,
        configsFor
    } = data

    const configUser = configsFor === enumConfigType.USER ?  config as IDesktopUserConfig[] : undefined
    const configGroup = configsFor === enumConfigType.GROUP ?  config as IDesktopGroupConfig[] : undefined

    const {
        users, groups
    } = useTypedSelector(store => store.desktopConfigurations)

    let ToolbarContent = <></>
    let selectedRowsCount = 0

    if(checkedRow){
        selectedRowsCount = checkedRow?.filter(item => item).length
        if(selectedRowsCount > 0){
            
            let checkedIndex = checkedRow.indexOf(true)

            ToolbarContent = 
            <>
                <div
                    style={{
                        display: 'flex'
                    }}
                >

                    <Button
                        disabled = {selectedRowsCount === 1 ? false : true}
                        size="small"
                        variant="contained"
                        color="inherit"
                        sx={{margin: '14px 4px 14px 14px'}}
                        onClick={
                                ()=>{
                                    if(onDownload&&data && (selectedRowsCount === 1)){
                                        let checkedIndex = checkedRow.indexOf(true)
                                        if(checkedIndex>-1){
                                            onDownload(checkedIndex, config)
                                        }
                                    }
                                }
                        }
                    >
                        Download
                    </Button>
                    {
                        (onUpdateUsers || onUpdateGroups) && data && checkedRow ? 
                            (
                                configsFor === enumConfigType.USER && configUser && onUpdateUsers ?

                                    <EditConfig
                                        buttonDisable  = { selectedRowsCount === 1 ? false : true }
                                        onUpdateConfig = { onUpdateUsers                          }
                                        configType     = { configsFor                             }
                                        updateData     = {{
                                            application : users.selectedApplicationVersion?.name,
                                            appVersion  : users.selectedApplicationVersion?.version,
                                            login       : configUser[checkedIndex].login,
                                            configJson  : configUser[checkedIndex].data
                                        }}
                                    />

                                :
                                    (   
                                        configsFor === enumConfigType.GROUP && configGroup && onUpdateGroups?

                                            <EditConfig
                                                buttonDisable  = { selectedRowsCount === 1 ? false : true }
                                                onUpdateConfig = { onUpdateGroups                         }
                                                configType     = { enumConfigType.GROUP                   }
                                                updateData     = {{
                                                    application : groups.selectedApplicationVersion?.name,
                                                    appVersion  : groups.selectedApplicationVersion?.version,
                                                    groupId     : configGroup[checkedIndex].groupId,
                                                    configJson  : configGroup[checkedIndex].data
                                                }}
                                            />
                                        :
                                            null
                                    )
                            )
                        :
                            null
                    }
                </div>

                    <Button
                        size="small"
                        variant="contained"
                        color="warning"
                        sx={{margin: '14px 14px 14px 0'}}
                        onClick={ ()=>{
                            if(onDelete&&checkedRow){
                                onDelete(checkedRow)
                            }
                        } }
                    >
                        DELETE
                    </Button>
            </>
            
        }
    }

    return(
        selectedRowsCount !== 0 ?
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        position: 'absolute',
                        width: '100%',
                        height: 57,
                        backgroundColor: colorLibrary.backgroundColor,
                        zIndex: 1
                    }}
                >
                    { ToolbarContent }
                </div>
            </div>
        :
                null
    )
}

export default MultiplySelectionTool
