import { AxiosResponse } from "axios"
import API from '../api-base'
import { enumApiParameter } from "../../../Types/General"
import { TApiPostCreateUpdateUserConfig } from "../../../Types/Api/ApiDesktopConfigRequest"

// https://desktop-configuration-api.tp-dev-stable.smiledirect.services/swagger-ui/index.html?urls.primaryName=Version%202#/User%20configuration%20API/create

const postCreateUserConfig = async ( params: TApiPostCreateUpdateUserConfig ): Promise<AxiosResponse>  =>{
    return await API.post(
        `/v2/configurations/users/${String(params[enumApiParameter.APPLICATION])}/${params[enumApiParameter.VERSION]}/${params[enumApiParameter.LOGIN]}`,
        params.data
    )
}

export default postCreateUserConfig
