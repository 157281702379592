import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiGetCaseByCaseId      } from "../../../Types/Api/ApiCaseManagementRequest"
import { TApiGetCommentsResponse } from "../../../Types/Api/ApiCaseManagementResponse"

// https://smiledirectclub.atlassian.net/browse/TPW-975
// https://case-management-api.tp-dev-stable.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Comment%20API/getByCaseId_1

const getCommentsByCaseId = async ( requestParams: TApiGetCaseByCaseId ): Promise<AxiosResponse<TApiGetCommentsResponse>>  =>{
    return await API.get(
        `/v2/cases/${requestParams.caseId}/comments`
    )
}

export default getCommentsByCaseId
