import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiGetFileListByCaseId } from "../../../Types/Api/ApiCaseManagementRequest"
import { TApiGetFileListByCaseIdResponse } from "../../../Types/Api/ApiCaseManagementResponse"
import { enumApiParameter } from "../../../Types/General"

const getFileListByCaseId = async ( requestParams: TApiGetFileListByCaseId ): Promise<AxiosResponse<TApiGetFileListByCaseIdResponse>>  =>{
    return await API.get(
        `/v2/cases/${requestParams[enumApiParameter.ID]}/files`,
    )
}

export default getFileListByCaseId
