import { combineReducers                  } from 'redux'
import { baseReducer                      } from './reducerBase'
import { groupsReducer                    } from './reducerGroups'
import { desktopConfigsReducer            } from './reducerDesktopConfigurations'
import { caseManagementReducer            } from './reducerCaseManagement'
import { caseManagementUserHistoryReducer } from './reducerCaseManagementUserHisttory'
import { workflowReducer                  } from './reducerWorkflow'
import { mtpReducer                       } from './reducerMobileTreatmentPlan'

const rootReducer = combineReducers({

    base                      : baseReducer, 
    groups                    : groupsReducer,
    desktopConfigurations     : desktopConfigsReducer,
    caseManagement            : caseManagementReducer,
    caseManagementUserHistory : caseManagementUserHistoryReducer,
    workflow                  : workflowReducer,
    mtp                       : mtpReducer,

})

export default rootReducer
