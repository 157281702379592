import { AxiosResponse } from "axios"
import { TApiPostWorkflowRuledelete } from "../../Types/Api/ApiWorkflowRequest"
import API from './api-base'

// https://tp-workflow-api.tp-qa.smiledirect.services/swagger-ui/index.html#/Rules%20API/delete

const deleteRule = async ( params: TApiPostWorkflowRuledelete ): Promise<AxiosResponse>  =>{
    
    const { ruleId } = params

    return await API.delete(
        `/rules/${ ruleId }`
    )
}

export default deleteRule
