import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiGetCaseByCaseId      } from "../../../Types/Api/ApiCaseManagementRequest"
import { TCase } from "../../../Types/Store/StoreCaseManagement"

// https://case-management-api.tp-qa.smiledirect.services/swagger-ui/index.html?urls.primaryName=CaseManagement%20service%20v2#/Cases%20API/getByCaseId

const getCaseByCaseId = async ( requestParams: TApiGetCaseByCaseId ): Promise<AxiosResponse<TCase>>  =>{
    return await API.get(
        
        `/v2/cases/${ requestParams.caseId }`
    )
}

export default getCaseByCaseId
