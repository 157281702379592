import { useMemo } from 'react' 
import { Box, SxProps, Theme, Typography } from "@mui/material"
import { TNode } from "./CaseFiles"
import colorLibrary from '../../../../Utils/colors'
import CaseFileTreeActionInfo from './FilesTreeNodeActionInfo'
import formatBytes from '../../../../Utils/format-bytes'
import { getColorByStatus } from './Utils/getColorByStatus'
import { getIconByFiledescritpion } from './Utils/getIconByFiledescritpion'
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';

type TFilesTreeNodeProps = {
    node: TNode
    needUpdateFileTree: ()=>void
}

export const nodeStyle = {
    [`.${treeItemClasses.content}.Mui-selected`]:{
        backgroundColor: '#0000ff33',
    },
    [`.${treeItemClasses.content}.Mui-selected.Mui-focused`]:{
        backgroundColor: '#0000ff33',
    },
}

const FilesTreeNode = (props:TFilesTreeNodeProps) =>{
    const { node, needUpdateFileTree } = props

    const fileIcon = useMemo(()=>{

        let fillColor  = getColorByStatus(node)
        if(node.child.length === 0 && node.id.indexOf('FOLDER|')>-1){
            fillColor = '#f00'
        }
        return(
            <Box 
                component = { getIconByFiledescritpion(node) } 
                sx={{ 
                    mr: 1, 
                    fill: fillColor ? fillColor : colorLibrary.backgroundColor
                }} 
            />
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[node])

    const fileActions = useMemo(()=>{
        const { fileDescription } = node 
        
        const wrapperStyle:SxProps<Theme> = {
            display: 'flex',
            alignItems: 'center',
            margin: '0 0 0 20px',
        }

        if(fileDescription){
            if(fileDescription.status === 'PENDING' || fileDescription.status === 'UPLOAD_FAILED'){ // 'UPLOADED' / 'PENDING'
                return(
                    <Box sx={wrapperStyle}>
                        <CaseFileTreeActionInfo 
                            fileDescription={node}
                        />
                    </Box>
                )
            } 
        }

        return(
            <Box sx={wrapperStyle}>
                <CaseFileTreeActionInfo fileDescription={node}/>
                {
                    node.fileDescription &&
                    <Box sx={{ fontSize: '12px' }}>
                        { formatBytes( node.fileDescription.size) }
                    </Box>
                }
            </Box>
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[node, node.fileDescription])

    return(
        node.child.length > 0 ?
        
            // FOLDER WITH CHILDS
            <TreeItem
                nodeId = { node.id }
                label  = {
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        { fileIcon }

                        <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1, fontSize: '13px' }}>
                            {node.name}
                        </Typography>
                    </Box>
                }
                sx={nodeStyle}
            >
                {
                    node.child.map((childNodeItem)=>{
                        return(
                            <FilesTreeNode
                                key  = { childNodeItem.id }
                                node = { childNodeItem    }
                                needUpdateFileTree={ needUpdateFileTree }
                            />
                        )
                    })
                }
            </TreeItem>

        :
            (
                // FILE
                node.fileDescription ? 
                    <TreeItem
                        nodeId = { node.id }
                        label  = {
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                { fileIcon }

                                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 0 , fontSize: '13px'}}>
                                    {node.name}
                                </Typography>

                                { fileActions }
                            </Box>
                        }
                        sx={nodeStyle}
                    >
                    </TreeItem>
                :
                    // EMPTY FOLDER (NEW)
                    <TreeItem
                        nodeId = { node.id }
                        label  = {
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                { fileIcon }

                                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1, fontSize: '13px' }}>
                                    {node.name}
                                </Typography>
                            </Box>
                        }
                        sx={nodeStyle}
                    >
                    </TreeItem>
            )
            

        
    )
}

export default FilesTreeNode
