import { AxiosResponse } from "axios"
import API from '../api-base'
import { enumApiParameter } from "../../../Types/General"
import { TApiGetListOfUserConfigSortByLoginV2         } from "../../../Types/Api/ApiDesktopConfigRequest"
import { TApiGetListOfUserConfigSortByLoginResponse } from "../../../Types/Api/ApiDesktopConfigResponse"

// https://adalisk.stoplight.io/docs/tp-api/branches/main/2e96a04dcdf06-get-list-of-user-config-sort-by-login

const getListOfUserConfigSortByLoginV2 = async ( params: TApiGetListOfUserConfigSortByLoginV2 ): Promise<AxiosResponse<TApiGetListOfUserConfigSortByLoginResponse>>  =>{
    const requestParams: TApiGetListOfUserConfigSortByLoginV2= {...params}
    
    delete requestParams[enumApiParameter.APPLICATION]
    delete requestParams[enumApiParameter.VERSION]

    for (const [key, value] of Object.entries(requestParams)){
        if(key === enumApiParameter.DIRECTION && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.DIRECTION]
        }
        if(key === enumApiParameter.LOGIN && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.LOGIN]
        }
        if(key === enumApiParameter.PAGE_NUMBER && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.PAGE_NUMBER]
        }
        if(key === enumApiParameter.PAGE_SIZE && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.PAGE_SIZE]
        }
    }

    return await API.get(
        `/v2/configurations/users/${String(params.application).toLowerCase()}/${String(params.version).toLowerCase()}/by-login`,
        { 
            params:{ ...requestParams} 
        }
    )
}

export default getListOfUserConfigSortByLoginV2
