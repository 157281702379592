import { AxiosResponse } from "axios"
import { TApiPutWorkflowRuleUpdate } from "../../Types/Api/ApiWorkflowRequest"
import API from './api-base'

// https://tp-workflow-api.tp-qa.smiledirect.services/swagger-ui/index.html#/Rules%20API/update

const putRuleUpdate = async ( params: TApiPutWorkflowRuleUpdate ): Promise<AxiosResponse>  =>{

    const data = {
        name: params.name,
        order: params.order,
        enabled: params.enabled,
        condition: params.condition,
        actions: params.actions,
        version: params.version
    }

    return await API.put(
        `/rules/${ params.id }`,
        data
    )
}

export default putRuleUpdate
