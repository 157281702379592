import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiGetFileListByCaseId } from "../../../Types/Api/ApiCaseManagementRequest"
import { TApiGetFileLinksByCaseIdResponse } from "../../../Types/Api/ApiCaseManagementResponse"
import { enumApiParameter } from "../../../Types/General"

const getFileLinksByCaseId = async ( requestParams: TApiGetFileListByCaseId ): Promise<AxiosResponse<TApiGetFileLinksByCaseIdResponse>>  =>{
    return await API.get(
        `/v2/cases/${requestParams[enumApiParameter.ID]}/file-links`,
        {
            params:{
                [enumApiParameter.PAGE_SIZE]: 100,
            }
        }
    )
}

export default getFileLinksByCaseId
