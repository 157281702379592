import { Box, Button, Input } from "@mui/material"
import { ChangeEvent } from "react"
import postCreateUrlForUploadingFileToCase from "../../../../Api/CaseManagement/v2/post-create-url-for-uploading-file"
import axios from "axios"
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import { toolbarButtonStyle } from "./FilesToolbar"

type TFilesToolbarActionUploadFileProps = {
    setFileUploaded   : ( isFileUploaded   : boolean) => void
    setUploadProgress : ( progressInPercent: number ) => void
    setFileListNotLoaded : ( ) => void
    isFileUploaded    : boolean
    uploadProgress    : number
    pathInCase        : string[]
    caseId            : string
}


const FilesToolbarActionUploadFile = (props:TFilesToolbarActionUploadFileProps) =>{
    const { 
        setFileUploaded,
        setUploadProgress,
        setFileListNotLoaded,
        isFileUploaded,
        uploadProgress,
        caseId,
        pathInCase
    } = props

    const onInputChange = async (e: ChangeEvent<HTMLInputElement>) =>{
        e.preventDefault()
        const { files } = e.target 
        if(files && files.length>0){
            
            let path = ''
            if(pathInCase.length>0){
                const splitted = pathInCase[0].split('/')
                const cleared = splitted.slice(1,splitted.length)
                path = cleared.join('/')
            }
            const file = files[0] 
            setFileUploaded(false)
            postCreateUrlForUploadingFileToCase({
                id: caseId ? caseId : '',
                fileName: file.name,
                mimeType: file.type,
                pathInCase: `${path === '' ? '/' : path }`,
            })
            .then((response)=>{

                axios({
                    method: 'put',
                    url: response.data.fileLink,
                    data: file,
                    headers:{
                        'content-Type' : file.type,
                        //'x-amz-meta-uuid': response.data.uuid // Is it depricated? AWS api return undefined
                    },
                    onUploadProgress: (e) =>{
                        setUploadProgress( Math.floor(100*e.loaded/e.total))
                    }
                })
                .then((awsResponse)=>{
                    
                    if(awsResponse.status === 200){
                        setFileUploaded(true)
                        setTimeout(()=>{ setFileListNotLoaded()}, 2000 )
                        setTimeout(()=>{ setFileUploaded(false)}, 5000 )
                    }
                })
                .catch((e)=>{
                    console.log('CANT UPLOAD FILE TO SERVER', e)
                })
                return response.data
            })
            .catch((error)=>{
                console.log('CANT GET URL FOR CASE FILE UPLOADING', JSON.stringify(error))
            })
        }
    }

    return(
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end'
            }}
            
        >   
            <label htmlFor="contained-button-file">
                <Input id="contained-button-file" type="file" sx={{display: 'none'}} onChange={onInputChange}/>
                <Button size ='small' variant="contained" component="span" sx={{...toolbarButtonStyle, height: '30px'}}
                    
                >
                    
                    <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                        <NoteAddIcon/>
                        <div>
                            {
                                uploadProgress > -1 && uploadProgress < 100 ? 
                                `Upload ${uploadProgress}%`
                                :
                                'Upload'
                            }
                        </div>

                        {
                            isFileUploaded ?
                                <div style={{
                                    margin: '0 0 8px 14px',
                                    width: 12,
                                    height: 18,
                                    borderBottom: '3px solid #86ff00',
                                    borderRight: '3px solid #86ff00',
                                    transform: 'rotate(45deg)',
                                }}/>
                            : null
                        }
                    </Box>
                    
                </Button>
            </label>
        </Box>
    )
}

export default FilesToolbarActionUploadFile
