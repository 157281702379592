import { Box, Button } from "@mui/material"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import getListActions from "../../../Api/Workflow/api-get-list-actions"
import { useAction } from "../../../Store/Hook/use-action"
import { TWorkflowRuleAction } from "../../../Types/Store/StoreWorkflow"

import ActionAttributeViewer from "./ActionAttrubuteViewer"
import ActionCreator from "./ActionCreator"
import ActionEditor from "./ActionEditor"

export type TActionViewerProps = {
    actionList: TWorkflowRuleAction[],
    setActions?: Dispatch<SetStateAction<TWorkflowRuleAction[]>>
}


const style={
    width: '100%',
    border: '1px solid #ccc',
    padding: '10px 20px 10px 24px',
}


export type TEditActionDescription = {
    index: number

}

const ActionViewer = (props:TActionViewerProps) =>{

    const { actionList, setActions } = props
    const [ editActionIndex, setEditActionIndex ] = useState<number>(-100)
    const [ avalableActionsTemplate, setAvalableActionsTemplate ] = useState<TWorkflowRuleAction[]>([])
    const [ isNeedUpdateTemplates, setNeedUpdateTemplates] = useState(true)
    const { setWorkflowError } = useAction()

    useEffect(()=>{
        if(isNeedUpdateTemplates === true){
            getListActions()
            .then((response)=>{
                console.log(response)
                setAvalableActionsTemplate(response.data)
            })
            .catch((error)=>{
                setWorkflowError({
                    needToShow: true,
                    message: error.response.data.message,
                    path: error.response.data.path,
                    status: error.response.data.status
                })
            })
            setNeedUpdateTemplates(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedUpdateTemplates])

    return(
        <>
            {

                actionList.length === 0 ?
                    <Box sx={style}>
                        Empty action list
                    </Box>
                :
                    <Box sx={style}>
                        {
                            actionList.map((actionItem, actionItemIndex)=>{
                                
                                return(
                                    <Box 
                                        key={`action-item-${actionItemIndex}`}
                                        sx={{
                                            padding: '0 8px 0 18px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                            alignItems:'flex-start',
                                            margin: '4px 0 0 0'
                                        }}
                                    >
                                        <Box 
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                            }}
                                        >

                                            <Box
                                                sx={{
                                                    backgroundColor: '#f3f3f3',
                                                    padding: ' 6px 10px 6px 10px',
                                                }}
                                            >
                                                { actionItem.type }
                                            </Box>

                                            <ActionAttributeViewer attributes={actionItem.attributes} />

                                            { 
                                                !(editActionIndex > -1 && actionItemIndex === editActionIndex) ?

                                                    <>
                                                        <Button
                                                            variant="outlined" 
                                                            color  ='error' 
                                                            size   ='small'
                                                            sx={{
                                                                margin: '0 10px 0 0'
                                                            }}
                                                            onClick={()=>{ 
                                                                if(setActions){
                                                                    setActions((prevState)=>{
                                                                        return([
                                                                            ...prevState.slice(0,actionItemIndex),
                                                                            ...prevState.slice(actionItemIndex+1, prevState.length),
                                                                        ])
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            Delete
                                                        </Button>

                                                        <Button
                                                            variant="outlined" 
                                                            color  ='primary' 
                                                            size   ='small'
                                                            onClick={()=>{ setEditActionIndex(actionItemIndex) }}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </>

                                                :null
                                            }

                                            

                                        </Box>


                                        <ActionEditor 
                                            isVisible               = { editActionIndex > -1 && actionItemIndex === editActionIndex  }
                                            setInvisible            = { ()=>{setEditActionIndex(-100)}                               }
                                            actionItemIndex         = { actionItemIndex                                              }
                                            actionList              = { actionList                                                   }
                                            setActionList           = { setActions                                                   }
                                            avalableActionsTemplate = { avalableActionsTemplate                                      }
                                        />

                                    </Box>
                                )
                            })
                        }
                    </Box>
            }

            
            <Box 
                key={`action-item-last`}
                sx={{
                    margin: '4px 0 0 0',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }}
            >
                {
                    !(editActionIndex === -1) ?  
                    <Button
                        variant="outlined" 
                        color  ='primary' 
                        size   ='small'
                        onClick={()=>{ setEditActionIndex(-1) }}
                    >
                        ADD NEW
                    </Button>
                    :null
                }
            
                
                <ActionCreator
                    isVisible               = { editActionIndex === -1  }
                    setInvisible            = {()=>{setEditActionIndex(-100)}}
                    setActionList           = { setActions }
                    avalableActionsTemplate = { avalableActionsTemplate                                      }
                />
                
            </Box>
        </>

    )
}

export default ActionViewer
