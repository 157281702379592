
import { TSorting } from "../../Pages/DesktopConfigurations/TabsComponents/Tables/ConfigTableHeader"
import { enumSORT } from "../General" 

export enum enumStoreGroupsAction {
    SET_NEED_UPDATE_GROUPS_FROM_API = 'SET_NEED_UPDATE_GROUPS_FROM_API',
    SET_NEED_UPDATE_USERS_FROM_API  = 'SET_NEED_UPDATE_USERS_FROM_API',
    SET_GROUPS_FROM_API             = 'SET_GROUPS_FROM_API',
    SET_CURRENT_GROUP_FROM_API      = 'SET_CURRENT_GROUP_FROM_API',
    SET_CURRENT_USERS_FROM_API      = 'SET_CURRENT_USERS_FROM_API',
    SET_GROUPS_SORTING              = 'SET_GROUPS_SORTING',
    SET_CURRENT_USERS_PAGINATION    = 'SET_CURRENT_USERS_PAGINATION',
    SET_GROUPS_PAGINATION           = 'SET_GROUPS_PAGINATION',
    SET_CURRENT_USER__FROM_API      = 'SET_CURRENT_USER__FROM_API',
    SET_CURRENT_GROUP__FROM_API     = 'SET_CURRENT_GROUP__FROM_API',

    SET_GR_ERROR_NEED_TO_SHOW         = 'SET_GR_ERROR_NEED_TO_SHOW',
    SET_GR_ERROR                      = 'SET_GR_ERROR',
}

export type User = {
    login: string
    addedAt: string
    addedBy: string
}

export type UserGroup = {
    id: string,
    name: string,
    description: string,
    version: number,
    createdAt: string,
    updatedAt: string,
}

export interface IStoreGroups {
    needUpdateDataFromAPI: boolean
    list: UserGroup[]
    pagination:{
        totalElements: number
        size  : number
        number: number
    }
    sorting: enumSORT
    current:{
        needUpdateGroupFromAPI: boolean
        group: UserGroup | undefined 
        user : User | undefined
        users: User[]
        usersPagination:{
            needUpdateDataFromAPI: boolean,
            userSorting: enumSORT
            totalElements: number
            size: number
            number: number
        }
    }
    error:{
        needToShow: boolean
        status    : string
        message   : string
        path      : string
    },
}

export type TGroupStoreAction = TGroupActionSetNeedUpdateGroupsFromAPI | 
                                TSetCurrentGroupFromAPIAction |
                                TSetGroupsFromAPIAction | 
                                TSetGroupsSortingAction |
                                TSetCurrentUsersFromAPIAction|
                                TSetCurrenUserePaginationAction |
                                TSetNeedUpdateUsersFromApiAction |
                                TSetGroupsPaginationAction|
                                TSetNeedUpdateCurrentGroupFromApiAction |
                                TUserGroupsSetError |
                                TUserGroupsSetErrorNeedToShow


export type TGroupActionSetNeedUpdateGroupsFromAPI = {
    type: enumStoreGroupsAction.SET_NEED_UPDATE_GROUPS_FROM_API
    payload: boolean
}

export type TSetGroupsFromAPIActionPayload = {
    groups: UserGroup[],
    pagination: {
        totalElements: number
        size: number
        number: number
    }
} 

export type TSetGroupsFromAPIAction = {
    type: enumStoreGroupsAction.SET_GROUPS_FROM_API
    payload: TSetGroupsFromAPIActionPayload
}
export type TSetGroupsSortingAction = {
    type: enumStoreGroupsAction.SET_GROUPS_SORTING,
    payload: TSorting
}

export type TSetCurrentGroupFromAPIAction = {
    type: enumStoreGroupsAction.SET_CURRENT_GROUP_FROM_API
    payload: UserGroup | undefined
}

export type TStoreCurrentUsers = {
    users: User[]
    usersPagination:{
        needUpdateDataFromAPI: boolean,
        userSorting: enumSORT
        totalElements: number
        size: number
        number: number
    }
}



export type TSetCurrentUsersFromAPIAction = {
    type: enumStoreGroupsAction.SET_CURRENT_USERS_FROM_API
    payload: TStoreCurrentUsers
}

export type TSetCurrenUserePaginationAction = {
    type: enumStoreGroupsAction.SET_CURRENT_USERS_PAGINATION
    payload: number
}

export type TSetGroupsPaginationAction = {
    type: enumStoreGroupsAction.SET_GROUPS_PAGINATION
    payload: number
}

export type TSetNeedUpdateUsersFromApiAction = {
    type: enumStoreGroupsAction.SET_NEED_UPDATE_USERS_FROM_API
    payload: boolean
}

export type TSetCurrentUserFromApiAction = {
    type: enumStoreGroupsAction.SET_CURRENT_USER__FROM_API
    payload: User
}

export type TSetNeedUpdateCurrentGroupFromApiAction = {
    type: enumStoreGroupsAction.SET_CURRENT_GROUP__FROM_API
    payload: boolean
}

export type TUserGroupsSetErrorPayload = {
    status    : string
    message   : string
    path      : string
}

export type TUserGroupsSetError = {
    type: enumStoreGroupsAction.SET_GR_ERROR 
    payload: TUserGroupsSetErrorPayload
}

export type TUserGroupsSetErrorNeedToShow = {
    type: enumStoreGroupsAction.SET_GR_ERROR_NEED_TO_SHOW 
    payload: boolean
}

