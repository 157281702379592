import { TreeItem, TreeItemProps } from "@mui/lab"
import { Box } from "@mui/material"
import { TMTPTreeFileDescription } from "./getFileTree"
import MTPThreeView from "./MTPThreeView"
import FolderIcon from '@mui/icons-material/Folder'
import colorLibrary from "../../../Utils/colors"

type TMTPThreeViewFolderProps = TreeItemProps & {
    child: TMTPTreeFileDescription[]
}

const MTPThreeViewFolder = (props:TMTPThreeViewFolderProps) =>{
    
    const { label, child } = props
    const salt = Date.now().toString()

    return(
        <TreeItem {...props}
            label = {
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '13px',
                    }}
                >
                    <Box 
                        component = { FolderIcon } 
                        sx={{ 
                            mr: 1, 
                            fill: colorLibrary.backgroundColor
                        }} 
                    />
                    { label } 
                </Box>
            }
        >
            {
                child.map(( childItem, childItemIndex )=>{
                    return(
                        <MTPThreeView
                            key={ 'fileTree' + salt + childItemIndex}
                            child={ [childItem] }
                        />
                    )
                })
            }
        </TreeItem>
    )
}

export default MTPThreeViewFolder
