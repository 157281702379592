import { useEffect, Dispatch, SetStateAction, useState } from 'react'
import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useAction } from "../../../Store/Hook/use-action"
import useValidation, { enumFormValidationRule } from "../../formValidator"
import useDebounce from '../../../Utils/use-input-debounce'
import { enumSORT, enumSortingField } from '../../../Types/General'
import {
    Search as SearchIcon,
    Cancel as CancelIcon 
} from '@mui/icons-material'

import { DateRangePicker } from 'rsuite'
import "rsuite/dist/rsuite.min.css"
import { DateRange } from 'rsuite/esm/DateRangePicker'
import { getDateInputTemplate } from '../../../Utils/format-date-to-local'
import isAfter from 'date-fns/isAfter'
import FilterPanelCSVExporter from './FilterPanelCSVExporter'
import { useTypedSelector } from '../../../Store/Hook/use-typed-selector'
import { useSearchParams } from 'react-router-dom'
import { TCaseManagementSortingBy } from '../../../Types/Store/StoreCaseManagement'

import deepEqual from '../../../Utils/compare-objects'
import colorLibrary from '../../../Utils/colors'
import adjustDateRange from '../../../Utils/adjust-date-range'
import CaseManagementPaginaton from './CaseManagementPaginaton'
import { Checkbox } from '@mui/joy'
import useCaseStatus from './useCaseStatus'

export type TFilterPanelProps = {
    setLoading: Dispatch<SetStateAction<boolean>>
    casesListLength  : number
    includeAutoTestFilter: boolean
    setIncludeAutoTestFilter: React.Dispatch<React.SetStateAction<boolean>>
}

const textFieldStyle = {
    padding: '8px',
    'label':{
        margin: '8px 0 0 8px',
        fontSize: '12px',
    },
    'input':{
        fontSize: '12px'
    }
}

const FilterPanel = (props: TFilterPanelProps) => {
    
    let [ searchParams, setSearchParams ] = useSearchParams()

    const { filterData } = useTypedSelector(state => state.caseManagement)

    const { 
        setLoading, 
        casesListLength,

        includeAutoTestFilter,
        setIncludeAutoTestFilter,
    } = props
    const [ isStickyEnabled, setStikyEnabled    ] = useState(true)

    const caseStatuses = useCaseStatus()

    const { 
        setNeedUpdateCaseManagementFromAPI,
        setCaseManagementFilterData,
        setCaseManagementPageNumber,
        setCaseManagementPageSize,
    } = useAction()

    const [
        filterCaseId,
        isFilterCaseIdValid,
        ,
        onFilterCaseIdChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED,enumFormValidationRule.UUID], 
            searchParams.get('cms_id') !== null ? 
                String(searchParams.get('cms_id')) 
            : ''
        )

    const [
        source,
        isSourceValid,
        ,
        onSourceChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED],
            searchParams.get('source') !== null ? 
                String(searchParams.get('source')) 
            : ''
        )

    const [
        externalId,
        isExternalIdValid,
        ,
        onExternalIdChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED],
            searchParams.get('external_id') !== null ?
                String(searchParams.get('external_id'))
            : ''
        )
    
    const [
        locked,
        isLockedValid,
        ,
        onLockedChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED], 
            searchParams.get('locked') !== null ?
                String(searchParams.get('locked'))
            : ''
        )

    const [
        createdBy,
        isCreatedByValid,
        ,
        onCreatedByChange
    ] = useValidation(
            [enumFormValidationRule.EMAIL],
            searchParams.get('created_by') !== null ?
                String(searchParams.get('created_by'))
            : ''
        )
    
    const [
        createdDate,
        ,
        ,
        onCreatedDateChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED],
            searchParams.get('created_date') !== null ?
                String(searchParams.get('created_date'))
            : ''
        )

    const [
        updatedDate,
        ,
        ,
        onUpdatedDateChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED],
            searchParams.get('updated_date') !== null ?
                String(searchParams.get('updated_date'))
            : ''
        )

    const [
        status,
        ,
        ,
        onStatusChange
    ] = useValidation(
            [enumFormValidationRule.REQUIRED],
            searchParams.get('status') !== null ?
                String(searchParams.get('status'))
            : ''
        )

    const debouncedFilterCaseId = useDebounce(filterCaseId, 500)
    const debouncedSource       = useDebounce(source      , 500)
    const debouncedExternalId   = useDebounce(externalId  , 500)
    const debouncedLocked       = useDebounce(locked      , 500)
    const debouncedCreatedBy    = useDebounce(createdBy   , 500)
    const debouncedCreatedDate  = useDebounce(createdDate , 500)
    const debouncedUpdatedDate  = useDebounce(updatedDate , 500)
    const debouncedStatus       = useDebounce(status      ,500)
    
    const onResize = ()=>{  
        setStikyEnabled( document.getElementById('root')?.clientWidth === document.getElementById('root')?.scrollWidth )
    }

    useEffect(()=>{
        window.addEventListener('resize', onResize )
        window.addEventListener('focus' , onResize )
        
        return(()=>{
            window.removeEventListener('resize', onResize)
            window.removeEventListener('focus', onResize)
        })
    },[])

    useEffect(()=>{

        if(isFilterCaseIdValid === true && isCreatedByValid === true){

            const filterDataNew  = {
                id              : debouncedFilterCaseId ? debouncedFilterCaseId              : undefined,
                source          : debouncedSource       ? debouncedSource                    : undefined,
                externalId      : debouncedExternalId   ? debouncedExternalId                : undefined,
                locked          : debouncedLocked       ? debouncedLocked                    : undefined,
                createdBy       : debouncedCreatedBy    ? debouncedCreatedBy                 : undefined,
                dateCreatedFrom : debouncedCreatedDate  ? debouncedCreatedDate.split('|')[0] : undefined,
                dateCreatedTo   : debouncedCreatedDate  ? debouncedCreatedDate.split('|')[1] : undefined,
                dateUpdatedFrom : debouncedUpdatedDate  ? debouncedUpdatedDate.split('|')[0] : undefined,
                dateUpdatedTo   : debouncedUpdatedDate  ? debouncedUpdatedDate.split('|')[1] : undefined,
                status          : debouncedStatus       ? debouncedStatus                    : undefined,
            }

            const filterDataPrevious = {...filterData }
            delete filterDataPrevious['page.number']
            delete filterDataPrevious['page.size']
            delete filterDataPrevious['direction']
            delete filterDataPrevious['sortBy']

            setCaseManagementFilterData({
                "page.number"   : deepEqual(filterDataNew,filterDataPrevious) === true ? filterData['page.number'] : 0,
                id              : debouncedFilterCaseId ? debouncedFilterCaseId              : undefined,
                source          : debouncedSource       ? debouncedSource                    : undefined,
                externalId      : debouncedExternalId   ? debouncedExternalId                : undefined,
                locked          : debouncedLocked       ? debouncedLocked                    : undefined,
                createdBy       : debouncedCreatedBy    ? debouncedCreatedBy                 : undefined,
                dateCreatedFrom : debouncedCreatedDate  ? debouncedCreatedDate.split('|')[0] : undefined,
                dateCreatedTo   : debouncedCreatedDate  ? debouncedCreatedDate.split('|')[1] : undefined,
                dateUpdatedFrom : debouncedUpdatedDate  ? debouncedUpdatedDate.split('|')[0] : undefined,
                dateUpdatedTo   : debouncedUpdatedDate  ? debouncedUpdatedDate.split('|')[1] : undefined,
                status          : debouncedStatus       ? debouncedStatus                    : undefined,
            })

            setNeedUpdateCaseManagementFromAPI(true)
            setLoading(true)
            
            // FOR ANY PARAMETER THAT CHANGED
            if( 
                debouncedFilterCaseId ||
                debouncedSource       ||
                debouncedExternalId   ||
                debouncedLocked       ||
                debouncedCreatedBy    ||
                debouncedCreatedDate  ||
                debouncedUpdatedDate  ||
                debouncedStatus
            ){
                
                const newParams: any = {}

                if(debouncedFilterCaseId){ newParams.cms_id        =  debouncedFilterCaseId   }
                if(debouncedSource      ){ newParams.source        =  debouncedSource         }
                if(debouncedExternalId  ){ newParams.external_id   =  debouncedExternalId     }
                if(debouncedLocked      ){ newParams.locked        =  debouncedLocked         }
                if(debouncedCreatedBy   ){ newParams.created_by    =  debouncedCreatedBy      }
                if(debouncedCreatedDate ){ newParams.created_date  =  debouncedCreatedDate    }
                if(debouncedUpdatedDate ){ newParams.updated_date  =  debouncedUpdatedDate    }
                if(debouncedStatus      ){ newParams.status        =  debouncedStatus         }

                newParams.page_number = filterData['page.number']
                newParams.page_size   = filterData['page.size'  ]
                newParams.sort_by     = filterData.sortBy
                newParams.direction   = filterData.direction

                setSearchParams(newParams)
            }else{
                // FOR CLEARED PARAMETER
                if(debouncedFilterCaseId === ''){ searchParams.delete('cms_id')        }
                if(debouncedSource === ''      ){ searchParams.delete('source')        }
                if(debouncedExternalId === ''  ){ searchParams.delete('external_id')   }
                if(debouncedLocked === ''      ){ searchParams.delete('locked')        }
                if(debouncedCreatedBy === ''   ){ searchParams.delete('created_by')    }
                if(debouncedCreatedDate === '' ){ searchParams.delete('created_date')  }
                if(debouncedUpdatedDate === '' ){ searchParams.delete('updated_date')  }
                if(debouncedStatus      === '' ){ searchParams.delete('status')        }
                // IF WAS CLEARED ALL PARAMETERS
                if(
                    debouncedFilterCaseId === '' &&
                    debouncedSource === '' &&
                    debouncedExternalId === '' &&
                    debouncedLocked === '' &&
                    debouncedCreatedBy === '' &&
                    debouncedCreatedDate === '' &&
                    debouncedUpdatedDate === '' &&
                    debouncedStatus === ''
                ){ 
                    ['page_number','page_size','sort_by','direction'].forEach(item => searchParams.delete(item))
                }
                setSearchParams(searchParams)

            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
        debouncedFilterCaseId,
        debouncedSource,
        debouncedExternalId,
        debouncedLocked,
        debouncedCreatedBy,
        debouncedCreatedDate,
        debouncedUpdatedDate,
        debouncedStatus,
    ])

    // STARTUP INITIALIZATION from URL
    useEffect(()=>{
        const searchfields = ['cms_id','source','external_id','locked','created_by','created_date','updated_date','page_number','page_size','direction','sort_by', 'status']
        const isSearchParamsInsideURL = searchfields.filter(field => searchParams.has(field)).length > 0 
        if( isSearchParamsInsideURL === true ){

            setCaseManagementFilterData({
                id              : searchParams.get('cms_id'      ) !== null ? String(searchParams.get('cms_id'      ))                   : undefined,
                source          : searchParams.get('source'      ) !== null ? String(searchParams.get('source'      ))                   : undefined,
                externalId      : searchParams.get('external_id' ) !== null ? String(searchParams.get('external_id' ))                   : undefined,
                locked          : searchParams.get('locked'      ) !== null ? String(searchParams.get('locked'      ))                   : undefined,
                createdBy       : searchParams.get('created_by'  ) !== null ? String(searchParams.get('created_by'  ))                   : undefined,
                dateCreatedFrom : searchParams.get('created_date') !== null ? String(searchParams.get('created_date')).split('|')[0]     : undefined,
                dateCreatedTo   : searchParams.get('created_date') !== null ? String(searchParams.get('created_date')).split('|')[1]     : undefined,
                dateUpdatedFrom : searchParams.get('updated_date') !== null ? String(searchParams.get('updated_date')).split('|')[0]     : undefined,
                dateUpdatedTo   : searchParams.get('updated_date') !== null ? String(searchParams.get('updated_date')).split('|')[1]     : undefined,
                direction       : searchParams.get('direction'   ) !== null ? searchParams.get('direction') as  enumSORT                 : filterData.direction,
                "page.number"   : searchParams.get('page_number' ) !== null ? Number(searchParams.get('page_number' ))                   : undefined,
                "page.size"     : searchParams.get('page_size'   ) !== null ? Number(searchParams.get('page_size'   ))                   : undefined,
                sortBy          : searchParams.get('sort_by'     ) !== null ? searchParams.get('sort_by'   ) as TCaseManagementSortingBy : undefined,
                status          : searchParams.get('status'      ) !== null ? String(searchParams.get('status'      ))                   : undefined,
            })
            setNeedUpdateCaseManagementFromAPI(true)
            setLoading(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // ADD PARAMETERS FOR PAGINATION
    useEffect(()=>{
        const searchfields = ['page_number','page_size','direction','sort_by']
        const isSearchParamsInsideURL = searchfields.filter(field => searchParams.has(field)).length > 0 
        if( isSearchParamsInsideURL === true ){
            searchParams.set('page_number', String(filterData['page.number']) )
            searchParams.set('page_size'  , String(filterData['page.size'  ]) )
            searchParams.set('direction'  , String(filterData.direction ? filterData.direction : ''  ) )
            searchParams.set('sort_by'    , String(filterData.sortBy        ) )
            setSearchParams(searchParams)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filterData])

    const onChangeFilter = (filterField: enumSortingField, filterValue: string, isInit?: boolean) =>{

        switch (filterField) {
            case enumSortingField.ID:
                onFilterCaseIdChange(filterValue, isInit)
                break
            case enumSortingField.SOURCE:
                onSourceChange( filterValue , isInit)
                break
            case enumSortingField.EXTERNAL_ID:
                onExternalIdChange( filterValue , isInit)
                break
            case enumSortingField.LOCKED:
                onLockedChange( filterValue , isInit)
                break
            case enumSortingField.CREATED_BY:
                onCreatedByChange( filterValue , isInit)
                break
            case enumSortingField.CREATED_AT:
                onCreatedDateChange( filterValue , isInit)
                break
            case enumSortingField.UPDATED_AT:
                onUpdatedDateChange( filterValue , isInit)
                break
            case enumSortingField.STATUS:
                onStatusChange(filterValue , isInit)
                break

            default: break
        }
    }

    

    return(
        <Box
            sx={{
                width: '260px',
                height: 'calc(100vh - 70px)',
                position: isStickyEnabled ? 'sticky' : undefined , // https://medium.com/web-standards/sticky-bc7ff7088693
                left: 0,
                top: '70px',
                backgroundColor: '#fff',
                zIndex: 8,
                display: 'flex',
                flexDirection: 'column',
                transition: 'all 0.3s ease-out',
                margin: '10px 8px 0 -26px ',
            }}          
        >
            <Box
                sx={{
                    width: 'calc( 100% - 16px)',
                    backgroundColor: colorLibrary.backgroundColor,
                    padding: '6px 4px',
                    color: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '0 8px 8px 8px',
                    borderRadius: '8px',
                }}
            >
                <Box sx={{display: 'flex',}}>
                    <SearchIcon />
                    <div>
                        Search by
                    </div>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}
                >

                    <TextField
                        autoFocus
                        size    = 'small'
                        id      = "searchByExternalId"
                        label   = { 'External ID' }
                        type    = "text"
                        variant = "outlined"
                        value   = { externalId }
                        error   = {!isExternalIdValid}
                        onChange={(e)=>{
                            if(String(e.target.value).trim() === ''){
                                onChangeFilter(enumSortingField.EXTERNAL_ID, '', true)
                            }else{
                                onChangeFilter(enumSortingField.EXTERNAL_ID, String(e.target.value).trim(), false)
                            }
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment:
                                externalId &&
                                <InputAdornment position="end" 
                                    sx={{  }}
                                >
                                    <IconButton onClick={()=>{ onChangeFilter(enumSortingField.EXTERNAL_ID, '', true) }} >
                                        <CancelIcon/>
                                    </IconButton>
                                </InputAdornment>,
                        }}
                        sx={textFieldStyle}
                    />

                    <TextField
                        size    = 'small'
                        id      = "searchBySource"
                        label   = { 'Source' }
                        type    = "text"
                        variant = "outlined"
                        value   = { source }
                        error={!isSourceValid}
                        onChange={(e)=>{
                            if(String(e.target.value).trim() === ''){
                                onChangeFilter(enumSortingField.SOURCE, '', true)
                            }else{
                                onChangeFilter(enumSortingField.SOURCE, String(e.target.value).trim(), false)
                            }
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment:
                                source &&
                                <InputAdornment position="end" 
                                    sx={{  }}
                                >
                                    <IconButton onClick={()=>{ onChangeFilter(enumSortingField.SOURCE, '', true) }} >
                                        <CancelIcon/>
                                    </IconButton>
                                </InputAdornment>,
                        }}
                        sx={textFieldStyle}
                    />

                    <TextField
                        size='small'
                        id      = "searchByCaseId"
                        label   = { 'CMS ID' }
                        type    = "text"
                        variant = "outlined"
                        value   = { 
                            filterCaseId
                        }
                        error={!isFilterCaseIdValid}
                        onChange={(e)=>{
                            if(String(e.target.value).trim() === ''){
                                onChangeFilter(enumSortingField.ID, '', true)
                            }else{
                                onChangeFilter(enumSortingField.ID, String(e.target.value).trim(), false)
                            }
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment:
                                filterCaseId && 
                                <InputAdornment position="end">
                                    <IconButton onClick={()=>{ onChangeFilter(enumSortingField.ID, '', true) }} >
                                        <CancelIcon/>
                                    </IconButton>
                                </InputAdornment>
                        }}
                        sx={textFieldStyle}
                    />

                    

                    <FormControl size="small" fullWidth
                        sx={textFieldStyle}
                        error = {!isLockedValid}
                    >
                        <InputLabel id="is-locked-selector">Locked</InputLabel>
                        <Select
                            labelId="is-locked-selector"
                            value={locked === 'true' ? 'Locked' : ( locked === 'false' ? 'Unlocked' : '' )}
                            label="Locked"
                            onChange={(e)=>{
                                const newValue =  String(e.target.value) === '-' ? '' : String(e.target.value)
                                if(newValue !==''){
                                    onChangeFilter(enumSortingField.LOCKED, newValue === 'Locked' ? 'true': 'false')
                                }else{
                                    onChangeFilter(enumSortingField.LOCKED, '', true) }
                                }
                            }
                            sx={{
                                height: '34px',
                                fontSize: '12px',
                            }}
                        >
                            {
                                ['-', 'Locked', 'Unlocked'].map((item, itemIndex)=>{
                                    return(
                                        <MenuItem 
                                            key={`locked-Filter-item-${itemIndex}`}
                                            value={item}
                                        >
                                            {
                                                item
                                            }
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>

                    <TextField
                        size='small'
                        id      = "searchByCreatedBy"
                        label   = { 'Created By (Case Sensitive)' }
                        type    = "text"
                        variant = "outlined"
                        value   = { createdBy }
                        error   = {!isCreatedByValid}
                        onChange={(e)=>{
                            if(String(e.target.value).trim() === ''){
                                onChangeFilter(enumSortingField.CREATED_BY, '', true)
                            }else{
                                onChangeFilter(enumSortingField.CREATED_BY, String(e.target.value).trim(), false)
                            }
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment:
                                createdBy &&
                                <InputAdornment position="end" 
                                    sx={{  }}
                                >
                                    <IconButton onClick={()=>{ onChangeFilter(enumSortingField.CREATED_BY, '', true) }} >
                                        <CancelIcon/>
                                    </IconButton>
                                </InputAdornment>,
                        }}

                        sx={textFieldStyle}
                    />

                    <DateRangePicker
                        className='dataRange'
                        placeholder="Created Date"
                        format={ getDateInputTemplate()[0] }
                        placement={'auto'}
                        onChange={(e: DateRange | null )=>{ 
                            if(e === null){
                                onChangeFilter(enumSortingField.CREATED_AT, '', true)
                            }else{
                                onChangeFilter(enumSortingField.CREATED_AT, adjustDateRange(e).map(item=>item.toISOString()).join('|'), false)
                            }
                        }}

                        style={{
                            padding: '8px',
                            zIndex: 20,
                        }}

                        disabledDate={ date => isAfter(date, new Date())} 
                        value = { debouncedCreatedDate ? [ new Date( debouncedCreatedDate.split('|')[0] ), new Date( debouncedCreatedDate.split('|')[1] )] : null } 
                    />

                    <DateRangePicker
                        className='dataRange'
                        placeholder="Last Updated"
                        format={ getDateInputTemplate()[0] }
                        placement={'auto'}
                        onChange={(e: DateRange | null )=>{ 
                            if(e === null){
                                onChangeFilter(enumSortingField.UPDATED_AT, '', true)
                            }else{
                                onChangeFilter(enumSortingField.UPDATED_AT, adjustDateRange(e).map(item=>item.toISOString()).join('|'), false)
                            }
                        }}
                        
                        style={{
                            padding: '8px',
                            zIndex: 20,
                        }}
                        value = { debouncedUpdatedDate ? [ new Date( debouncedUpdatedDate.split('|')[0] ), new Date( debouncedUpdatedDate.split('|')[1] )] : null }
                        disabledDate={ date => isAfter(date, new Date())}
                    />

                    <FormControl size="small" fullWidth
                        sx={textFieldStyle}
                        error = {!isLockedValid}
                    >
                        <InputLabel id="is-status-selector">Status</InputLabel>
                        <Select
                            labelId="is-status-selector"
                            value={ status}
                            label="Status"
                            onChange={(e)=>{
                                e.preventDefault()
                                e.stopPropagation()
                                
                                const newValue =  String(e.target.value) === '-' ? '' : String(e.target.value)
                                
                                if(newValue !==''){
                                    onChangeFilter(enumSortingField.STATUS, newValue)
                                }else{
                                    onChangeFilter(enumSortingField.STATUS, '', true)
                                }
                            }}

                            sx={{
                                height: '34px',
                                fontSize: '12px',
                            }}
                        >
                            {
                                ['-', ...caseStatuses].map((statusItem, statusItemIndex)=>{
                                    return(
                                        <MenuItem 
                                            key={`status-item-${statusItemIndex}`}
                                            value={statusItem}
                                        >
                                            {
                                                statusItem
                                            }
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>

                    <Checkbox
                        sx={{
                            margin: '6px 0 6px 10px'
                        }}
                        label="Include auto-test cases"
                        checked={includeAutoTestFilter}
                        onChange={()=>{ setIncludeAutoTestFilter(checked => !checked) }}
                    />
                    
                    <FilterPanelCSVExporter 
                        id               =  { debouncedFilterCaseId ? debouncedFilterCaseId              : undefined }
                        source           =  { debouncedSource       ? debouncedSource                    : undefined }
                        externalId       =  { debouncedExternalId   ? debouncedExternalId                : undefined }
                        locked           =  { debouncedLocked       ? debouncedLocked                    : undefined }
                        createdBy        =  { debouncedCreatedBy    ? debouncedCreatedBy                 : undefined }
                        dateCreatedFrom  =  { debouncedCreatedDate  ? debouncedCreatedDate.split('|')[0] : undefined }
                        dateCreatedTo    =  { debouncedCreatedDate  ? debouncedCreatedDate.split('|')[1] : undefined }
                        dateUpdatedFrom  =  { debouncedUpdatedDate  ? debouncedUpdatedDate.split('|')[0] : undefined }
                        dateUpdatedTo    =  { debouncedUpdatedDate  ? debouncedUpdatedDate.split('|')[1] : undefined }
                        sortBy           =  { filterData.sortBy                                                      }
                        direction        =  { filterData.direction                                                   }
                    />

                </Box>

                <CaseManagementPaginaton 
                    setPageNumber={ ( pageNumber:number ) => {
                        setCaseManagementPageNumber(pageNumber)
                        setNeedUpdateCaseManagementFromAPI(true)
                        setLoading(true)
                    }}
                    setPageSize  ={ (pageSize:number)=> {
                        setCaseManagementPageSize(pageSize)
                        setNeedUpdateCaseManagementFromAPI(true)
                        setLoading(true)
                    }}
                    currentPageNumber = { filterData['page.number'] ? filterData['page.number'] : 0  }
                    currentPageSize   = { filterData['page.size'  ] ? filterData['page.size'  ] : 20 }
                    lastRequestRecordsCount = { casesListLength }
                />
            </Box>
            

        </Box>
    )

}

export default FilterPanel
