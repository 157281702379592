import { TApiGetCasesByFilter } from "../Api/ApiCaseManagementRequest"
import { THistoryRecord } from "../Api/ApiCaseManagementResponse"
import { enumSORT, enumSortingField } from "../General"


export enum enumStoreCaseManagementAction {
    SET_CM_NEED_UPDATE_GROUPS_FROM_API = 'SET_CM_NEED_UPDATE_GROUPS_FROM_API',
    SET_CM_CASES_LIST                  = 'SET_CM_CASES_LIST',
    SET_CM_FILTER_VALUE                = 'SET_CM_FILTER_VALUE',
    SET_CM_FILTER_SORTING              = 'SET_CM_FILTER_SORTING',
    SET_CM_FILTER_PAGINATION           = 'SET_CM_FILTER_PAGINATION', 
    SET_CM_ERROR                       = 'SET_CM_ERROR',
    SET_CM_ERROR_NEED_TO_SHOW          = 'SET_CM_ERROR_NEED_TO_SHOW',

    SET_CM_CASES                       = 'SET_CM_CASES',

    SET_CM_PAGE_NUMBER                 = 'SET_CM_PAGE_NUMBER',
    SET_CM_PAGE_SIZE                   = 'SET_CM_PAGE_SIZE',
    SET_CM_SORTING_DIRECTION           = 'SET_CM_SORTING_DIRECTION',
    SET_CM_SORTING                     = 'SET_CM_SORTING',
    SET_CM_FILTER_DATA                 = 'SET_CM_FILTER_DATA',
}

export enum enumStoreCaseManagementUserHistoryAction {
    SET_CMUH_HISTORY_LIST         = 'SET_CMUH_HISTORY_LIST',

    SET_CMUH_NEED_UPDATE_FROM_API = 'SET_CMUH_NEED_UPDATE_FROM_API',
    SET_CMUH_SET_PAGINATION       = 'SET_CMUH_SET_PAGINATION',
    SET_CMUH_FILTER_DATA      = 'SET_CMUH_SET_FILTER_DATA',
    SET_CMUH_DIRECTION        = 'SET_CMUH_DIRECTION',
    
    SET_CMUH_ERROR                = 'SET_CM_ERROR',
    SET_CMUH_ERROR_NEED_TO_SHOW   = 'SET_CM_ERROR_NEED_TO_SHOW',
}

export type TCase = {
    id                       : string
    externalId               : string
    createdBy                : string
    source                   : string
    version                  : number
    createdAt                : string
    updatedAt                : string
    medicalRecordNumber      : string
    status                   : string
    locked                   : boolean
    lockedBy?                : string 
    lockedAt?                : string 
    smoVersion?              : string
    smoObjExportPath?        : string
    treatmentOption?         : string
    tags?                    : string[]
    previousCaseId?          : string
    externalTreatmentPlanId? : string
}

export type TPagination = {
    totalElements: number
    size  : number
    number: number
}

export type TCaseManagementFilterData = {
    filterId  :enumSortingField
    value     : string
    sorting   : enumSORT,
    pagination: TPagination
}

export type TCaseManagementSortingBy = 'CREATED_BY' | 'DATE_CREATED' | 'DATE_UPDATED'

export interface IStoreCaseManagement {
    needUpdateListFromAPI: boolean
    list: TCase[]

    filterData: TApiGetCasesByFilter

    error:{
        needToShow: boolean
        status    : string
        message   : string
        path      : string
    },
}

export type TCaseManagementStoreAction =    TCaseManagementSetNeedUpdateGroupsFromAPI |
                                            TCaseManagementSeCasesList |
                                            TCaseManagementSetError |
                                            TCaseManagementSetErrorNeedToShow |
                                            TCaseManagementSetCasesV2 |
                                            TCaseManagementSetSorting |
                                            TCaseManagementSetPageNumber |
                                            TCaseManagementSetPageSize |
                                            TCaseManagementSetFilterData

export type TCaseManagementSetNeedUpdateGroupsFromAPI = {
    type: enumStoreCaseManagementAction.SET_CM_NEED_UPDATE_GROUPS_FROM_API
    payload: boolean
}


export type TCaseManagementSetSortingPayload = {
    sortingField: enumSortingField
    sorting: enumSORT
}

export type TCaseManagementSeCasesListPayload = {
    casesList : TCase[]
    filterId  : enumSortingField
    pagination: TPagination
}

export type TCaseManagementSetCasesV2 = {
    type: enumStoreCaseManagementAction.SET_CM_CASES
    payload: TCase[]
}

export type TCaseManagementSeCasesList = {
    type: enumStoreCaseManagementAction.SET_CM_CASES_LIST
    payload: TCaseManagementSeCasesListPayload
}


export type TCaseManagementSetErrorPayload = {
    status    : string
    message   : string
    path      : string
}

export type TCaseManagementSetError = {
    type: enumStoreCaseManagementAction.SET_CM_ERROR,
    payload: TCaseManagementSetErrorPayload
}

export type TCaseManagementSetErrorNeedToShow = {
    type: enumStoreCaseManagementAction.SET_CM_ERROR_NEED_TO_SHOW,
    payload: boolean
}

export type TCaseManagementUserHistoryStoreAction = TCaseManagementUserHistorySetNeedUpdateFromAPI |  
                                                    TCaseManagementUserHistorySetPagination |
                                                    TCaseManagementUserHistorySetError |
                                                    TCaseManagementUserHistorySetErrorNeedToShow |
                                                    TCaseManagementUserHistoryListAction |
                                                    TCaseManagementUserHistorySetFilter |
                                                    TCaseManagementUserHistorySetSortingDirectionAction


export type TCaseManagementUserHistorySetPagination = {
    type    : enumStoreCaseManagementUserHistoryAction.SET_CMUH_SET_PAGINATION,
    payload : TPagination
}

export type TCaseManagementUserHistorySetFilter = {
    type    : enumStoreCaseManagementUserHistoryAction.SET_CMUH_FILTER_DATA,
    payload : {
        user : string
        from : string
        to : string
    }
}

export type TCaseManagementUserHistorySetNeedUpdateFromAPI = {
    type: enumStoreCaseManagementUserHistoryAction.SET_CMUH_NEED_UPDATE_FROM_API
    payload: boolean
}

export type TCaseManagementUserHistorySetError = {
    type: enumStoreCaseManagementUserHistoryAction.SET_CMUH_ERROR,
    payload: TCaseManagementSetErrorPayload
}

export type TCaseManagementUserHistorySetErrorNeedToShow = {
    type: enumStoreCaseManagementUserHistoryAction.SET_CMUH_ERROR_NEED_TO_SHOW,
    payload: boolean
}

export type TCaseManagementUserHistoryListAction = {
    type:enumStoreCaseManagementUserHistoryAction.SET_CMUH_HISTORY_LIST,
    payload: THistoryRecord[]
}

export type TCaseManagementUserHistorySetSortingDirectionAction = {
    type:enumStoreCaseManagementUserHistoryAction.SET_CMUH_DIRECTION,
    payload: enumSORT
}
export interface IStoreCaseManagementUserHistory {
    
    needUpdateListFromAPI: boolean
    pagination: TPagination
    historyList: THistoryRecord[]
    
    sorting: enumSORT
    sortingField: enumSortingField,

    filter:{
        user : string
        from : string
        to : string
    },

    error:{
        needToShow: boolean
        status    : string
        message   : string
        path      : string
    },
}

export type TCaseLockDescription = {
    caseId   : string
    createdBy: string
    createdAt: string
}

export type TCaseManagementSetSorting = {
    type    : enumStoreCaseManagementAction.SET_CM_SORTING
    payload : TCaseManagementSortingBy
}

export type TCaseManagementSetPageNumber = {
    type    : enumStoreCaseManagementAction.SET_CM_PAGE_NUMBER
    payload : number
}

export type TCaseManagementSetPageSize = {
    type    : enumStoreCaseManagementAction.SET_CM_PAGE_SIZE
    payload : number
}

export type TCaseManagementSetFilterData = {
    type : enumStoreCaseManagementAction.SET_CM_FILTER_DATA
    payload: TApiGetCasesByFilter
}
