import { AxiosResponse } from "axios"
import API from '../api-base'
import { TApiPostCreateUpdateApplicationConfig } from "../../../Types/Api/ApiDesktopConfigRequest"
import { enumApiParameter } from "../../../Types/General"

// https://desktop-configuration-api.tp-dev-stable.smiledirect.services/swagger-ui/index.html?urls.primaryName=Version%202#/Application%20configuration%20API/create_2

const postCreateApplicationConfig = async ( params: TApiPostCreateUpdateApplicationConfig ): Promise<AxiosResponse>  =>{
    return await API.post(

        `/v2/configurations/applications/${ String(params[enumApiParameter.APPLICATION]) }/${params[enumApiParameter.VERSION]}`,
        
        params.data
        
    )
}

export default postCreateApplicationConfig
