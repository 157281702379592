import { Box, Typography, Modal, Button } from '@mui/material'
import { useMemo, useState } from 'react'
import deleteCaseFileByS3Key from '../../../../Api/CaseManagement/v2/delete-case-file-by-s3-key'
import { useAction } from '../../../../Store/Hook/use-action'
import colorLibrary from '../../../../Utils/colors'
import { TCaseFileDescription } from '../../../../Types/Api/ApiCaseManagementResponse'
import getIconByFilenameExtension from '../../../../Utils/getIconByFilenameExtension'
import { toolbarButtonStyle } from './FilesToolbar'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

type TFilesToolbarActionDeleteProps = {
    fileDescription: TCaseFileDescription | undefined
    setNeedUpdateFileTree: ()=>void
}

const FilesToolbarActionDelete = (props: TFilesToolbarActionDeleteProps) => {
    
    const { fileDescription, setNeedUpdateFileTree } = props
    const { setCaseManagementError, setCaseManagementErrorNeedToShow } = useAction()

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleDelete = () =>{
        if(fileDescription){

            deleteCaseFileByS3Key({
                id: fileDescription.caseId,
                s3key: fileDescription.s3key,
            }).then((response)=>{
                handleClose()
                if(setNeedUpdateFileTree){
                    setNeedUpdateFileTree()
                }
            }).catch((error)=>{
                setCaseManagementError({
                    status  : error.response.data.status ? String(error.response.data.status) : '',
                    path    : error.response.data.path   ? String(error.response.data.path)   : '',
                    message : error.response.data.message ? String(error.response.data.message) : `CANT DELETE FILE WITH S3Key = ${fileDescription.s3key} FROM Case = ${fileDescription.caseId}`
                })
                setCaseManagementErrorNeedToShow(true)
            })
        }

    }

    const fileIcon = useMemo(()=>{
        return(getIconByFilenameExtension(fileDescription ? fileDescription.fileName : ''))
    },[ fileDescription ])

    return(
        <>

            <Button
                size="small"
                variant="outlined"
                sx={toolbarButtonStyle} 
                startIcon={<DeleteForeverIcon />}
                onClick={ handleOpen }
            >
                Delete
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 'max-content',
                    bgcolor: '#fff',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography variant="h6" component="h2">
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Box
                                component={fileIcon}
                                sx={{
                                    fill: colorLibrary.backgroundColor,
                                    margin: '0 20px 0 0',
                                    width: '64px',
                                    height: '64px',
                                }}
                            />
                            <Box sx={{
                                color: '#000',
                                fontSize: '24px',
                            }}>
                                { fileDescription?.fileName }
                            </Box>
                        </Box>
                    </Typography>

                    <Typography sx={{ mt: 2, margin : '40px 0 40px 0' }}>
                        Do you really want to delete this file?
                    </Typography>

                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <Button variant='outlined' onClick={handleClose} sx={{margin: '0 30px 0 0'}}>
                            Cancel
                        </Button>
                        
                        <Button variant='outlined' onClick={handleDelete}>
                            Delete
                        </Button>
                    </Box>
                    
                </Box>
            </Modal>
        </>

    )
}

export default FilesToolbarActionDelete
