import { Box, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { TWorkflowRuleAction } from "../../../Types/Store/StoreWorkflow"
import useValidation, { enumFormValidationRule }  from "../../formValidator"
import { TActionEditorProps, TAttributeCheckResult } from "./ActionEditor"
import checkFormState from "./Utils/check-form-state"
import generateFormEntries from "./Utils/generate-form"



const ActionCreator = (props:TActionEditorProps) =>{

    const { 
        isVisible , 
        actionItemIndex, 
        actionList, 
        setActionList, 
        setInvisible, 
        avalableActionsTemplate
    } = props
    

    const [ templateIndex,   setTemplateIndex   ] = useState(-1)
    const [ formState,       setFormState       ] = useState<Object>({})
    const [ formErrorState,  setFormErrorsState ] = useState<TAttributeCheckResult[]>([])
    const [ isNeedSetAction, setNeedSetAction   ] = useState(false)
    const [ localFormState,  setLocalFormState ] = useState<TWorkflowRuleAction | undefined>()

    const [
        actionTemplateType,
        isActionTemplateTypeValid,
        ,
        onActionTemplateTypeChange
    ] = useValidation([enumFormValidationRule.REQUIRED])

    
    useEffect(()=>{

        if(avalableActionsTemplate && actionTemplateType && templateIndex > -1){

            const newAttributes:any = {}
            const objectEntries = Object.entries(avalableActionsTemplate[templateIndex].attributes)

            for(let i = 0; i< objectEntries.length; i++){

                if(objectEntries[i][1].type === 'string'){
                    newAttributes[objectEntries[i][0]] =  ''
                }
                if(objectEntries[i][1].type === 'array'){
                    newAttributes[objectEntries[i][0]] =  []
                }
            }

            setLocalFormState({
                type: avalableActionsTemplate[templateIndex].type,
                attributes: {
                    ...newAttributes,
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[templateIndex, avalableActionsTemplate])


    useEffect(()=>{
        if(isNeedSetAction === true){
            if(setActionList && localFormState){

                const formCheckResults = checkFormState(
                    {
                        type: localFormState.type,
                        attributes: {
                            ...localFormState.attributes,
                            ...formState
                        }
                    },
                    avalableActionsTemplate
                )

                if(formCheckResults.length>0){
                    
                    setLocalFormState({
                        type: localFormState.type,
                        attributes: {
                            ...localFormState.attributes,
                            ...formState
                        }
                    })
                    setFormErrorsState(formCheckResults)

                }else{
                    
                    if(setInvisible){
                        setInvisible()
                        onActionTemplateTypeChange('')
                        setTemplateIndex(-1)
                    }
                    
                    setFormErrorsState([])
                    setActionList((prevList)=>{
                        
                        return([
                            ...prevList,
                            {
                                type: avalableActionsTemplate[templateIndex].type,
                                attributes: {
                                    ...formState
                                }
                            }
                        ])
                    })
                    setLocalFormState(undefined)
                    setFormState({})
                }
                
                setNeedSetAction(false)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[isNeedSetAction, actionItemIndex,formState,actionList, localFormState])


    const selectActionType = useMemo(()=>{
        
        if(avalableActionsTemplate.length === 0){
            return null
        }

        return(
            <Box
                sx={{
                    width:'100%',
                    display: "flex",
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                }}
            >
                <Box 
                    sx={{ 
                        height: '100%', 
                        display:'flex', 
                        alignSelf:'center', 
                        margin: '0 20px 16px 0' 
                }}>
                    Add new 
                </Box>

                <FormControl error={!isActionTemplateTypeValid}
                    sx={{width: '100%', margin: '0 20px 20px 0'}}
                >
                    <InputLabel id="action-type-label">Action Type</InputLabel>
                    <Select
                        labelId="action-type-label"
                        value={actionTemplateType}
                        label="Action Type"
                        onChange={(e)=>{
                            onActionTemplateTypeChange(e.target.value)
                            let templateIndex = -1
                            for(let i = 0; i<avalableActionsTemplate.length; i++){
                                if( avalableActionsTemplate[i].type === e.target.value ){
                                    templateIndex = i
                                    break
                                }
                            }
                            setTemplateIndex( templateIndex )
                            setFormState({})
                        }}
                        size='medium'
                        sx={{width: '200px'}}
                        fullWidth
                    >
                        {
                            avalableActionsTemplate
                            .map((actionItem, index)=>{
                                return( <MenuItem key = {`actiontype-${index}`} value={actionItem.type}>{actionItem.type}</MenuItem> )
                            })

                        }
                    </Select>
                </FormControl>
            </Box>
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[avalableActionsTemplate, actionTemplateType, templateIndex])



    const actionNewItem = useMemo(()=>{

        // ACTION TYPE DOESN't SELECTED

        if(templateIndex === -1 || avalableActionsTemplate.length === 0){
            return(
                <Box
                        sx={{margin: '16px 0 0 0'}}
                    >
                        <Button
                            disabled
                            variant="outlined" 
                            color  ='primary' 
                            size   ='small'
                            sx={{margin: '0 16px 0 0'}}
                        >
                            Add
                        </Button>
                        <Button
                            variant="outlined" 
                            color  ='primary' 
                            size   ='small'
                            onClick={()=>{
                                if(setInvisible){
                                    setInvisible()
                                    onActionTemplateTypeChange('',true)
                                    setTemplateIndex(-1)
                                    setLocalFormState(undefined)
                                    setFormState({})
                                }
                            }}
                        >
                            Cancel
                        </Button>
                    </Box>
            )
        }else{

            
            const startValues =  localFormState ? localFormState.attributes as { [key: string]: string; } : {}
            const formForNewElements = generateFormEntries(avalableActionsTemplate[templateIndex].attributes, setFormState, startValues , formErrorState )

            return(
            
                <Box>
                    <Box>
                        {
                            formForNewElements.length === 0 ?
                                <Box sx={{
                                    margin: '0 0 20px 0',
                                    border: '1px solid #ccc',
                                    padding: '10px',
                                }}>
                                    Action doesn't has atributes
                                </Box>
                            :
                                <Box
                                    sx={{
                                        border: '1px solid #ccc',
                                        padding: '0 20px 20px 20px',
                                    }}
                                >
                                    {
                                        formForNewElements
                                    }
                                </Box>
                        }
                    </Box>

                    <Box
                        sx={{
                            margin: '16px 0 0 0',
                        }}
                    >
                        <Button
                            variant="outlined" 
                            color  ='primary' 
                            size   ='small'
                            sx={{margin: '0 16px 0 0'}}
                            onClick={()=>{
                                setNeedSetAction(true)
                            }}
                        >
                            Add
                        </Button>
                        <Button
                            variant="outlined" 
                            color  ='primary' 
                            size   ='small'
                            onClick={()=>{
                                if(setInvisible){
                                    setInvisible()
                                    onActionTemplateTypeChange('',true)
                                    setTemplateIndex(-1)
                                    setFormErrorsState([])
                                    setLocalFormState(undefined)
                                    setFormState({})
                                }
                            }}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[ templateIndex, avalableActionsTemplate, formErrorState, ])

    return(
        isVisible ?
            <Box
                sx={{
                    margin: '32px 0 0 0',
                    width: '100%',
                    border: '1px solid #ccc',
                    padding: '10px 20px 10px 24px',
                }}
            >
                
                { selectActionType }
                { actionNewItem    }
            </Box>
        : null
    )
}

export default ActionCreator
