import { enumSortingField, TSort } from "../General"
import { TPagination } from "./StoreCaseManagement"

export type TWorkflowRuleActionType = string // `${ 'LOG' | 'EMAIL' }_ACTION`

export type TWorkflowRuleAction = {
    type      : TWorkflowRuleActionType
    attributes: Object
}

export type TWorkflowRuleNew = {

    name      : string
    condition : string
    order     : number
    enabled   : boolean
    actions   : TWorkflowRuleAction[]
}

export type TWorkflowRule = {
    id        : string
    name      : string
    condition : string
    order     : number
    enabled   : boolean
    actions   : TWorkflowRuleAction[]
    version   : number
}

export type TWorkflowStore = {

    needUpdateDataFromAPI: boolean
    
    rulesList: TWorkflowRule[]

    pagination:TPagination

    sorting: TSort,
    sortingField: enumSortingField,

    error:{
        needToShow: boolean
        status    : string
        message   : string
        path      : string
    }
}

export enum enumWorkflowStoreActionType {
    SET_WORKFLOW_RULES_LIST                 = 'SET_WORKFLOW_RULES_LIST',
    SET_WORKFLOW_RULES_NEED_UPDATE_FROM_API = 'SET_WORKFLOW_RULES_NEED_UPDATE_FROM_API',
    SET_WORKFLOW_RULES_PAGINATION           = 'SET_WORKFLOW_RULES_PAGINATION',
    SET_WORKFLOW_RULES_ERROR                = 'SET_WORKFLOW_RULES_ERROR',
    SET_WORKFLOW_RULES_ERROR_NEED_TO_SHOW   = 'SET_WORKFLOW_RULES_ERROR_NEED_TO_SHOW',
}

export type TWorkflowStoreAction =  TWorkflowStoreActionSetRulesList |
                                    TWorkflowStoreActionSetNeedUpdateRulesListFromAPI |
                                    TWorkflowStoreActionSetPagination |
                                    TWorkflowStoreActionSetError


export type TWorkflowStoreActionSetRulesList = {
    type: enumWorkflowStoreActionType.SET_WORKFLOW_RULES_LIST
    payload: TWorkflowRule[] 
}

export type TWorkflowStoreActionSetNeedUpdateRulesListFromAPI = {
    type: enumWorkflowStoreActionType.SET_WORKFLOW_RULES_NEED_UPDATE_FROM_API
    payload: boolean
}

export type TWorkflowStoreActionSetPagination = {
    type: enumWorkflowStoreActionType.SET_WORKFLOW_RULES_PAGINATION
    payload: TPagination
}

export type TWorkflowSetErrorPayload = {
    needToShow: boolean
    status    : string
    message   : string
    path      : string
}


export type TWorkflowStoreActionSetError = {
    type: enumWorkflowStoreActionType.SET_WORKFLOW_RULES_ERROR
    payload: TWorkflowSetErrorPayload
}

export type TWorkflowStoreActionSetErrorNeedToShow = {
    type: enumWorkflowStoreActionType.SET_WORKFLOW_RULES_ERROR_NEED_TO_SHOW
    payload: boolean
}
