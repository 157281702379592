import { AxiosResponse } from "axios"
import API from '../api-base'
import { enumApiParameter } from "../../../Types/General"
import { TApiGetListOfUserConfigSortByUpdatedAtV2         } from "../../../Types/Api/ApiDesktopConfigRequest"
import { TApiGetListOfUserConfigSortByUpdatedAtResponse } from "../../../Types/Api/ApiDesktopConfigResponse"

// https://adalisk.stoplight.io/docs/tp-api/b3A6Mzg5ODY2NDM-get-list-of-user-config-sort-by-udpdated-at

const getListOfUserConfigSortByUpdatedAtV2 = async ( params: TApiGetListOfUserConfigSortByUpdatedAtV2 ): Promise<AxiosResponse<TApiGetListOfUserConfigSortByUpdatedAtResponse>>  =>{
    const requestParams: TApiGetListOfUserConfigSortByUpdatedAtV2 = {...params}
    
    delete requestParams[enumApiParameter.APPLICATION]
    delete requestParams[enumApiParameter.VERSION]

    for (const [key, value] of Object.entries(requestParams)){

        if(key === enumApiParameter.PAGE_NUMBER && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.PAGE_NUMBER]
        }
        if(key === enumApiParameter.DIRECTION && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.DIRECTION]
        }
        if(key === enumApiParameter.PAGE_SIZE && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.PAGE_SIZE]
        }
        if(key === enumApiParameter.PERIOD_FROM && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.PERIOD_FROM]
        }
        if(key === enumApiParameter.PERIOD_TO && typeof(value)==='undefined'){
            delete requestParams[enumApiParameter.PERIOD_TO]
        }
    }
    

    return await API.get(
        `/v2/configurations/users/${String(params.application).toLowerCase()}/${String(params.version).toLowerCase()}/by-updated`,
        { 
            params:{ ...requestParams} 
        }
    )
}

export default getListOfUserConfigSortByUpdatedAtV2
